import { createDomain } from 'effector';
import moment, { Moment } from 'moment';
import { Lens } from 'monocle-ts';
import { getOrElse } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { insertAt, lookup, every } from 'fp-ts/es6/Record';
import { Protocol } from '../api/protocol';
import { apiGetContractLimitHistory, apiGetLimitSettings } from '../api';
import { clearSession } from './system';
import { ContractLimitItem } from '../types/limit';
import { sanitizePagination } from '../utils/api';

const LimitsDomain = createDomain();

export type LimitsState = {
    date: Moment;
    list: Record<string, ContractLimitItem[]>;
    pagination: Pagination;
};

export type LimitSettingsState = Protocol.LimitSettingsResponse;

export const clearLimitsList = LimitsDomain.createEvent();
export const fetchLimitHistory = LimitsDomain.createEffect<
    Protocol.ContractLimitHistoryRequest,
    Protocol.ContractLimitHistoryResponse
>();

fetchLimitHistory.use(apiGetContractLimitHistory);

const initialState: LimitsState = {
    date: moment(),
    list: {},
    pagination: {
        pageNumber: 1,
        pageCount: 1,
        pageSize: 10,
        pageTotalRecordsCount: 0,
    },
};

const initialSetting: LimitSettingsState = {
    enableLimitChanging: false,
    limit: 0,
    limitMessage: '',
    maxDays: 0,
    minDays: 0,
};

const dateLens = Lens.fromProp<LimitsState>()('date');
const listLens = Lens.fromProp<LimitsState>()('list');
const paginationLens = Lens.fromProp<LimitsState>()('pagination');

export const LimitsStore = LimitsDomain.createStore(initialState)
    .on(fetchLimitHistory.done, (state, { params, result: { items, ...rawPagination } }) => {
        const pagination = sanitizePagination(rawPagination);
        return pipe(
            state,
            paginationLens.set(pagination),
            listLens.modify(insertAt(pagination.pageNumber.toString(), items)),
            dateLens.set(moment(`${params.year}.${params.month}`, 'YYYY.MM'))
        );
    })
    .reset(clearLimitsList, clearSession);

export const CurrentLimitsList = LimitsStore.map((state) =>
    pipe(
        state.list,
        lookup(state.pagination.pageNumber.toString()),
        getOrElse<ContractLimitItem[]>(() => [])
    )
);

export const fetchLimitSettings = LimitsDomain.createEffect<
    Protocol.LimitSettingsRequest,
    Protocol.LimitSettingsResponse
>();
fetchLimitSettings.use(apiGetLimitSettings);

export const LimitSettingsStore = LimitsDomain.createStore(initialSetting)
    .on(fetchLimitSettings.doneData, (state, response) => response)
    .reset(clearSession);

const confirmationFieldsGetter = Lens.fromProps<LimitSettingsState>()(['minDays', 'maxDays']).asGetter().get;
export const CanChangeLimitStore = LimitSettingsStore.map(
    (state) =>
        state.enableLimitChanging &&
        pipe(
            confirmationFieldsGetter(state),
            every((value: number) => value > 0)
        )
);
