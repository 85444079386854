import React, { FunctionComponent, createContext, useState } from 'react';

import firebase from 'firebase/app';
import 'firebase/messaging';

import { useOnceWithCondition } from '../hooks/lifecycle';

type Messaging = firebase.messaging.Messaging;

type FirebaseInstanceState = 'idle' | 'trying' | 'failed' | 'unsupported' | 'success' | 'unknown';
type NonSuccessInstanceState = Exclude<FirebaseInstanceState, 'success'>;
type ContainerErrorInstanceState = Exclude<NonSuccessInstanceState, 'idle' | 'trying'>;

type FirebaseSuccessState = { kind: 'success'; instance: Messaging; vapidKey: string };
type FirebaseOtherState = { kind: NonSuccessInstanceState };
type FirebaseState = FirebaseSuccessState | FirebaseOtherState;

type FirebaseProviderProps = { loggedIn: boolean };
export type FirebaseProviderContext = { firebaseState: FirebaseState; humanizeState: () => string };

const FirebaseConfig = {
    apiKey: 'AIzaSyCSwD3pRmwsTU7D-PqFGX2bJu_2QGFItD0',
    authDomain: 'ikslk-950b9.firebaseapp.com',
    databaseURL: 'https://ikslk-950b9.firebaseio.com',
    projectId: 'ikslk-950b9',
    storageBucket: 'ikslk-950b9.appspot.com',
    messagingSenderId: '216242182971',
    appId: '1:216242182971:web:9147bc1cad1eeaece4f51e',
    measurementId: 'G-2PY32VCZMQ',
};

const VAPIDKey = 'BBvR-Tea8J2GYoJXr-4AZdgY1FNFaQqyMDLj6umZyCZI51XqPbgJzPq_5SQf58PcclFTkNNwnk4LNNVAzDzqmHU';

const stateMap: Record<FirebaseInstanceState, string> = {
    idle: 'Модуль Push-уведомлений не инициализирован',
    trying: 'Инициализация модуля Push-уведомлений',
    failed: 'Не удалось инициализировать модуль Push-уведомлений',
    success: 'Модуль Push-уведомлений инициализирован',
    unknown: 'Неизвестная ошибка при инициализация модуля Push-уведомлений',
    unsupported: 'Ваш браузер не поддерживает Push-уведомления',
};

const mapErrorIntoState = (err: unknown): ContainerErrorInstanceState => {
    if (!(err instanceof Error)) return 'unknown';

    console.warn(err);

    const { message } = err;
    return message.includes('unsupported-browser') ? 'unsupported' : 'failed';
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const FirebaseContext = createContext<FirebaseProviderContext>(null!);
export const FirebaseProvider: FunctionComponent<FirebaseProviderProps> = (props) => {
    const { loggedIn, children } = props;

    const [firebaseState, setState] = useState<FirebaseState>({ kind: 'idle' });
    const humanizeState = (): string => stateMap[firebaseState.kind];

    useOnceWithCondition(() => {
        setState({ kind: 'trying' });

        try {
            firebase.initializeApp(FirebaseConfig);
        } catch (e) {
            setState({ kind: mapErrorIntoState(e) });
        }

        try {
            const instance = firebase.messaging();
            setState({ kind: 'success', instance, vapidKey: VAPIDKey });
        } catch (e) {
            setState({ kind: mapErrorIntoState(e) });
        }
    }, loggedIn);

    return <FirebaseContext.Provider value={{ firebaseState, humanizeState }}>{children}</FirebaseContext.Provider>;
};
