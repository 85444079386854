import { createDomain } from 'effector';

import { pipe } from 'fp-ts/es6/function';
import { Lens } from 'monocle-ts';

import { apiIncomingDownloadHTML, apiIncomingList } from '../api';
import { Protocol } from '../api/protocol';

import { clearSession } from './system';

import { IncomingListItem } from '../types/callListItems';

import { sanitizePagination } from '../utils/api';

export type IncomingListState = {
    list: IncomingListItem[];
    pagination: Pagination;
};

export const IncomingListDomain = createDomain();

export const clearIncomingList = IncomingListDomain.event();
export const fetchIncomingList = IncomingListDomain.effect<Protocol.IncomingRequest, Protocol.IncomingResponse>();
export const downloadIncomingHTML = IncomingListDomain.effect<
    Protocol.IncomingDownloadHTMLRequest,
    Protocol.IncomingDownloadHTMLResponse
>();

fetchIncomingList.use(apiIncomingList);
downloadIncomingHTML.use(apiIncomingDownloadHTML);

const initialState: IncomingListState = {
    list: [],
    pagination: {
        pageNumber: 1,
        pageCount: 1,
        pageSize: 10,
        pageTotalRecordsCount: 0,
    },
};

const listLens = Lens.fromProp<IncomingListState>()('list');
const paginationLens = Lens.fromProp<IncomingListState>()('pagination');

export const IncomingListStore = IncomingListDomain.createStore(initialState)
    .on(fetchIncomingList.done, (state, { result: { items, ...pagination } }) =>
        pipe(state, paginationLens.set(sanitizePagination(pagination)), listLens.set(items))
    )
    .reset(clearIncomingList, clearSession);
