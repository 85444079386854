import React, { FunctionComponent, useState } from 'react';
import {
    Button,
    Fade,
    IconButton,
    Modal,
    Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useStore } from 'effector-react';
import { useStyles } from './modal/styles';
import { ButtonWithLoading } from './ButtonWithLoading';
import { apiChangeTariffCancel } from '../api';
import { CurrentContractStore, CurrentDashboardStore, CurrentTariffStore, fetchDashboard } from '../effector/dashboard';

type Props = {
    open: boolean;
    onClose: () => void,
}
export const TariffChangeCancelConfirm: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const { open, onClose } = props;

    const { enqueueSnackbar } = useSnackbar();

    const tariffs = useStore(CurrentTariffStore);
    const currentContract = useStore(CurrentContractStore);
    const currentDashboard = useStore(CurrentDashboardStore);

    const [loading, setLoading] = useState(false);

    const onCancelTariffChange = (): void => {
        if (!currentContract || !tariffs.planned || !currentDashboard || loading || tariffs.planned.replacedFrom === null) return;
        setLoading(true);

        const payload = {
            ...currentContract,
            contractTariffId: tariffs.planned.id,
            replacedFrom: tariffs.planned.replacedFrom,
        };

        const updateDashboard = (message: string): void => {
            if (!currentContract) return;
            fetchDashboard(currentContract).then(() => {
                enqueueSnackbar(message, { variant: 'success' });
            }).catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            })
        };

        apiChangeTariffCancel(payload).then((response) => {
            updateDashboard(response.result);
        }).catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error' });
        }).finally(() => {
            setLoading(false);
            onClose();
        });
    };

    return (
        <Modal
            open={open}
            onClose={loading ? () => {} : onClose}
            aria-labelledby="Модальное окно подтверждения отмены изменения тарифа"
        >
            <Fade in={open}>
                <div className={classes.body}>
                    <Typography variant="h6" className={classes.title}>
                        Вы уверены, что хотите отменить переход на тариф{tariffs.planned ? ` ${tariffs.planned.title}` : ''}?
                    </Typography>

                    <div className={classes.buttons}>
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            color="secondary"
                            disabled={loading}
                            className={classes.button}
                        >
                            Закрыть окно
                        </Button>

                        <div className={classes.button}>
                            <ButtonWithLoading
                                type="submit"
                                loading={loading}
                                color="primary"
                                onClick={onCancelTariffChange}
                                className={classes.buttonConfirm}
                                noMargin
                            >
                                Отменить переход
                            </ButtonWithLoading>
                        </div>
                    </div>

                    <IconButton aria-label="Закрыть" className={classes.close} onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </div>
            </Fade>
        </Modal>
    );
};
