import StrictEventEmitter from 'strict-event-emitter-types/src';
import { EventEmitter } from 'events';

export interface BusEvents {} // eslint-disable-line @typescript-eslint/no-empty-interface

export type ListenerType<T> = [T] extends [(...args: infer U) => any] ? U : [T] extends [void] ? [] : [T];
export type EventBusType = StrictEventEmitter<EventEmitter, BusEvents>;
export type EventRegister = <T, P extends keyof BusEvents>(this: T, event: P, listener: (...args: ListenerType<BusEvents[P]>) => void) => void;
export type EventSubscribe = <T, P extends keyof BusEvents>(this: T, event: P, listener: (...args: ListenerType<BusEvents[P]>) => void) => () => void;

export const EventBus: EventBusType = new EventEmitter();

export const subscribeEvent: EventSubscribe = (event, handler) => {
    EventBus.addListener(event, handler);
    return () => EventBus.removeListener(event, handler);
}

