import React, { FunctionComponent, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { CurrentContractStore } from '../effector/dashboard';
import { fetchTariffList, TariffListStore } from '../effector/tariffList';
import { foldApiDateToDate } from '../utils/view';
import { AccordionLoader } from './AccordionLoader';
import { Table, TableColumns } from '../containers/Table';
import { FeipTypograf } from '../utils/typograf';
import { TariffListItem } from '../types/tariff';

const columns: TableColumns<TariffListItem> = [
    {
        id: 'title',
        label: 'Тариф',
        format: (value: string) => FeipTypograf.execute(value),
    },
    {
        id: 'dateFrom',
        label: 'Подключен',
        align: 'left',
        format: foldApiDateToDate,
    },
    {
        id: 'dateTo',
        label: 'Заканчивается',
        align: 'left',
        format: (value: DateTime.Date | null) => (value ? foldApiDateToDate(value) : '—'),
    },
];

type Props = { open: boolean };
export const TariffList: FunctionComponent<Props> = ({ open }) => {
    const list = useStore(TariffListStore);
    const loading = useStore(fetchTariffList.pending);
    const currentContract = useStore(CurrentContractStore);

    const [fetched, setFetched] = useState(list.length > 0);

    useEffect(() => {
        if (open && currentContract && !fetched && !loading) {
            setFetched(true);
            fetchTariffList(currentContract).catch(() => setFetched(false));
        }
    }, [loading, fetched, open, currentContract]);

    // Handle contract id change in header
    useEffect(() => {
        setFetched(false);
    }, [currentContract]);

    if (loading) return <AccordionLoader />;
    if (!loading && list.length === 0) return <AccordionLoader text="Не найдено данных для отображения" />;

    return <Table columns={columns} data={list} keyProp={['title', 'dateFrom']} />;
};
