import moment from 'moment';

export const DEFAULT_PAYMENT_PRICE = 100;
export const MIN_PAYMENT_PRICE = 1;
export const MAX_PAYMENT_PRICE = 9999999;

export const MIN_DAY_OF_MONTH = Number(moment().startOf('months').format('DD'));
export const MAX_DAY_OF_MONTH = 31;

export const PHONE_RU_CLEANED_LENGTH = 11;

export const LOCATION_HASH = {
    BALANCE: '#balance',
    CHANGE_TARIFF: '#change',
};

export const LOCATION_ROUTE = {
    ADVANCED: '/advanced',
    DASHBOARD: '/dashboard',
    CAMERA: '/dashboard/camera/:id',
    ARCHIVE: '/dashboard/camera/:id/:daysAgo',
    NEWS: '/news',
    SETTINGS: '/settings',
    HELP: '/help',
    PAYMENT_STATUS: '/payment-status/:paymentSystem',
};

export const BASE_ROUTES = [
    LOCATION_ROUTE.DASHBOARD,
    LOCATION_ROUTE.ADVANCED,
    LOCATION_ROUTE.NEWS,
    LOCATION_ROUTE.SETTINGS,
    LOCATION_ROUTE.HELP,
];

export const LOCATION_CAPTION = {
    ADVANCED: 'Отчеты',
    DASHBOARD: 'Профиль',
    NEWS: 'Новости',
    SETTINGS: 'Настройки',
    HELP: 'Помощь',
    LOGIN: 'Личный кабинет',
    NOT_FOUND: 'Страница не найдена',
    PAYMENT_STATUS: 'Статус онлайн платежа',
};
