import React, { ChangeEvent, FormEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Switch, FormControlLabel, Typography, createStyles, makeStyles } from '@material-ui/core';

import { useStore } from 'effector-react';
import { CurrentContractStore } from '../effector/dashboard';
import { CurrentContractPushTokenStore, deleteToken, registerToken } from '../effector/notifications';

import { useFirebase } from '../hooks/firebase';
import { ButtonWithLoading } from './ButtonWithLoading';

const useStyles = makeStyles((theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(2),
            display: 'block',
        },
        form: {
            paddingTop: theme.spacing(2),
        },
        disabledInfo: {
            paddingTop: theme.spacing(2),
        },
    })
);

export const NotificationsSettings: FunctionComponent = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { firebaseState, humanizeState } = useFirebase();

    const contract = useStore(CurrentContractStore);
    const contractToken = useStore(CurrentContractPushTokenStore);
    const humanizedState = useMemo(humanizeState, [firebaseState]);

    const [loading, setLoading] = useState(false);
    const [notificationsOn, setNotificationsOn] = useState(contractToken !== null);

    useEffect(() => {
        if (contract) setNotificationsOn(contractToken !== null);
    }, [contract]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => setNotificationsOn(event.target.checked);
    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (firebaseState.kind !== 'success' || loading) {
            enqueueSnackbar(humanizedState, { variant: 'warning' });
            return;
        }

        setLoading(true);

        if (contract === null) {
            enqueueSnackbar('Не задан текущий договор', { variant: 'warning' });
            return;
        }

        try {
            if (notificationsOn || contractToken === null) {
                const token = await firebaseState.instance.getToken({ vapidKey: firebaseState.vapidKey });
                await registerToken({ ...contract, token });
            } else {
                await firebaseState.instance.deleteToken();
                await deleteToken({ ...contract, token: contractToken });
            }

            const message = notificationsOn ? 'Уведомления успешно включены' : 'Уведомления успешно отключены';
            enqueueSnackbar(message, { variant: 'success' });
        } catch (e) {
            enqueueSnackbar(
                `Произошла ошибка во время ${
                    notificationsOn ? 'включения' : 'отключения'
                } push-уведомлений. Попробуйте повторить запрос позднее.`,
                { variant: 'error' }
            );
        }

        setLoading(false);
    };

    const disableUI = firebaseState.kind !== 'success';
    const disableByEq = (contractToken !== null && notificationsOn) || (contractToken === null && !notificationsOn);

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <FormControlLabel
                disabled={disableUI || loading}
                control={<Switch color="primary" checked={notificationsOn} onChange={handleChange} />}
                label={notificationsOn ? 'Уведомления включены' : 'Уведомления отключены'}
            />

            <ButtonWithLoading
                type="submit"
                color="primary"
                loading={loading}
                disabled={disableUI || disableByEq}
                className={classes.button}
            >
                Изменить
            </ButtonWithLoading>

            {disableUI && (
                <Typography className={classes.disabledInfo} color="error">
                    {humanizedState}
                </Typography>
            )}
        </form>
    );
};
