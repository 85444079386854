import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Fade, IconButton, Modal, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Close as CloseIcon, Delete, Edit } from '@material-ui/icons';
import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';

import { apiSetParamEmails } from '../../api';
import {
    CurrentContractStore,
    CurrentParamsStore,
    updateContactEmails,
} from '../../effector/dashboard';
import { useStyles as modalStyles } from '../modal/styles';
import { useStyles as useChangeParamStyles } from './styles';
import { ButtonWithLoading } from '../ButtonWithLoading';
import { Protocol } from '../../api/protocol';
import { EditButton } from '../EditButton';
import { ContactEmail } from '../../types/contract';

type SetEmailProps = {
    index?: number;
    data?: ContactEmail;
    mode?: 'edit' | 'add';
};
export const SetEmail: FunctionComponent<SetEmailProps> = (props) => {
    const { data, index, mode = 'edit' } = props;

    const isEditMode = mode === 'edit';

    const modalClasses = modalStyles();
    const classes = useChangeParamStyles();

    const [isModalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState(data !== undefined ? data.email : '');
    const [comment, setComment] = useState(data !== undefined ? data.comment : '');
    const [loading, setLoading] = useState(false);

    const currentContract = useStore(CurrentContractStore);
    const currentParams = useStore(CurrentParamsStore);
    const { enqueueSnackbar } = useSnackbar();

    const closeModal = (): void => setModalOpen(false);
    const clear = (): void => {
        setEmail('');
        setComment('');
    };

    useEffect(() => {
        if (data !== undefined && isEditMode) {
            setEmail(data.email);
            setComment(data.comment);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updateEmails = (): void => {
        if (!currentContract) return;

        let newValues: ContactEmail[] = [];
        newValues = currentParams.contactEmails.map((item, itemIndex) => {
            if (itemIndex === index) {
                return {
                    ...item,
                    email,
                    comment,
                }
            }

            return item;
        });

        if (index === undefined) newValues.push({ email, comment });

        setLoading(true);
        const payload: Protocol.SetParamEmailsRequest = {
            ...currentContract,
            value: JSON.stringify({ emails: newValues }),
        };

        apiSetParamEmails(currentContract, payload)
            .then((response) => {
                enqueueSnackbar(response.result, { variant: 'success' });
                updateContactEmails(newValues);
                if (!isEditMode) clear();
                closeModal();
            })
            .catch(({ message }) => enqueueSnackbar(message, { variant: 'error' }))
            .finally(() => setLoading(false));
    };

    return (
        <>
            {isEditMode ? (
                <IconButton
                    size="small"
                    edge="end"
                    aria-label="Изменить Email адрес"
                    onClick={() => setModalOpen(true)}
                >
                    <Edit fontSize="small" />
                </IconButton>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => setModalOpen(true)}
                >
                    Добавить Email
                </Button>
            )}

            <Modal
                open={isModalOpen}
                onClose={closeModal}
            >
                <Fade in={isModalOpen}>
                    <div className={modalClasses.body}>
                        <Typography variant="h6" className={modalClasses.title}>
                            {isEditMode ? 'Изменить' : 'Добавить'} email
                        </Typography>

                        <div className={classes.form}>
                            <TextField
                                label="Email"
                                placeholder="Email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />

                            <TextField
                                value={comment}
                                label="Комментарий"
                                onChange={(event) => setComment(event.target.value)}
                            />
                        </div>

                        <ButtonWithLoading
                            type="submit"
                            loading={loading}
                            color="primary"
                            onClick={updateEmails}
                            noMargin
                        >
                            {isEditMode ? 'Сохранить изменения' : 'Добавить Email'}
                        </ButtonWithLoading>

                        <IconButton aria-label="Закрыть" className={modalClasses.close} onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Fade>
            </Modal>
        </>
    )
};

type DeleteEmailProps = { index: number; data: ContactEmail }
export const DeleteEmail: FunctionComponent<DeleteEmailProps> = (props) => {
    const { index, data } = props;

    const modalClasses = modalStyles();

    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const currentContract = useStore(CurrentContractStore);
    const currentParams = useStore(CurrentParamsStore);
    const { enqueueSnackbar } = useSnackbar();

    const closeModal = (): void => setModalOpen(false);

    const deleteEmail = (): void => {
        if (!currentContract) return;

        const newValues: ContactEmail[] = [...currentParams.contactEmails];
        newValues.splice(index, 1);

        setLoading(true);
        const payload: Protocol.SetParamEmailsRequest = {
            ...currentContract,
            value: JSON.stringify({ emails: newValues }),
        };

        apiSetParamEmails(currentContract, payload)
            .then((response) => {
                enqueueSnackbar(response.result, { variant: 'success' });
                closeModal();
                setLoading(false);
                updateContactEmails(newValues);
            })
            .catch(({ message }) => {
                enqueueSnackbar(message, { variant: 'error' });
                setLoading(false);
            });
    };

    return (
        <>
            <IconButton
                size="small"
                edge="end"
                aria-label="Удалить Email"
                onClick={() => setModalOpen(true)}
            >
                <Delete fontSize="small" />
            </IconButton>

            <Modal
                open={isModalOpen}
                onClose={closeModal}
            >
                <Fade in={isModalOpen}>
                    <div className={modalClasses.body}>
                        <Typography variant="h6" className={modalClasses.title}>
                            Удалить Email ({data.email})?
                        </Typography>

                        <div className={modalClasses.buttons}>
                            <Button
                                onClick={closeModal}
                                variant="outlined"
                                color="secondary"
                                className={modalClasses.button}
                            >
                                Отменить
                            </Button>

                            <ButtonWithLoading
                                type="submit"
                                loading={loading}
                                color="primary"
                                onClick={deleteEmail}
                                className={clsx([
                                    modalClasses.button,
                                    modalClasses.buttonConfirm,
                                ])}
                                noMargin
                            >
                                Подтвердить
                            </ButtonWithLoading>
                        </div>

                        <IconButton aria-label="Закрыть" className={modalClasses.close} onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Fade>
            </Modal>
        </>
    )
};

export const ChangeEmailsParam: FunctionComponent = () => {
    const modalClasses = modalStyles();
    const classes = useChangeParamStyles();

    const [isModalOpen, setModalOpen] = useState(false);
    const currentParams = useStore(CurrentParamsStore);
    const closeModal = (): void => setModalOpen(false);

    return (
        <>
            <EditButton
                onClick={() => setModalOpen(true)}
                withTopGutter={false}
            />

            <Modal
                open={isModalOpen}
                onClose={closeModal}
            >
                <Fade in={isModalOpen}>
                    <div className={modalClasses.body}>
                        <Typography variant="h6" className={modalClasses.title}>
                            Изменить Email-адреса
                        </Typography>

                        <div className={clsx([
                            classes.form,
                            classes.formLargeGutter,
                        ])}>
                            {currentParams.contactEmails.map((item, index) => (
                                <div key={`change-email-item-${index}`} className={classes.contactItem}>
                                    <div className={classes.contactWrapper}>
                                        <p className={classes.contactValue}>{item.email}</p>
                                        {item.comment && <p className={classes.contactComment}>{item.comment}</p>}
                                    </div>

                                    <div className={classes.contactButtons}>
                                        <SetEmail data={item} index={index} />
                                        <DeleteEmail data={item} index={index} />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <SetEmail mode="add" />

                        <IconButton aria-label="Закрыть" className={modalClasses.close} onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Fade>
            </Modal>
        </>
    )
};
