import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { sequenceT } from 'fp-ts/es6/Apply';
import { delay, Task, task } from 'fp-ts/es6/Task';

import {
    FormControl,
    FormLabel,
    InputAdornment,
    Slider,
    TextField,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useStore } from 'effector-react';
import { CurrentContractStore, fetchDashboard } from '../effector/dashboard';
import { fetchLimitHistory, fetchLimitSettings, LimitSettingsStore, LimitsStore } from '../effector/limits';

import { apiChangeContractLimit } from '../api';
import { Protocol } from '../api/protocol';

import { isInt } from '../utils/guards';

import { ButtonWithLoading } from './ButtonWithLoading';
import { foldDateIntoYearMonth } from '../utils/api';

// ----- Styles -----
const useStyles = makeStyles(() =>
    createStyles({
        form: {
            padding: '8px 4px',
            display: 'flex',
            flexDirection: 'column',
        },
        numberInput: {
            marginBottom: 32,
            marginTop: 8,
        },
    })
);

type FormInputs = Protocol.ChangeContractLimitRequest;

type Props = {
    setClose: () => void;
}
export const ChangeLimitForm: FunctionComponent<Props> = (props) => {
    const { setClose } = props;

    const currentContract = useStore(CurrentContractStore);
    const { minDays, maxDays } = useStore(LimitSettingsStore);
    const { pagination, date } = useStore(LimitsStore);

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [days, setDays] = useState(minDays);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);

    // Barely random constants, API don't send limits, only in message
    const DEFAULT_AMOUNT_PRICE = 100;
    const MAX_AMOUNT_PRICE = 5000;

    const { register, handleSubmit, setValue } = useForm<FormInputs>({
        reValidateMode: 'onSubmit',
        defaultValues: {
            amount: DEFAULT_AMOUNT_PRICE,
            days: 1,
        },
    });

    const handleAmountChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const { value } = event.target;
        if (isInt(value)) {
            setValue('amount', Number(value));
        }

        if (value.length > String(MAX_AMOUNT_PRICE).length) {
            setValue(
                'amount',
                Number(value.slice(0, String(MAX_AMOUNT_PRICE).length))
            );
        }
    };

    const onSubmit = (formData: FormInputs): void => {
        if (currentContract === null) return;

        /**
         *  Build post-update action
         *
         *  Understanding what is Task
         */

        // Define post-update payload data
        const historyPayload: Protocol.ContractLimitHistoryRequest = {
            ...currentContract,
            ...pagination,
            ...foldDateIntoYearMonth(date)
        }

        /**
         * Build Tasks
         * @see Task constructor
         * NOTE: ignore return type as unknown
         */
        const updateDashboardT: Task<unknown> = () => fetchDashboard(currentContract);
        const updateLimitHistoryT: Task<unknown> = () => fetchLimitHistory(historyPayload);
        const updateLimitSettingsT: Task<unknown> = () => fetchLimitSettings(currentContract);
        const changeUpdatingStateT: Task<unknown> = async () => setUpdating(true);

        /**
         * Create task runner with parallel behaviour
         * @see https://grossbart.github.io/fp-ts-recipes/#/async?a=run-a-list-of-tasks-in-sequence
         */
        const parallelTS = sequenceT(task);

        // Say what we want to run in parallel
        // This is just a declaration, not running, all tasks are lazy
        const updateTask = parallelTS(
            updateDashboardT,
            updateLimitHistoryT,
            updateLimitSettingsT
        );

        /**
         * Apply a delay combinator to our tasks
         * That is, after the end of the delay, our tasks will be launched
         * Returns a new task
         */
        const delayedUpdateTask = delay(1000)(updateTask);

        const { amount } = formData;
        const payload = { amount, days };

        setLoading(true);
        apiChangeContractLimit(currentContract, payload)
            .then(({ result }) => enqueueSnackbar(result, { variant: 'success' }))
            .then(parallelTS(changeUpdatingStateT, delayedUpdateTask))
            .catch(({ message }) => enqueueSnackbar(message, { variant: 'error' }))
            .finally(() => {
                setUpdating(false);
                setLoading(false);
                setClose();
            });
    };

    return (
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
                <FormLabel
                    id="change-limit-days-label"
                    className="MuiInputLabel-shrink"
                >
                    Количество дней
                </FormLabel>

                <Slider
                    value={days}
                    min={minDays}
                    disabled={loading}
                    step={1}
                    max={maxDays}
                    marks
                    onChange={(event, value) => {
                        setDays(value as number);
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="change-limit-days-label"
                />
            </FormControl>

            <TextField
                id="change-limit-input-amount"
                type="number"
                name="amount"
                label="Сумма"
                disabled={loading}
                inputRef={register}
                className={classes.numberInput}
                onChange={handleAmountChange}
                defaultValue={DEFAULT_AMOUNT_PRICE}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">₽</InputAdornment>
                    ),
                }}
            />

            <ButtonWithLoading
                loading={loading}
                type="submit"
                noMargin
                fullWidth
                color="primary"
            >
                {updating ? 'Обновление' : 'Изменить'}
            </ButtonWithLoading>
        </form>
    );
};
