import React, { FunctionComponent, useCallback, useState } from 'react';
import { useEvent, useStore } from 'effector-react';
import { useHistory, useLocation } from 'react-router-dom';

import {
    ClickAwayListener,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import { AccountCircle, Equalizer, ExitToApp, NotificationsActive, Settings, Person, Help } from '@material-ui/icons';

import { CurrentParamsStore } from '../effector/dashboard';
import { clearSession } from '../effector/system';

import { LOCATION_CAPTION, LOCATION_ROUTE } from '../utils/constants';
import { FeipTypograf } from '../utils/typograf';

import { DashboardStatus } from './DashboardStatus';
import { LogoutModal } from './LogoutModal';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            color: theme.palette.text.secondary,

            '& .MuiListItemText-primary': {
                color: theme.palette.text.secondary,
                fontWeight: 500,
            },

            '& .MuiListItemIcon-root': {
                minWidth: 40,
            },
        },
        selected: {
            color: theme.palette.primary.contrastText,
            '&.MuiListItemIcon-root': {
                backgroundColor: 'rgba(250,176,0, 0.28)',
            },

            '& .MuiListItemIcon-root': {
                color: theme.palette.primary.dark,
            },

            '& .MuiListItemText-primary': {
                color: theme.palette.primary.contrastText,
                fontWeight: 500,
            },
        },
        listRoot: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: 240,
        },
        listItemText: {
            fontSize: 14,
        },
        statusWrapper: {
            marginTop: 'auto',
        },
        status: {
            fontSize: 14,
            '& svg': {
                marginRight: 8,
            },
        },
        fullNameWrapper: {
            alignItems: 'flex-start',
            borderBottom: '1px solid #eaeaea',
            marginBottom: 4,
            paddingBottom: 10,
        },
        fullName: {
            margin: 0,
            '& > span': {
                fontSize: 14,
            },
        },
    })
);

type DrawerNavigationProps = { open: boolean; setOpen: (open: boolean) => void };
const DrawerNavigation: FunctionComponent<DrawerNavigationProps> = ({ open, setOpen }) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const store = useStore(CurrentParamsStore);
    const logout = useEvent(clearSession);
    const handleLogout = useCallback(() => logout(), [logout]);
    const [logoutModalIsOpen, setLogoutModalOpen] = useState(false);
    const [isMobileNameTooltipOpen, setMobileNameTooltipOpen] = useState(false);

    const menuItems = [
        {
            caption: LOCATION_CAPTION.DASHBOARD,
            icon: <AccountCircle />,
            location: LOCATION_ROUTE.DASHBOARD,
        },
        {
            caption: LOCATION_CAPTION.ADVANCED,
            icon: <Equalizer />,
            location: LOCATION_ROUTE.ADVANCED,
        },
        {
            caption: LOCATION_CAPTION.NEWS,
            icon: <NotificationsActive />,
            location: LOCATION_ROUTE.NEWS,
        },
        {
            caption: LOCATION_CAPTION.SETTINGS,
            icon: <Settings />,
            location: LOCATION_ROUTE.SETTINGS,
        },
        {
            caption: LOCATION_CAPTION.HELP,
            icon: <Help />,
            location: LOCATION_ROUTE.HELP,
        },
    ];

    return (
        <>
            <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
                <List component="nav" aria-label="main mailbox folders" classes={{ root: classes.listRoot }}>
                    {store.fullName && (
                        <ClickAwayListener onClickAway={() => setMobileNameTooltipOpen(false)}>
                            <Tooltip
                                title={FeipTypograf.execute(store.fullName)}
                                open={isMobileNameTooltipOpen}
                                onClick={() => setMobileNameTooltipOpen(!isMobileNameTooltipOpen)}
                            >
                                <ListItem key="/fullname" className={classes.fullNameWrapper}>
                                    <ListItemIcon style={{ minWidth: 40 }}>
                                        <Person />
                                    </ListItemIcon>
                                    <ListItemText className={classes.fullName}>
                                        {FeipTypograf.execute(store.fullName)}
                                    </ListItemText>
                                </ListItem>
                            </Tooltip>
                        </ClickAwayListener>
                    )}
                    {menuItems.map((item) => (
                        <ListItem
                            key={item.location}
                            button
                            classes={{
                                root: classes.root,
                                selected: classes.selected,
                            }}
                            selected={location.pathname === item.location}
                            onClick={() => {
                                history.push(item.location);
                                setOpen(false);
                            }}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.caption} classes={{ primary: classes.listItemText }} />
                        </ListItem>
                    ))}
                    <ListItem
                        button
                        key="/logout"
                        classes={{
                            root: classes.root,
                            selected: classes.selected,
                        }}
                        onClick={() => {
                            setLogoutModalOpen(true);
                        }}
                    >
                        <ListItemIcon>
                            <ExitToApp />
                        </ListItemIcon>
                        <ListItemText primary="Выйти" classes={{ primary: classes.listItemText }} />
                    </ListItem>

                    <ListItem key="/status" className={classes.statusWrapper}>
                        <DashboardStatus className={classes.status} />
                    </ListItem>
                </List>
            </Drawer>
            <LogoutModal open={logoutModalIsOpen} setOpen={setLogoutModalOpen} handleLogout={handleLogout} />
        </>
    );
};

export { DrawerNavigation };
