import { Cloud, CloudDoneTwoTone as CloudOn, CloudOff, SvgIconComponent } from '@material-ui/icons';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

type StatusMapSetting = {
    text: string;
    type: 'online' | 'offline' | 'blocked' | 'other';
    color: string;
    component: SvgIconComponent;
    tooltipText: string | null;
};

type KnownStatuses = 'Активен' | 'Отключен' | 'Недостаточно средств' | string;

export const getStatusConfig = (status: string): StatusMapSetting => {
    const dirtyStatus = status as KnownStatuses;

    switch (dirtyStatus) {
        case 'Активен':
            return {
                text: 'Активен',
                color: green[500],
                component: CloudOn,
                tooltipText: 'Услуги предоставляются',
                type: 'online',
            };
        case 'Недостаточно средств':
            return {
                text: 'Недостаточно средств',
                color: red[400],
                component: CloudOff,
                tooltipText: 'Предоставление услуг приостановлено',
                type: 'offline',
            };
        case 'Отключен':
            return {
                text: 'Отключен',
                color: grey[600],
                component: CloudOff,
                tooltipText: 'Предоставление услуг приостановлено',
                type: 'other',
            };
        default:
            return {
                text: status,
                color: '#DF9502',
                component: Cloud,
                tooltipText: null,
                type: 'blocked',
            };
    }
};
