import { createDomain, createEffect, createEvent } from 'effector';
import { Protocol } from '../api/protocol';
import { apiNews } from '../api';
import { clearSession } from './system';

export const NewsDomain = createDomain();

export type NewsState = {
    news: Protocol.NewsResponse;
};

export const clearNewsCache = createEvent();

export const fetchNews = createEffect<
    Protocol.NewsRequest,
    Protocol.NewsResponse
>();

fetchNews.use(apiNews);

const initialState: NewsState = {
    news: [],
};

export const NewsStore = NewsDomain.createStore(initialState)
    .on(fetchNews.done, (state, payload) => ({
        ...state,
        news: payload.result,
    }))
    .reset(clearNewsCache, clearSession);

export const CurrentNewsStore = NewsStore.map((state) => ({
    news: state.news,
}));
