import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';

import { FormControl, InputLabel, MenuItem, Select, TablePagination, Button } from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import { useStore } from 'effector-react';
import { CurrentContractStore } from '../effector/dashboard';
import { fetchServiceList, ServiceListStore } from '../effector/serviceList';
import {
    CurrentTrafficList,
    downloadTrafficHTML,
    fetchTrafficList,
    saveTrafficDate,
    TrafficStore,
} from '../effector/traffic';

import { TrafficListItem, TrafficServiceId } from '../types/traffic';

import { foldViewState, formatBytes, foldApiTimestampToFullDatetime } from '../utils/view';
import { FeipTypograf } from '../utils/typograf';
import { momentEq } from '../utils/equals';

import { DefaultError } from '../api/client/error';

import { Table, TableColumns } from '../containers/Table';
import { ButtonWithLoading } from './ButtonWithLoading';
import { AccordionLoader } from './AccordionLoader';
import { downloadBlob } from '../utils/api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'block',
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
        filters: {
            margin: theme.spacing(2),
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        filter: {
            '&:not(:last-child)': {
                marginRight: 16,
                [theme.breakpoints.down('sm')]: {
                    marginRight: 0,
                    marginBottom: 12,
                },
            },
        },
        downloadButton: {
            display: 'inline-flex',
        },
        errorContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        },
        errorLabel: {
            color: theme.palette.primary.contrastText,
            marginBottom: theme.spacing(2),
        },
        select: {
            '& .MuiSelect-select': {
                '&:focus': {
                    backgroundColor: 'white',
                },
            },
        },
    })
);

const columns: TableColumns<TrafficListItem> = [
    {
        id: 'dateFrom',
        label: 'Начало сессии',
        format: foldApiTimestampToFullDatetime,
        isNoWrapHeader: true,
    },
    {
        id: 'dateTo',
        label: 'Конец сессии',
        isNoWrapHeader: true,
        format: (value: DateTime.Timestamp | null) => (value ? foldApiTimestampToFullDatetime(value) : '—'),
    },
    { id: 'length', label: 'Продолжительность' },
    {
        id: 'title',
        label: 'Сервис',
        format: (value: string) => FeipTypograf.execute(value),
    },
    {
        id: 'incomeByte',
        label: 'Входящий трафик',
        format: formatBytes,
        align: 'right',
    },
    {
        id: 'outcomeByte',
        label: 'Исходящий трафик',
        format: formatBytes,
        align: 'right',
    },
];

type FetchOpts = {
    sid?: TrafficServiceId;
    newDate?: Moment;
    pageSize?: number;
    pageNumber?: number;
};

type Props = { open: boolean };
export const Traffic: FunctionComponent<Props> = ({ open }) => {
    const classes = useStyles();

    const loading = useStore(fetchTrafficList.pending);
    const loadingHTML = useStore(downloadTrafficHTML.pending);
    const currentList = useStore(CurrentTrafficList);
    const currentContract = useStore(CurrentContractStore);
    const { pagination, date } = useStore(TrafficStore);
    const { list: servicesList, fetched: isServicesFetched } = useStore(ServiceListStore);

    const [error, setError] = useState<string | null>(null);
    const [fetched, setFetched] = useState(currentList.length > 0);
    const [serviceId, setServiceId] = useState<TrafficServiceId>('all');

    const fetch = useCallback(
        (opts: FetchOpts = {}): void => {
            const {
                sid = serviceId,
                newDate = date,
                pageSize = pagination.pageSize,
                pageNumber = pagination.pageNumber,
            } = opts;

            if (!currentContract || loading || error !== null) return;

            if (!momentEq.equals(newDate, date)) saveTrafficDate(newDate);

            fetchTrafficList({
                ...currentContract,
                ...pagination,
                pageSize,
                pageNumber,
                serviceId: sid,
                year: newDate.year(),
                month: newDate.month() + 1,
            }).catch(() => {
                setError(DefaultError.Common);
                setFetched(false);
            });
        },
        [currentContract, serviceId, pagination, date, loading, error]
    );

    const download = (): void => {
        if (loadingHTML || currentContract === null) return;
        downloadTrafficHTML({
            ...currentContract,
            serviceId,
            year: date.year(),
            month: date.month() + 1,
        })
            .then((blob) => downloadBlob(blob, `Интернет-трафик за ${date.format('MMMM YYYY')}.html`))
            .catch((e) => console.log(e));
    };

    const changeServiceId: SelectInputProps['onChange'] = useCallback(
        (event) => {
            const value = event.target.value as TrafficServiceId;
            setServiceId(value);
            fetch({ sid: value });
        },
        [fetch]
    );

    // Handle contract id change in header
    useEffect(() => {
        setFetched(false);
    }, [currentContract]);
    useEffect(() => {
        if (open && currentContract && !isServicesFetched && !loading) {
            fetchServiceList(currentContract);
        }
    }, [loading, isServicesFetched, open, currentContract]);
    useEffect(() => {
        if (open && currentContract && !fetched && !loading && error === null) {
            setFetched(true);
            fetch();
        }
    }, [loading, fetched, open, currentContract, fetch, error]);

    if (loading && !isServicesFetched) return <AccordionLoader />;

    const viewState = foldViewState(currentList, loading, error);

    return (
        <div className={classes.root}>
            <div className={classes.filters}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
                    <FormControl className={classes.filter}>
                        <DatePicker
                            value={date}
                            label="Дата"
                            views={['month', 'year']}
                            maxDate={moment().endOf('day')}
                            cancelLabel="Отмена"
                            okLabel="Сохранить"
                            minDateMessage="Дата не должна быть раньше 2000 года"
                            maxDateMessage="Дата не должна превышать текущую дату"
                            disableFuture
                            onChange={(newDate: MaterialUiPickersDate) => {
                                if (!currentContract || !newDate) return;
                                fetch({ newDate });
                            }}
                        />
                    </FormControl>
                    <FormControl className={classes.filter}>
                        <InputLabel id="service-id-select-label">Сервис</InputLabel>
                        <Select
                            className={classes.select}
                            labelId="service-id-select-label"
                            value={serviceId}
                            onChange={changeServiceId}
                        >
                            <MenuItem value="all" key="select-item-all">
                                Все
                            </MenuItem>
                            {servicesList.map((item) => (
                                <MenuItem value={item.id} key={`select-item-${item.id}`}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </MuiPickersUtilsProvider>
                <ButtonWithLoading
                    color="primary"
                    onClick={download}
                    loading={loadingHTML}
                    containerClass={classes.downloadButton}
                >
                    Скачать
                </ButtonWithLoading>
            </div>

            {viewState(
                () => (
                    <AccordionLoader text="Не найдено данных за выбранный период" />
                ),
                () => (
                    <AccordionLoader />
                ),
                (err) => (
                    <AccordionLoader>
                        <div className={classes.errorContainer}>
                            <div className={classes.errorLabel}>{err}</div>
                            <Button color="primary" variant="contained" onClick={() => setError(null)}>
                                Обновить
                            </Button>
                        </div>
                    </AccordionLoader>
                ),
                (list) => (
                    <>
                        <Table data={list} columns={columns} keyProp={['dateFrom', 'dateTo']} />
                        <TablePagination
                            component="div"
                            rowsPerPage={pagination.pageSize}
                            count={pagination.pageTotalRecordsCount}
                            page={pagination.pageNumber - 1}
                            labelRowsPerPage="Записей на странице"
                            onChangePage={(event, value) => {
                                fetch({ pageNumber: value + 1 });
                            }}
                            onChangeRowsPerPage={(event) => {
                                fetch({ pageSize: Number(event.target.value) });
                            }}
                        />
                    </>
                )
            )}
        </div>
    );
};
