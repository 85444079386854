import { createDomain, restore } from 'effector';
import { apiDesignFeatures } from '../api';
import { Protocol } from '../api/protocol';

export const DesignFeatures = createDomain();

export const getDesignFeatures = DesignFeatures.effect<
    Protocol.GetDesignFeaturesRequest,
    Protocol.GetDesignFeaturesResponse
>();

getDesignFeatures.use(apiDesignFeatures);

export const DesignFeaturesStore = restore<Protocol.GetDesignFeaturesResponse>(getDesignFeatures.doneData, []);
