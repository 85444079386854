import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Button,
    Fade,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useStore } from 'effector-react';
import { useStyles } from './modal/styles';
import { ButtonWithLoading } from './ButtonWithLoading';
import { TariffForChangeItem } from '../types/tariff';
import { apiChangeTariff } from '../api';
import { CurrentContractStore, CurrentDashboardStore, CurrentTariffStore, fetchDashboard } from '../effector/dashboard';
import { foldApiDateToLocalizedDate } from '../utils/view';
import { Protocol } from '../api/protocol';

type Props = {
    tariff: TariffForChangeItem;
    open: boolean;
    onClose: () => void;
    availableDates: Protocol.TariffChangeAvailableDatesResponse;
};
export const TariffChangeConfirm: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const { tariff, open, onClose, availableDates } = props;

    const { enqueueSnackbar } = useSnackbar();

    const tariffs = useStore(CurrentTariffStore);
    const currentContract = useStore(CurrentContractStore);
    const currentDashboard = useStore(CurrentDashboardStore);

    const [date, setDate] = useState(availableDates[0]);
    const [loading, setLoading] = useState(false);

    const onConfirm = (): void => {
        if (!currentContract || !tariffs.current || !currentDashboard || loading) return;
        setLoading(true);

        const payload = {
            ...currentContract,
            tariffGroupId: tariffs.current.gid,
            fromContractTariffId: tariffs.current.id,
            toTariffPlanId: tariff.tpid,
            fromDate: date,
        };

        const updateDashboard = (message: string): void => {
            if (!currentContract) return;
            fetchDashboard(currentContract)
                .then(() => {
                    enqueueSnackbar(message, { variant: 'success' });
                })
                .catch((e) => {
                    enqueueSnackbar(e.message, { variant: 'error' });
                });
        };

        apiChangeTariff(payload)
            .then((response) => {
                updateDashboard(response.result);
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
                onClose();
            });
    };

    useEffect(() => {
        if (availableDates.length > 0) {
            setDate(availableDates[0]);
        }
    }, [availableDates]);

    return (
        <Modal
            open={open}
            onClose={loading ? () => {} : onClose}
            aria-labelledby="Модальное окно подтверждения смены тарифа"
        >
            <Fade in={open}>
                <div className={classes.body}>
                    <Typography variant="h6" className={classes.title}>
                        Вы уверены, что хотите изменить тариф на «{tariff.title}»?
                    </Typography>

                    <div className={classes.date}>
                        <FormControl>
                            <InputLabel id="tariff-change-available-dates">Дата перехода</InputLabel>

                            <Select
                                labelId="tariff-change-available-dates"
                                id="tariff-change-available-dates"
                                value={date}
                                defaultValue={date}
                                onChange={(e) => setDate(e.target.value as DateTime.Date)}
                            >
                                {availableDates.map((item, index) => (
                                    <MenuItem value={item} key={`${item}-${index}`}>
                                        {foldApiDateToLocalizedDate(item)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div className={classes.buttons}>
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            color="secondary"
                            disabled={loading}
                            className={classes.button}
                        >
                            Отменить
                        </Button>

                        <div className={classes.button}>
                            <ButtonWithLoading
                                type="submit"
                                loading={loading}
                                color="primary"
                                onClick={onConfirm}
                                className={classes.buttonConfirm}
                                noMargin
                            >
                                Подтвердить
                            </ButtonWithLoading>
                        </div>
                    </div>

                    <IconButton aria-label="Закрыть" className={classes.close} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </Fade>
        </Modal>
    );
};
