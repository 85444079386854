import React, { FunctionComponent, useEffect, useState } from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import moment from 'moment';

import { useStore } from 'effector-react';
import { CurrentContractStore, CurrentDashboardStore, CurrentTariffStore, fetchDashboard } from '../effector/dashboard';
import { fetchTariffList, TariffListStore } from '../effector/tariffList';

import { apiChangeTariffAvailableDates } from '../api';
import { Protocol } from '../api/protocol';

import { TariffForChangeItem } from '../types/tariff';
import { foldApiDateToLocalizedDate } from '../utils/view';
import { FeipTypograf } from '../utils/typograf';

import { TariffChangeCancelConfirm } from './TariffChangeCancelConfirm';
import { TariffChangeConfirm } from './TariffChangeConfirm';
import { ButtonWithLoading } from './ButtonWithLoading';
import { AccordionLoader } from './AccordionLoader';
import { TariffCard } from './TariffCard';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'grid',
            gridRowGap: 24,
            paddingTop: 8,
            width: '100%',
        },
        changeTariffPlaceholder: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
        changeTariffPlaceholderText: {
            lineHeight: 1.5,
            margin: '12px auto',
            maxWidth: 540,
            textAlign: 'center',
        },
    })
);

type Props = { open: boolean };
export const TariffGrid: FunctionComponent<Props> = ({ open }) => {
    const classes = useStyles();

    const list = useStore(TariffListStore);
    const tariffs = useStore(CurrentTariffStore);
    const loading = useStore(fetchTariffList.pending);
    const currentContract = useStore(CurrentContractStore);
    const currentDashboard = useStore(CurrentDashboardStore);
    const dashboardLoading = useStore(fetchDashboard.pending);

    const [isCancelConfirmModalOpen, setCancelConfirmModalOpen] = useState(false);

    const [loadingDates, setLoadingDates] = useState(false);
    const [availableDates, setAvailableDates] = useState<Protocol.TariffChangeAvailableDatesResponse>([]);

    const [fetched, setFetched] = useState(list.length > 0);
    const [chosenTariff, setChosenTariff] = useState<TariffForChangeItem | null>(null);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const clear = (): void => {
        setConfirmModalOpen(false);
        setChosenTariff(null);
        setAvailableDates([]);
    };

    const fetchAvailableDates = (tariff: TariffForChangeItem): void => {
        if (!currentContract || !currentDashboard || !tariffs.current) return;
        setLoadingDates(true);
        setChosenTariff(tariff);

        const payload = {
            ...currentContract,
            gid: tariffs.current.gid,
            tariffFromId: tariffs.current.tpid,
            tariffToId: tariff.tpid,
        };

        apiChangeTariffAvailableDates(payload)
            .then((response) => {
                if (response.length > 0) {
                    setAvailableDates(response);
                    setConfirmModalOpen(true);
                } else {
                    enqueueSnackbar('Доступные даты для перехода не были найдены', { variant: 'error' });
                    clear();
                }
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
                clear();
            })
            .finally(() => setLoadingDates(false));
    };

    useEffect(() => {
        if (open && currentContract && currentDashboard && !fetched && !loading) {
            setFetched(true);
            fetchTariffList(currentContract).catch(() => setFetched(false));
        }
    }, [loading, fetched, open, currentContract, currentDashboard]);

    // Handle contract id change in header
    useEffect(() => {
        setFetched(false);
    }, [currentContract]);

    if (loading || dashboardLoading || !currentDashboard || !tariffs.current) return <AccordionLoader />;

    const wasReplaced =
        tariffs.current.replacedFrom !== null ? moment.unix(tariffs.current.replacedFrom).isAfter() : false;

    if (!wasReplaced && tariffs.planned) {
        return (
            <AccordionLoader>
                <div className={classes.changeTariffPlaceholder}>
                    <p
                        className={classes.changeTariffPlaceholderText}
                        dangerouslySetInnerHTML={{
                            __html: FeipTypograf.execute(
                                `У вас запланирован переход на новый тариф (${
                                    tariffs.planned.title
                                }) с ${foldApiDateToLocalizedDate(tariffs.planned.dateFrom)}`
                            ),
                        }}
                    />

                    <ButtonWithLoading color="primary" loading={false} onClick={() => setCancelConfirmModalOpen(true)}>
                        Отменить переход
                    </ButtonWithLoading>
                </div>

                <TariffChangeCancelConfirm
                    open={isCancelConfirmModalOpen}
                    onClose={() => setCancelConfirmModalOpen(false)}
                />
            </AccordionLoader>
        );
    }
    if (!loading && fetched && currentDashboard && tariffs.current.tariffsForChange.length === 0) {
        return <AccordionLoader text="Не найдено тарифов для перехода" />;
    }
    return (
        <section className={classes.root}>
            {tariffs.current.tariffsForChange.map((item) => (
                <TariffCard
                    data={item}
                    key={item.tpid}
                    isLoading={Boolean(chosenTariff && item.tpid === chosenTariff.tpid && !isConfirmModalOpen)}
                    isDisabled={loadingDates}
                    onClick={() => fetchAvailableDates(item)}
                />
            ))}

            {chosenTariff && availableDates.length > 0 && (
                <TariffChangeConfirm
                    availableDates={availableDates}
                    tariff={chosenTariff}
                    open={isConfirmModalOpen}
                    onClose={clear}
                />
            )}
        </section>
    );
};
