import { useEffect, useState } from 'react';

export const useOnDestroy = (callback: Noop): void => {
    useEffect(() => {
        return callback;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useOnce = (callback: Noop): void => {
    useEffect(() => {
        callback();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
export const useOnceWithCondition = (callback: Noop, condition: boolean): void => {
    const [called, setCalled] = useState(false);

    useEffect(() => {
        if (!called && condition) {
            setCalled(true);
            callback();
        }
    }, [callback, condition, called]);
};

