import React, { FunctionComponent, useState } from 'react';
import { Button, Fade, IconButton, Modal, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';
import { useStyles } from './modal/styles';
import { apiAutoReplenishmentInfo, apiDeleteAutoReplenishment } from '../api';
import { CurrentContractStore } from '../effector/dashboard';
import { ButtonWithLoading } from './ButtonWithLoading';
import { Protocol } from '../api/protocol';

type Props = {
    open: boolean;
    onClose: () => void;
    updateReplenishmentInfo: (data: Protocol.AutoReplenishmentInfoResponse) => void;
}
export const DeleteAutoReplenishment: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const { open, onClose, updateReplenishmentInfo } = props;

    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const currentContract = useStore(CurrentContractStore);

    const fetchInfo = (): void => {
        if (!currentContract || loading) return;

        apiAutoReplenishmentInfo(currentContract).then((response) => {
            updateReplenishmentInfo(response);
        }).catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error' });
        });
    };

    const onDelete = (): void => {
        if (!currentContract || loading) return;
        setLoading(true);
        apiDeleteAutoReplenishment(currentContract).then((response) => {
            fetchInfo();
            enqueueSnackbar(response.result, { variant: 'success' });
        }).catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error' });
        }).finally(() => {
            setLoading(false);
            onClose();
        });
    };

    return (
        <Modal
            open={open}
            onClose={loading ? () => {} : onClose}
            aria-labelledby="Модальное окно выхода"
        >
            <Fade in={open}>
                <div className={classes.body}>
                    <Typography variant="h6" className={classes.title}>
                        Вы действительно хотите отключить автоплатеж?
                    </Typography>

                    <div className={classes.buttons}>
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            color="secondary"
                            disabled={loading}
                            className={classes.button}
                        >
                            Отменить
                        </Button>

                        <div className={classes.button}>
                            <ButtonWithLoading
                                type="submit"
                                loading={loading}
                                color="primary"
                                onClick={onDelete}
                                className={classes.buttonConfirm}
                                noMargin
                            >
                                Подтвердить
                            </ButtonWithLoading>
                        </div>
                    </div>

                    <IconButton aria-label="Закрыть" className={classes.close} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </Fade>
        </Modal>
    );
};
