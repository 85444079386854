import React, { ReactNode } from 'react';
import moment, { Moment } from 'moment';
import { flow } from 'fp-ts/es6/function';

import type { FieldError } from 'react-hook-form';

export const formatPrice = (
    value: number | string,
    digits = 2,
    separator = ' ',
    removeTrailingZeros = true
): string => {
    const val = parseFloat(value.toString()) / 100;
    const formatted = val
        .toFixed(digits)
        .replace(/(\d)(?=(\d{3})+(\.|$))/g, `$1${separator}`)
        .replace(/\./g, ',');
    return (val * 100) % 100 === 0 && removeTrailingZeros ? formatted.replace(/,\d{2}/, '') : formatted;
};

export const dateFormatter = (format: string) => (date: Moment): string => date.format(format);
export const buildDateFormatter = <T>(parser: (apiDate: T) => Moment) => (format: string) => (date: T): string =>
    parser(date).format(format);

export const foldDate = dateFormatter('DD.MM.YYYY');
export const foldLocalizedDate = dateFormatter('DD MMMM YYYY');

export const parseApiDate = (date: DateTime.Date): Moment => moment(date, 'YYYY-MM-DD');
export const parseApiTimestamp = (date: DateTime.Timestamp): Moment => moment.unix(date);

export const formatApiDate = buildDateFormatter(parseApiDate);
export const formatApiTimestamp = buildDateFormatter(parseApiTimestamp);

export const foldApiTimestampToDate = flow(parseApiTimestamp, foldDate);
export const foldApiTimestampToLocalizedDate = flow(parseApiTimestamp, foldLocalizedDate);
export const foldApiTimestampToFullDatetime = formatApiTimestamp('DD.MM.YYYY HH:mm:ss');

export const foldApiDateToDate = flow(parseApiDate, foldDate);
export const foldApiDateToLocalizedDate = flow(parseApiDate, foldLocalizedDate);

const ByteSize = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ'];
export const formatBytes = (bytes: string): string => {
    let size = 0;
    let current = parseFloat(bytes);
    while (current > 1024 && size !== 4) {
        current /= 1024;
        size += 1;
    }
    return `${current.toFixed(2)} ${ByteSize[size]}`;
};

export const formatErrorText = (error?: FieldError): React.ReactNode | null =>
    error && error.message ? error.message : null;

type LazyRender = () => ReactNode;
export const foldLoadingConditionView = (loading: boolean, condition: boolean) => (
    loadingRender: LazyRender,
    trueRender: LazyRender,
    falseRender: LazyRender
): ReactNode => {
    if (loading) return loadingRender();
    return condition ? trueRender() : falseRender();
};

export const foldViewState = <T>(data: T | null | undefined, loading: boolean, error: null | string) => (
    emptyNode: () => ReactNode,
    loadingNode: () => ReactNode,
    errorNode: (errorData: typeof error) => ReactNode,
    viewNode: (componentData: T) => ReactNode
): ReactNode => {
    if (loading) return loadingNode();
    if (error) return errorNode(error);
    if (!data || (Array.isArray(data) && data.length === 0)) return emptyNode();
    return viewNode(data);
};
