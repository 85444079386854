type ipResponse = { ip: string };

export const getIp = async (): Promise<string> => {
    try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        if (data.ip) return data.ip as string;
        return '';
    } catch (error) {
        return '';
    }
};
