import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    Button,
    FormControl,
    InputAdornment,
    IconButton,
    TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import { apiChangeContractPassword } from '../../api';
import { CurrentContractStore } from '../../effector/dashboard';

type FormInputs = {
    oldpassword: string;
    newpassword: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginBottom: 8,
            '&:not(:last-child)': {
                marginRight: 16,
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                marginRight: 0,
            },
        },
        buttonWrapper: {
            margin: 0,
            marginTop: 8,
        },
        button: {
            height: 40,
            minWidth: 112,
        },
        form: {
            paddingTop: 16,
            paddingBottom: 16,
        },
        input: {
            borderRadius: 5,
        },
        textField: {
            width: '100%',
            '& .MuiFormLabel-root': {
                color: theme.palette.text.secondary,
            },
            '& .MuiInputBase-root': {
                backgroundColor: '#F5F5F5',
            },
            '& label.Mui-focused': {
                color: theme.palette.text.secondary,
            },
        },
    })
);

export const ContractPassword: FunctionComponent = () => {
    const [visible, setVisible] = useState({
        oldPassword: false,
        newPassword: false,
    });
    const { register, handleSubmit } = useForm<FormInputs>();
    const { enqueueSnackbar } = useSnackbar();
    const currentContract = useStore(CurrentContractStore);
    const classes = useStyles();

    const onSubmit = (data: FormInputs): void => {
        if (currentContract) {
            apiChangeContractPassword({ ...currentContract, ...data })
                .then(() =>
                    enqueueSnackbar('Пароль успешно изменен', {
                        variant: 'success',
                    })
                )
                .catch((e) =>
                    enqueueSnackbar(e.message, {
                        variant: 'error',
                    })
                );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <FormControl
                size="small"
                variant="outlined"
                className={classes.formControl}
            >
                <TextField
                    classes={{ root: classes.textField }}
                    name="oldpassword"
                    id="contract-oldpassword"
                    inputRef={register}
                    type={visible.oldPassword ? 'text' : 'password'}
                    label="Старый пароль"
                    variant="filled"
                    InputProps={{
                        classes: {
                            root: classes.input,
                        },
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    size="medium"
                                    aria-label="Показать или скрыть пароль"
                                    onClick={() =>
                                        setVisible({
                                            ...visible,
                                            oldPassword: !visible.oldPassword,
                                        })
                                    }
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {visible.oldPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>

            <FormControl
                size="small"
                variant="outlined"
                className={classes.formControl}
            >
                <TextField
                    classes={{ root: classes.textField }}
                    type={visible.newPassword ? 'text' : 'password'}
                    name="newpassword"
                    id="contract-newpassword"
                    inputRef={register}
                    label="Новый пароль"
                    variant="filled"
                    InputProps={{
                        classes: {
                            root: classes.input,
                        },
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    size="medium"
                                    aria-label="Показать или скрыть пароль"
                                    onClick={() =>
                                        setVisible({
                                            ...visible,
                                            newPassword: !visible.newPassword,
                                        })
                                    }
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {visible.newPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
            <div className={classes.buttonWrapper}>
                <Button
                    className={classes.button}
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Изменить
                </Button>
            </div>
        </form>
    );
};
