import React, { FunctionComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'effector-react';
import { CurrentContractStore } from '../effector/dashboard';
import { ServiceListStore, fetchServiceList } from '../effector/serviceList';
import { foldApiTimestampToDate } from '../utils/view';
import { FeipTypograf } from '../utils/typograf';
import { Table, TableColumns } from '../containers/Table';
import { AccordionLoader } from './AccordionLoader';
import { ServiceListItem } from '../types/service';

const columns: TableColumns<ServiceListItem> = [
    {
        id: 'id',
        label: '№',
        format: (value: string) => <Link to={`/settings?service=${value}`}>{value}</Link>,
    },
    {
        id: 'title',
        label: 'Сервис',
        format: (value: string) => FeipTypograf.execute(value),
    },
    {
        id: 'dateFrom',
        label: 'Открыт',
        align: 'left',
        format: foldApiTimestampToDate,
    },
    {
        id: 'dateTo',
        label: 'Закрыт',
        align: 'left',
        format: (value: DateTime.Timestamp | null) => (value ? foldApiTimestampToDate(value) : '—'),
    },
    {
        id: 'isPPPoE',
        label: 'PPPoE',
        align: 'center',
        format: (value) => (value ? 'Да' : 'Нет'),
    },
];

type Props = { open: boolean };
export const ServiceList: FunctionComponent<Props> = ({ open }) => {
    const { list, fetched } = useStore(ServiceListStore);
    const loading = useStore(fetchServiceList.pending);
    const currentContract = useStore(CurrentContractStore);

    useEffect(() => {
        if (open && currentContract && !fetched && !loading) {
            fetchServiceList(currentContract);
        }
    }, [loading, fetched, open, currentContract]);

    if (loading) return <AccordionLoader />;
    if (!loading && list.length === 0) return <AccordionLoader text="Не найдено данных для отображения" />;

    return <Table columns={columns} keyProp="title" data={list} />;
};
