import { createDomain, combine } from 'effector';
import withStorage from 'effector-storage';

import * as O from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/function';
import { deleteAt, insertAt, lookup } from 'fp-ts/es6/Record';

import { apiDeletePushToken, apiRegisterPushToken } from '../api';
import { Protocol } from '../api/protocol';

import { ContractHash, makeContractHash } from '../types/contract';
import { CurrentContractStore } from './dashboard';

export const NotificationsDomain = createDomain();

export const clearNotificationsState = NotificationsDomain.event();

export const deleteToken = NotificationsDomain.effect<
    Protocol.DeletePushTokenRequest,
    Protocol.DeletePushTokenResponse
>();
export const registerToken = NotificationsDomain.effect<
    Protocol.RegisterPushTokenRequest,
    Protocol.RegisterPushTokenResponse
>();

deleteToken.use(apiDeletePushToken);
registerToken.use(apiRegisterPushToken);

const initialState: Record<ContractHash, string> = {};
const createStorageStore = withStorage(NotificationsDomain.store);
const NotificationsStore = createStorageStore(initialState, { key: 'notifications' })
    .on(registerToken.done, (state, { params: { token, ...contract } }) =>
        insertAt(makeContractHash(contract), token)(state)
    )
    .on(deleteToken.done, (state, { params: { token, ...contract } }) => deleteAt(makeContractHash(contract))(state))
    .reset(clearNotificationsState);

export const CurrentContractPushTokenStore = combine(NotificationsStore, CurrentContractStore, (config, contact) =>
    pipe(
        O.fromNullable(contact),
        O.map(makeContractHash),
        O.chain((hash) => lookup(hash)(config)),
        O.toNullable
    )
);
