import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Fade, IconButton, Modal, TextField, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Close as CloseIcon, Delete, Edit } from '@material-ui/icons';
import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';
import ReactInputMask from 'react-input-mask';

import { apiSetParamPhones } from '../../api';
import { CurrentContractStore, CurrentParamsStore, updateContactPhones } from '../../effector/dashboard';
import { cleanPhone, formatPhone, tel } from '../../utils/tel';
import { useStyles as modalStyles } from '../modal/styles';
import { useStyles as useChangeParamStyles } from './styles';
import { ButtonWithLoading } from '../ButtonWithLoading';
import { Protocol } from '../../api/protocol';
import { EditButton } from '../EditButton';
import { ContactPhone } from '../../types/contract';

type SetPhoneProps = {
    data?: ContactPhone;
    mode?: 'edit' | 'add';
};
export const SetPhone: FunctionComponent<SetPhoneProps> = (props) => {
    const { data, mode = 'edit' } = props;

    const isEditMode = mode === 'edit';

    const modalClasses = modalStyles();
    const classes = useChangeParamStyles();

    const [isModalOpen, setModalOpen] = useState(false);
    const [phone, setPhone] = useState(data !== undefined ? data.phone : '');
    const [comment, setComment] = useState(data !== undefined ? data.comment : '');
    const [loading, setLoading] = useState(false);

    const currentContract = useStore(CurrentContractStore);
    const currentParams = useStore(CurrentParamsStore);
    const { enqueueSnackbar } = useSnackbar();

    const closeModal = (): void => setModalOpen(false);
    const clear = (): void => {
        setPhone('');
        setComment('');
    };

    useEffect(() => {
        if (data !== undefined && isEditMode) {
            setPhone(data.phone);
            setComment(data.comment);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const updatePhones = (index?: number): void => {
        if (!currentContract) return;

        let newValues: ContactPhone[] = [];
        newValues = currentParams.contactPhones.map((item) => {
            if (item.index === index) {
                return {
                    ...item,
                    phone: cleanPhone(phone),
                    comment,
                };
            }

            return item;
        });

        if (index === undefined) {
            const lastItem = currentParams.contactPhones.pop();
            newValues.push({
                index: (lastItem !== undefined ? lastItem.index : currentParams.contactPhones.length) + 1,
                phone: cleanPhone(phone),
                comment,
            });
        }

        setLoading(true);
        const payload: Protocol.SetParamPhonesRequest = {
            ...currentContract,
            value: JSON.stringify({ phones: newValues }),
        };

        apiSetParamPhones(currentContract, payload)
            .then((response) => {
                enqueueSnackbar(response.result, { variant: 'success' });
                updateContactPhones(newValues);
                if (!isEditMode) clear();
                closeModal();
            })
            .catch(({ message }) => enqueueSnackbar(message, { variant: 'error' }))
            .finally(() => setLoading(false));
    };

    return (
        <>
            {isEditMode ? (
                <IconButton size="small" edge="end" aria-label="Изменить телефон" onClick={() => setModalOpen(true)}>
                    <Edit fontSize="small" />
                </IconButton>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => setModalOpen(true)}
                >
                    Добавить телефон
                </Button>
            )}

            <Modal open={isModalOpen} onClose={closeModal}>
                <Fade in={isModalOpen}>
                    <div className={modalClasses.body}>
                        <Typography variant="h6" className={modalClasses.title}>
                            {isEditMode ? 'Изменение' : 'Добавление'} телефона
                        </Typography>

                        <div className={classes.form}>
                            <ReactInputMask
                                mask={tel.reactInputMask}
                                value={phone}
                                onChange={(event) => setPhone(event.target.value)}
                            >
                                <TextField label="Телефон" />
                            </ReactInputMask>

                            <TextField
                                value={comment}
                                label="Комментарий"
                                onChange={(event) => setComment(event.target.value)}
                            />
                        </div>

                        <ButtonWithLoading
                            type="submit"
                            loading={loading}
                            color="primary"
                            onClick={() => updatePhones(data !== undefined ? data.index : undefined)}
                            noMargin
                            fullWidth
                        >
                            {isEditMode ? 'Сохранить изменения' : 'Добавить телефон'}
                        </ButtonWithLoading>

                        <IconButton aria-label="Закрыть" className={modalClasses.close} onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

type DeletePhoneProps = { data: ContactPhone };
export const DeletePhone: FunctionComponent<DeletePhoneProps> = (props) => {
    const { data } = props;

    const modalClasses = modalStyles();

    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const currentContract = useStore(CurrentContractStore);
    const currentParams = useStore(CurrentParamsStore);
    const { enqueueSnackbar } = useSnackbar();

    const closeModal = (): void => setModalOpen(false);

    const deletePhone = (index: number): void => {
        if (!currentContract) return;

        const newValues = currentParams.contactPhones.filter((item) => item.index !== index);

        setLoading(true);
        const payload: Protocol.SetParamPhonesRequest = {
            ...currentContract,
            value: JSON.stringify({ phones: newValues }),
        };

        apiSetParamPhones(currentContract, payload)
            .then((response) => {
                enqueueSnackbar(response.result, { variant: 'success' });
                closeModal();
                setLoading(false);
                updateContactPhones(newValues);
            })
            .catch(({ message }) => {
                enqueueSnackbar(message, { variant: 'error' });
                setLoading(false);
            });
    };

    return (
        <>
            <IconButton size="small" edge="end" aria-label="Удалить телефон" onClick={() => setModalOpen(true)}>
                <Delete fontSize="small" />
            </IconButton>

            <Modal open={isModalOpen} onClose={closeModal}>
                <Fade in={isModalOpen}>
                    <div className={modalClasses.body}>
                        <Typography variant="h6" className={modalClasses.title}>
                            Удалить телефон ({formatPhone(data.phone)})?
                        </Typography>

                        <div className={modalClasses.buttons}>
                            <Button
                                onClick={closeModal}
                                variant="outlined"
                                color="secondary"
                                className={modalClasses.button}
                            >
                                Отменить
                            </Button>

                            <ButtonWithLoading
                                type="submit"
                                loading={loading}
                                color="primary"
                                onClick={() => deletePhone(data.index)}
                                className={clsx([modalClasses.button, modalClasses.buttonConfirm])}
                                noMargin
                            >
                                Подтвердить
                            </ButtonWithLoading>
                        </div>

                        <IconButton aria-label="Закрыть" className={modalClasses.close} onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

export const ChangePhonesParam: FunctionComponent = () => {
    const modalClasses = modalStyles();
    const classes = useChangeParamStyles();

    const [isModalOpen, setModalOpen] = useState(false);
    const currentParams = useStore(CurrentParamsStore);
    const closeModal = (): void => setModalOpen(false);

    return (
        <>
            <EditButton onClick={() => setModalOpen(true)} withTopGutter={false} />

            <Modal open={isModalOpen} onClose={closeModal}>
                <Fade in={isModalOpen}>
                    <div className={modalClasses.body}>
                        <Typography variant="h6" className={modalClasses.title}>
                            Изменить телефоны
                        </Typography>

                        <div className={clsx([classes.form, classes.formLargeGutter])}>
                            {currentParams.contactPhones.map((item, index) => (
                                <div key={`change-phone-item-${index}`} className={classes.contactItem}>
                                    <div className={classes.contactWrapper}>
                                        <p className={classes.contactValue}>{formatPhone(item.phone)}</p>
                                        {item.comment && <p className={classes.contactComment}>{item.comment}</p>}
                                    </div>

                                    <div className={classes.contactButtons}>
                                        <SetPhone data={item} />
                                        <DeletePhone data={item} />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <SetPhone mode="add" />

                        <IconButton aria-label="Закрыть" className={modalClasses.close} onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};
