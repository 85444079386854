import { createDomain, createEvent } from 'effector';
import withStorage from 'effector-storage';
import { Protocol } from '../api/protocol';
import { ApiClient, Auth, apiLogin, apiRefreshToken } from '../api';
import { ContractEntity } from '../types/contract';

export type SystemState = {
    contracts: ContractEntity[];
    loggedIn: boolean;
    token: string | null;
};

export const SystemDomain = createDomain();

export const clearSession = SystemDomain.event();
export const updateSession = SystemDomain.effect<
    Protocol.LoginRequest,
    Protocol.LoginResponse
>();
export const refreshToken = SystemDomain.effect<
    Protocol.RefreshTokenRequest,
    Protocol.RefreshTokenResponse
>();

export const setContracts = createEvent<ContractEntity[]>();

updateSession.use(apiLogin);
refreshToken.use(apiRefreshToken);

ApiClient.setInvalidTokenHandler(() => clearSession());

const createStorageStore = withStorage(SystemDomain.store);

const initialState: SystemState = {
    contracts: [],
    loggedIn: false,
    token: null,
};

const cfg = { key: 'system' };
export const SystemStore = createStorageStore<SystemState>(initialState, cfg)
    .on(
        [updateSession.doneData, refreshToken.doneData],
        (state, { token, contracts }) => ({
            loggedIn: true,
            contracts,
            token,
        })
    )
    .on(setContracts, (state, contracts) => ({
        ...state,
        contracts,
    }))
    .reset(clearSession);

export const SystemContractsStore = SystemStore.map((state) => state.contracts);

SystemStore.map((state) => state.token).watch((token) => Auth.setToken(token));
