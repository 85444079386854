import type { FC, MouseEvent, MouseEventHandler } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import ReactPlayer from 'react-player';

import { createStyles, makeStyles } from '@material-ui/core';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import FastRewindRoundedIcon from '@material-ui/icons/FastRewindRounded';
import FastForwardRoundedIcon from '@material-ui/icons/FastForwardRounded';

import { useDebouncedCallback } from '../hooks/debouncedCallback';
import { useMultipleClick } from '../hooks/multipleClick';

import { isScreenSizeDown } from '../utils/media';

import { CustomIconButton } from './CustomIconButton';

import fullscreenEnterSvgScr from '../assets/icons/fullscreen-enter.svg';
import fullscreenExitSvgScr from '../assets/icons/fullscreen-exit.svg';

type EventFunction = () => void;

type VideoEvent = 'play' | 'pause' | 'enterFullscreen' | 'exitFullscreen';

type Elements = Nullable<{
    player: HTMLDivElement;
    video: ReactPlayer;
    togglePlayButton: HTMLButtonElement;
    controls: HTMLDivElement;
    substrate: HTMLButtonElement;
    timeline: HTMLProgressElement;
    spareTimelineValue: HTMLSpanElement;
    leftRewindCircle: HTMLSpanElement;
    rightRewindCircle: HTMLSpanElement;
    timeCaption: HTMLSpanElement;
    hoveredTime: HTMLSpanElement;
}>;

const HIDE_CONTROLS_DELAY_MS = 2000;
const HIDE_REWIND_CIRCLE_DELAY_MS = 1000;

// z-index value of native controls
const CONTROLS_Z_INDEX = 2147483647;
const SUBSTRATE_Z_INDEX = CONTROLS_Z_INDEX + 1;
const CONTROL_Z_INDEX = CONTROLS_Z_INDEX + 2;

const RewindCaption = <>5&nbsp;сек</>;

const formatTime = (seconds: number): string => {
    const format = (time: number): string => time.toString().padStart(2, '0');

    const totalMinutes = Math.floor(seconds / 60);
    const minutes = Math.floor(totalMinutes % 60);
    const hours = Math.floor(totalMinutes / 60);

    const smartFormat = `${format(minutes)}:${format(Math.floor(seconds % 60))}`;

    return hours > 0 ? `${format(hours)}:${smartFormat}` : smartFormat;
};

const useStyles = makeStyles(() =>
    createStyles({
        unstyledNativeButton: {
            position: 'relative',
            display: 'flex',
            backgroundColor: 'transparent',
            border: 'none',
            borderRadius: 0,
            padding: 0,
            cursor: 'pointer',
        },
        unselectable: {
            '-webkit-user-select': 'none',
            '-ms-user-select': 'none',
            userSelect: 'none',
        },
        player: {
            position: 'relative',
            width: '100%',
            backgroundColor: '#D0D0D0',
            borderRadius: 5,
        },
        video: {
            width: '100%',
            height: '100%',
            '&::-webkit-media-controls': {
                display: 'none !important',
            },
            '& video': {
                maxHeight: '420px',
            },
        },
        fullscreenVideo: {
            '& video': {
                maxHeight: 'none',
            },
        },
        controls: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            transition: 'opacity 0.6s ease',
            top: 0,
            left: 0,
            justifyContent: 'flex-end',
            zIndex: CONTROLS_Z_INDEX,
        },
        substrate: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: 5,
            top: 0,
            left: 0,
            zIndex: SUBSTRATE_Z_INDEX,
            '&:focus': {
                outline: 'none',
            },
        },
        rewindableSubstrate: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 148px',
        },
        rewindCircle: {
            display: 'flex',
            flexDirection: 'column',
            width: 78,
            height: 78,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            color: '#FFFFFF',
            borderRadius: '50%',
            fontFamily: 'IBM Plex Sans',
            fontSize: 14,
            transition: 'opacity 0.4s ease',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: '0',
        },
        timeline: {
            width: '100%',
            height: 8,
            backgroundColor: '#55524D',
            border: 'none',
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            zIndex: CONTROL_Z_INDEX,
            '&::-webkit-progress-value': {
                backgroundColor: '#FAB000',
            },
            '&::-moz-progress-bar': {
                backgroundColor: '#FAB000',
            },
        },
        spareTimeline: {
            width: '100%',
            height: 8,
            backgroundColor: '#55524D',
            zIndex: CONTROL_Z_INDEX,
        },
        spareTimelineValue: {
            backgroundColor: '#FAB000',
        },
        controlsPanel: {
            display: 'flex',
            width: '100%',
            height: 48,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            borderRadius: '0 0 5px 5px',
            alignItems: 'center',
            zIndex: CONTROL_Z_INDEX,
        },
        togglePlayButton: {
            maxHeight: '100%',
            color: '#FFFFFF',
            borderBottomLeftRadius: 5,
            padding: 12,
        },
        fullscreenButton: {
            borderBottomRightRadius: 5,
            padding: 12,
            marginLeft: 'auto',
        },
        timeCaption: {
            color: '#FFFFFF',
            fontSize: 14,
        },
        hoveredTime: {
            position: 'absolute',
            display: 'none',
            width: 'max-content',
            background: 'linear-gradient(0, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #96928B',
            color: '#FFFFFF',
            borderRadius: 5,
            fontSize: 14,
            transform: 'translate(-50%, -100%)',
            padding: '2px 6px',
            top: -8,
        },
    })
);

type VideoPlayerProps = {
    source: string;
    posterSrc?: string;
    isFLV?: boolean;
};

export const VideoPlayer: FC<VideoPlayerProps> = (props) => {
    const { source, posterSrc, isFLV = false } = props;

    const classes = useStyles();

    const [isPlaying, setPlayingState] = useState(false);
    const [isInFullscreen, setInFullscreenState] = useState(false);

    const isTimelineTouched = useRef<boolean>(false);

    const elementsRef = useRef<Elements>({
        player: null,
        video: null,
        togglePlayButton: null,
        controls: null,
        substrate: null,
        timeline: null,
        spareTimelineValue: null,
        leftRewindCircle: null,
        rightRewindCircle: null,
        timeCaption: null,
        hoveredTime: null,
    });

    const hideControlsDebounced = useDebouncedCallback(
        () => {
            if (isPlaying) {
                const { controls } = elementsRef.current;
                if (controls !== null) controls.style.opacity = '0';
            }
        },
        [isPlaying],
        HIDE_CONTROLS_DELAY_MS
    );

    const hideRewindLeftCircleDebounced = useDebouncedCallback(
        () => {
            const circle = elementsRef.current.leftRewindCircle;
            if (circle !== null) circle.style.opacity = '0';
        },
        [],
        HIDE_REWIND_CIRCLE_DELAY_MS
    );

    const hideRewindRightCircleDebounced = useDebouncedCallback(
        () => {
            const circle = elementsRef.current.rightRewindCircle;
            if (circle !== null) circle.style.opacity = '0';
        },
        [],
        HIDE_REWIND_CIRCLE_DELAY_MS
    );

    const setRef = <K extends keyof Elements>(key: K) => (element: Elements[K]) => {
        elementsRef.current[key] = element;
    };

    const handleVideoEvent = (event: VideoEvent): EventFunction => (): void => {
        const { player, video } = elementsRef.current;

        if (video === null || player === null) return;
        switch (event) {
            case 'play':
                if (!isPlaying) {
                    setPlayingState(true);
                    hideControlsDebounced();
                }
                break;

            case 'pause':
                if (isPlaying) {
                    setPlayingState(false);
                }
                break;

            case 'enterFullscreen':
                if (
                    document.fullscreenEnabled ||
                    document.webkitFullscreenEnabled ||
                    document.mozFullScreenEnabled ||
                    document.msFullscreenEnabled
                ) {
                    if (player.requestFullscreen !== undefined) {
                        player.requestFullscreen();
                    } else if (player.webkitRequestFullscreen !== undefined) {
                        player.webkitRequestFullscreen();
                    } else if (player.mozRequestFullScreen !== undefined) {
                        player.mozRequestFullScreen();
                    } else if (player.msRequestFullscreen !== undefined) {
                        player.msRequestFullscreen();
                    }
                }
                break;

            case 'exitFullscreen':
                if (document.fullscreenElement !== null) {
                    if (document.exitFullscreen !== undefined) {
                        document.exitFullscreen();
                    } else if (document.webkitExitFullscreen !== undefined) {
                        document.webkitExitFullscreen();
                    } else if (document.mozCancelFullScreen !== undefined) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen !== undefined) {
                        document.msExitFullscreen();
                    }
                }
                break;

            default:
                break;
        }
    };

    const handleDoubleSubstrateClick = useMultipleClick(2, (event: MouseEvent) => {
        const isScreenSmall = isScreenSizeDown('md');

        if (!isScreenSmall) {
            (isInFullscreen ? handleVideoEvent('exitFullscreen') : handleVideoEvent('enterFullscreen'))();
            return;
        }

        const { video, substrate, leftRewindCircle, rightRewindCircle } = elementsRef.current;

        if (video !== null && substrate !== null) {
            const { left, width } = substrate.getBoundingClientRect();
            const { pageX } = event;

            if (pageX >= left && pageX < left + width) {
                if (pageX >= left + width / 2) {
                    if (rightRewindCircle !== null && leftRewindCircle !== null) {
                        rightRewindCircle.style.opacity = '1';
                        leftRewindCircle.style.opacity = '0';
                        hideRewindRightCircleDebounced();
                    }

                    video.seekTo(
                        video.getCurrentTime() + 5 >= video.getDuration()
                            ? video.getDuration()
                            : video.getCurrentTime() + 5
                    );
                } else {
                    if (leftRewindCircle !== null && rightRewindCircle !== null) {
                        leftRewindCircle.style.opacity = '1';
                        rightRewindCircle.style.opacity = '0';
                        hideRewindLeftCircleDebounced();
                    }

                    video.seekTo(video.getCurrentTime() - 5 <= 0 ? 0 : video.getCurrentTime() - 5);
                }

                isTimelineTouched.current = true;
            }
        }
    });

    const handlePlayerMouseMove = (): void => {
        const { controls } = elementsRef.current;

        if (controls !== null) {
            controls.style.opacity = '1';
            hideControlsDebounced();
        }
    };

    const handleVideoMetadataLoaded = (): void => {
        const { video, timeline, timeCaption } = elementsRef.current;

        if (video !== null && video.getDuration() !== null && !Number.isNaN(video.getDuration())) {
            if (timeline !== null) {
                timeline.setAttribute('max', video.getDuration().toString());
            }

            if (timeCaption !== null) {
                const formattedTime = formatTime(video.getDuration());

                timeCaption.innerText = isFLV
                    ? ''
                    : `${formattedTime.length >= 8 ? '00:00:00' : '00:00'} / ${formattedTime}`;
            }
        }
    };

    const handleVideoTimeUpdate = (): void => {
        const { video, timeline, spareTimelineValue, timeCaption } = elementsRef.current;

        if (timeline !== null && video !== null) {
            timeline.value = isFLV && !isTimelineTouched.current ? video.getDuration() : video?.getCurrentTime() ?? 0;
        }

        if (video !== null) {
            if (spareTimelineValue !== null) {
                spareTimelineValue.style.width = `${(video.getDuration() / 100) * video.getCurrentTime()}%`;
            }

            if (timeCaption !== null && video.getDuration() !== null && !Number.isNaN(video.getDuration())) {
                let currentTime = formatTime(video.getCurrentTime());
                let duration = formatTime(video.getDuration());

                if (currentTime.length >= 8 && duration.length < 8) {
                    duration = duration.padStart(8, '00:');
                }

                if (duration.length >= 8 && currentTime.length < 8) {
                    currentTime = currentTime.padStart(8, '00:');
                }

                if (timeline !== null) {
                    timeline.setAttribute('max', video.getDuration().toString());
                }

                timeCaption.innerText = isFLV ? '' : `${currentTime} / ${duration}`;
            }
        }
    };

    const handleTimelineClick: MouseEventHandler<HTMLButtonElement> = (event): void => {
        const { video, timeline } = elementsRef.current;
        if (timeline !== null && video !== null) {
            const time =
                ((event.pageX - timeline.getBoundingClientRect().left) / timeline.offsetWidth) * video.getDuration();
            video.seekTo(Number.isNaN(time) ? 0 : time);

            isTimelineTouched.current = true;
        }
    };

    const handleTimelineMouseMove: MouseEventHandler<HTMLButtonElement> = (event): void => {
        const { video, timeline, hoveredTime } = elementsRef.current;

        if (video !== null && timeline !== null && hoveredTime !== null) {
            const time =
                ((event.pageX - timeline.getBoundingClientRect().left) / timeline.offsetWidth) * video.getDuration();

            if (Number.isNaN(time)) return;

            if (isFLV) {
                const formattedTime = formatTime(video.getDuration() - time);
                hoveredTime.innerText = formattedTime === '00:00' ? formattedTime : `-${formattedTime}`;
            } else {
                hoveredTime.innerText = formatTime(time);
            }

            const timelineRect = timeline.getBoundingClientRect();

            hoveredTime.style.left = `${event.pageX - timelineRect.left}px`;
        }
    };

    const handleTimelineMouseEnter = (): void => {
        const { video, hoveredTime } = elementsRef.current;

        if (video !== null && hoveredTime !== null) {
            hoveredTime.style.display = 'inline';
        }
    };

    const handleTimelineMouseLeave = (): void => {
        const { hoveredTime } = elementsRef.current;

        if (hoveredTime !== null) {
            hoveredTime.style.display = 'none';
        }
    };

    useEffect(() => {
        const handleFullscreenChange = (): void => {
            elementsRef.current.togglePlayButton?.focus();
            setInFullscreenState(document.fullscreenElement !== null);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    return (
        <div ref={setRef('player')} className={classes.player} onMouseMove={handlePlayerMouseMove}>
            <div className={clsx(classes.video, isInFullscreen && classes.fullscreenVideo)}>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <ReactPlayer
                    muted
                    width="100%"
                    height="100%"
                    preload="metadata"
                    url={source}
                    playing={isPlaying}
                    className={classes.unselectable}
                    poster={posterSrc}
                    ref={setRef('video')}
                    onLoadedMetadata={handleVideoMetadataLoaded}
                    onTimeUpdate={handleVideoTimeUpdate}
                    config={{
                        file: {
                            forceFLV: isFLV,
                            forceVideo: !isFLV,
                        },
                    }}
                />
            </div>

            <div ref={setRef('controls')} className={classes.controls}>
                <button
                    ref={setRef('substrate')}
                    type="button"
                    aria-label="substrate"
                    className={clsx(classes.substrate, classes.unstyledNativeButton, classes.rewindableSubstrate)}
                    onClick={handleDoubleSubstrateClick}
                >
                    <span ref={setRef('leftRewindCircle')} className={clsx(classes.rewindCircle, classes.unselectable)}>
                        <FastRewindRoundedIcon />
                        {RewindCaption}
                    </span>
                    <span
                        ref={setRef('rightRewindCircle')}
                        className={clsx(classes.rewindCircle, classes.unselectable)}
                    >
                        <FastForwardRoundedIcon />
                        {RewindCaption}
                    </span>
                </button>

                <button
                    type="button"
                    className={classes.unstyledNativeButton}
                    onClick={handleTimelineClick}
                    onMouseMove={handleTimelineMouseMove}
                    onMouseEnter={handleTimelineMouseEnter}
                    onMouseLeave={handleTimelineMouseLeave}
                >
                    <span ref={setRef('hoveredTime')} className={classes.hoveredTime} />

                    <progress ref={setRef('timeline')} className={classes.timeline}>
                        <div className={classes.spareTimeline}>
                            <span ref={setRef('spareTimelineValue')} className={classes.spareTimelineValue} />
                        </div>
                    </progress>
                </button>

                <div className={classes.controlsPanel}>
                    <button
                        type="button"
                        aria-label="toggle-play"
                        className={clsx(classes.togglePlayButton, classes.unstyledNativeButton)}
                        onClick={handleVideoEvent(isPlaying ? 'pause' : 'play')}
                    >
                        {isPlaying ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
                    </button>

                    <span ref={setRef('timeCaption')} className={classes.timeCaption}>
                        {isFLV ? '' : '00:00 / 00:00'}
                    </span>

                    <CustomIconButton
                        src={isInFullscreen ? fullscreenExitSvgScr : fullscreenEnterSvgScr}
                        aria-label="toggle-fullscreen"
                        imageOptions={{
                            width: 24,
                            height: 24,
                            color: '#FFFFFF',
                        }}
                        className={classes.fullscreenButton}
                        onClick={handleVideoEvent(isInFullscreen ? 'exitFullscreen' : 'enterFullscreen')}
                    />
                </div>
            </div>
        </div>
    );
};
