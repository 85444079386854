import { Moment } from 'moment';

export const sanitizePagination = (raw: Pagination): Pagination => ({
    pageSize: Math.max(raw.pageSize, 10),
    pageCount: Math.max(raw.pageCount, 1),
    pageNumber: Math.max(raw.pageNumber, 1),
    pageTotalRecordsCount: raw.pageTotalRecordsCount,
});

type YearMonth = {
    year: number;
    month: number;
};

export const foldDateIntoYearMonth = (date: Moment): YearMonth => ({
    year: date.year(),
    month: date.month() + 1,
});

export const downloadBlob = (blob: Blob, name: string): void => {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        })
    );

    // Remove link from body
    document.body.removeChild(link);
};
