import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { Close as CloseIcon, MailOutline, Phone, ChatBubbleOutline, SvgIconComponent } from '@material-ui/icons';
import { Fade, IconButton, Modal, TextField, Typography } from '@material-ui/core';

import { clearSession } from '../effector/system';
import { CurrentContractStore, CurrentParamsStore } from '../effector/dashboard';

import { apiCreateFeedback } from '../api';
import { Protocol } from '../api/protocol';

import { PHONE_RU_CLEANED_LENGTH } from '../utils/constants';
import { firstContact } from '../utils/strings';
import { cleanPhone, tel } from '../utils/tel';

import { useCarrotQuest } from '../hooks/carrotQuest';

import { ButtonWithLoading } from './ButtonWithLoading';
import { HelpButtonCard } from './HelpButtonCard';

import { useStyles } from './modal/styles';

export type FeedbackType = 'chat' | 'feedback' | 'callRequest';

type MessageType = {
    description: string;
    icon: SvgIconComponent;
    sendButtonText?: string;
    action?: Noop;
};

const Messages: Record<FeedbackType, MessageType> = {
    chat: {
        description: 'Оператор ответит вам в онлайн-чате',
        icon: ChatBubbleOutline,
        action: () => {
            const w = window;
            if (w.carrotquest !== undefined) {
                w.carrotquest.open();
            }
        },
    },
    feedback: {
        description: 'Письмо на почту тех.поддержки',
        sendButtonText: 'Отправить сообщение',
        icon: MailOutline,
    },
    callRequest: {
        description: 'Оператор свяжется с вами по телефону',
        sendButtonText: 'Отправить заявку',
        icon: Phone,
    },
};

type Props = { title: string; id?: string; type: FeedbackType };
export const Feedback: FunctionComponent<Props> = (props) => {
    const { id, title, type } = props;

    const { chatInstance, chatState } = useCarrotQuest();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const isCallRequest = type === 'callRequest';

    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');

    const currentContract = useStore(CurrentContractStore);
    const currentParams = useStore(CurrentParamsStore);

    const closeModal = (): void => setOpenModal(false);

    const createRequest = (): void => {
        if (!currentContract) return;

        const cleanedPhone = cleanPhone(phone);
        if (cleanedPhone.length !== PHONE_RU_CLEANED_LENGTH && (email.length === 0 || isCallRequest)) {
            enqueueSnackbar('Введите корректный номер телефона', { variant: 'warning' });
            return;
        }

        if (!isCallRequest) {
            if (email.length === 0 && cleanedPhone.length !== PHONE_RU_CLEANED_LENGTH) {
                enqueueSnackbar('Необходимо ввести Email или телефон', { variant: 'warning' });
                return;
            }

            if (text.length === 0) {
                enqueueSnackbar('Введите сообщение', { variant: 'warning' });
                return;
            }
        }

        setLoading(true);
        let payload: Protocol.AddFeedbackRequest = { ...currentContract };

        if (isCallRequest) {
            payload.phone = phone;
        } else {
            payload = {
                ...payload,
                fio: currentParams.fullName,
                email,
                text,
            };

            if (cleanedPhone.length === PHONE_RU_CLEANED_LENGTH) payload.phone = cleanedPhone;
        }
        apiCreateFeedback(payload)
            .then((response) => {
                enqueueSnackbar(response.result, { variant: 'success' });
                setOpenModal(false);
            })
            .catch(({ message }) => enqueueSnackbar(message, { variant: 'error' }))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        const contactPhone = firstContact(currentParams.contactPhones, 'phone');
        if (phone.length === 0 && contactPhone !== null) setPhone(contactPhone);

        const contactEmail = firstContact(currentParams.contactEmails, 'email');
        if (email.length === 0 && contactEmail !== null) setEmail(contactEmail);
    }, [currentParams]); // eslint-disable-line react-hooks/exhaustive-deps

    // close chat on logout (return for unwatch)
    useEffect(() => clearSession.watch(() => window.carrotquest.removeChat()), [chatInstance]);

    const { action } = Messages[type];
    return (
        <div>
            <HelpButtonCard
                {...{ id, title }}
                onClick={() => (action === undefined ? setOpenModal(true) : action())}
                description={Messages[type].description}
                Icon={Messages[type].icon}
                loading={type === 'chat' && chatState === 'loading'}
                disabled={type === 'chat' && chatState !== 'success'}
                loadingText="Модуль чата загружается"
                disabledText="В данный момент модуль чата недоступен"
            />

            {action === undefined && (
                <Modal open={openModal} onClose={closeModal}>
                    <Fade in={openModal}>
                        <div className={classes.body}>
                            <Typography variant="h6" className={classes.title}>
                                {title}
                            </Typography>

                            <p className={classes.description}>{Messages[type].description}</p>

                            <div className={classes.form}>
                                <ReactInputMask
                                    mask={tel.reactInputMask}
                                    value={phone}
                                    onChange={(event) => setPhone(event.target.value)}
                                >
                                    <TextField label="Телефон" />
                                </ReactInputMask>

                                {!isCallRequest && (
                                    <>
                                        <TextField
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                            label="Email"
                                            type="email"
                                        />

                                        <textarea
                                            placeholder="Сообщение"
                                            onChange={(event) => setText(event.target.value)}
                                            value={text}
                                            className={clsx('MuiInputBase-input', classes.textarea)}
                                        />
                                    </>
                                )}
                            </div>

                            <div className={classes.buttonSubmit}>
                                <ButtonWithLoading
                                    type="submit"
                                    loading={loading}
                                    color="primary"
                                    onClick={createRequest}
                                    noMargin
                                >
                                    {Messages[type].sendButtonText || 'Отправить'}
                                </ButtonWithLoading>
                            </div>

                            <IconButton aria-label="Закрыть" className={classes.close} onClick={closeModal}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Fade>
                </Modal>
            )}
        </div>
    );
};
