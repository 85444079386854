import { useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (...args: any[]) => void;
type ParameterizedCallback<T extends Callback> = (...args: Parameters<T>) => void;

const DEFAULT_CLICK_TIMEOUT = 300;

export const useMultipleClick = <T extends Callback>(
    amount: number,
    callback: T,
    timeout = DEFAULT_CLICK_TIMEOUT
): ParameterizedCallback<T> => {
    const amountRef = useRef(0);
    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

    return (...args: Parameters<T>) => {
        amountRef.current += 1;

        if (amountRef.current === amount) {
            amountRef.current = 0;
            callback(...args);
            return;
        }

        if (timeoutIdRef.current !== null) {
            clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = setTimeout(() => {
            amountRef.current = 0;
        }, timeout);
    };
};
