import { createDomain } from 'effector';

import moment from 'moment';

import { apiContractBalance } from '../api';
import { Protocol } from '../api/protocol';

import { clearSession } from './system';

import { BalanceAppPayload, BalanceListItem } from '../types/balance';
import { BalancePayloadIso } from '../utils/payload';

type BalanceState = {
    date: BalanceAppPayload;
    list: BalanceListItem[];
};

export const BalanceDomain = createDomain();

export const clearBalance = BalanceDomain.createEvent();
export const fetchBalance = BalanceDomain.createEffect<
    Protocol.ContractBalanceRequest,
    Protocol.ContractBalanceResponse
>();

fetchBalance.use(apiContractBalance);

const initialState: BalanceState = {
    list: [],
    date: {
        from: moment().startOf('month').subtract(1, 'month'),
        to: moment().startOf('month'),
    },
};

export const BalanceStore = BalanceDomain.createStore(initialState)
    .on(fetchBalance.done, (
        state,
        {
            result,
            params: { // NOTE: можно достать линзой, если прям хочется
                yearFrom,
                monthFrom,
                yearTo,
                monthTo
            }
        }) => ({
            list: result,
            date: BalancePayloadIso.from({
                yearFrom,
                monthFrom,
                yearTo,
                monthTo
            }),
        })
    )
    .reset(clearBalance, clearSession);
