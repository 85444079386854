import React, { FunctionComponent, ReactNode } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AccountCircle, Equalizer, NotificationsActive, Settings, Help } from '@material-ui/icons';

import { useDesignFeatures } from '../hooks/designFeatures';
import { DesignFeatureName } from '../types/designFeature';

import { LOCATION_CAPTION, LOCATION_ROUTE } from '../utils/constants';
import { rollbackRouteToBase } from '../utils/routes';

type MenuItem = {
    location: string;
    caption: string;
    icon: ReactNode;
    designFeature: DesignFeatureName;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: 'white',
            borderRadius: '5px 5px 0 0',
            display: 'flex',
            borderBottom: '1px solid rgba(0,0,0,.12)',

            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    })
);

const useTabStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            color: '#808080',
            flexDirection: 'row',
            fontWeight: 600,

            '& > div': {
                display: 'flex',
                alignItems: 'center',

                // Icon
                '& > svg': {
                    marginRight: 4,
                },
            },
        },
        selected: {
            '& .MuiTab-wrapper': {
                color: theme.palette.primary.contrastText,
            },

            '& svg': {
                fill: theme.palette.primary.dark,
            },
        },
    })
);

export const Navigation: FunctionComponent = () => {
    const classes = useStyles();
    const tabClasses = useTabStyles();
    const location = useLocation();
    const history = useHistory();

    const { getDesignFeatureCaption, isDesignFeatureVisible } = useDesignFeatures();

    const handleChange = (event: unknown, newValue: string): void => {
        history.push(newValue);
    };

    const menuItems: MenuItem[] = [
        {
            location: LOCATION_ROUTE.DASHBOARD,
            caption: LOCATION_CAPTION.DASHBOARD,
            icon: <AccountCircle />,
            designFeature: 'main1',
        },
        {
            location: LOCATION_ROUTE.ADVANCED,
            caption: LOCATION_CAPTION.ADVANCED,
            icon: <Equalizer />,
            designFeature: 'main2',
        },
        {
            location: LOCATION_ROUTE.NEWS,
            caption: LOCATION_CAPTION.NEWS,
            icon: <NotificationsActive />,
            designFeature: 'main3',
        },
        {
            location: LOCATION_ROUTE.SETTINGS,
            caption: LOCATION_CAPTION.SETTINGS,
            icon: <Settings />,
            designFeature: 'main4',
        },
        {
            location: LOCATION_ROUTE.HELP,
            caption: LOCATION_CAPTION.HELP,
            icon: <Help />,
            designFeature: 'main5',
        },
    ];

    return (
        <nav className={classes.root}>
            <Tabs
                value={rollbackRouteToBase(location.pathname)}
                indicatorColor="primary"
                onChange={handleChange}
                aria-label="Навигация"
            >
                {menuItems
                    .filter(({ designFeature }) => isDesignFeatureVisible(designFeature))
                    .map((item) => (
                        <Tab
                            key={item.designFeature}
                            value={item.location}
                            classes={tabClasses}
                            label={
                                <div>
                                    {item.icon} {getDesignFeatureCaption(item.designFeature, item.caption)}
                                </div>
                            }
                        />
                    ))}
            </Tabs>
        </nav>
    );
};
