import { Moment } from 'moment';

export enum BalanceType {
    Info,
    Income,
    Expense,
}

export type PaymentRequest = {
    summa: number;
    customerAddress: string;
    redirectURL: string;
};

export type PaymentResponse = {
    result: string;
};

export const balancePaymentMethods = ['sberbank', 'payonline'] as const;

export type BalancePaymentMethod = typeof balancePaymentMethods[number];

export const balancePaymentMethod: Record<BalancePaymentMethod, BalancePaymentMethod> = {
    sberbank: 'sberbank',
    payonline: 'payonline',
};

export type PaymentSystem = BalancePaymentMethod;

export type BalanceApiPayload = {
    yearFrom: string;
    monthFrom: string;
    yearTo: string;
    monthTo: string;
};

export type BalanceAppPayload = {
    from: Moment;
    to: Moment;
};

export type BalanceListItem = {
    dt: DateTime.Timestamp;
    title: string;
    coment: string;
    sum: number;
    balance: number;
    type: BalanceType;
};
