import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Tooltip } from '@material-ui/core';

import { useStore } from 'effector-react';
import { CurrentDashboardStore } from '../effector/dashboard';

import { getStatusConfig } from '../utils/dashboardStatus';

const useStatusStyles = makeStyles((theme) =>
    createStyles({
        status: {
            display: 'flex',
            alignItems: 'center',
        },
        statusText: {
            marginLeft: theme.spacing(1),
        },
    })
);

type DashboardStatusProps = { className?: string };
export const DashboardStatus: FunctionComponent<DashboardStatusProps> = (props) => {
    const classes = useStatusStyles();
    const dashboard = useStore(CurrentDashboardStore);

    if (dashboard === null) return null;

    const statusConfig = getStatusConfig(dashboard.status);
    const IconComponent = statusConfig.component;
    const disableTooltip = statusConfig.tooltipText === null;

    return (
        <div {...props}>
            <Tooltip
                title={statusConfig.tooltipText || ''}
                disableTouchListener={disableTooltip}
                disableHoverListener={disableTooltip}
                disableFocusListener={disableTooltip}
            >
                <div className={classes.status}>
                    <IconComponent style={{ color: statusConfig.color }} />
                    <span className={classes.statusText}>{statusConfig.text}</span>
                </div>
            </Tooltip>
        </div>
    );
};
