import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        body: {
            boxSizing: 'border-box',
            borderRadius: 5,
            position: 'absolute',
            backgroundColor: 'white',
            boxShadow: theme.shadows[5],
            left: '50%',
            marginTop: 48,
            marginBottom: 48,
            maxWidth: '80%',
            outline: 'none',
            padding: 40,
            transform: 'translateX(-50%)',
            width: 416,
            overflowY: 'auto',
            maxHeight: 'calc(100% - 96px)',
            [theme.breakpoints.down('sm')]: {
                padding: 24,
                maxWidth: '90%',
            },
        },
        title: {
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1.35,
            textAlign: 'left',
            [theme.breakpoints.down('md')]: {
                paddingRight: 24,
            },
        },
        description: {
            color: theme.palette.secondary.light,
            fontSize: 16,
            lineHeight: 1.25,
            margin: '8px 0 16px',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 32,
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
            '& > *': {
                width: '50%',

                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },

                '&:not(:last-child)': {
                    marginRight: 16,
                    [theme.breakpoints.down('sm')]: {
                        marginRight: 0,
                        marginBottom: 12,
                    },
                },
            },
        },
        button: {
            height: 48,
            fontSize: 14,

            // when button with loading which has a wrapper
            '& > div': {
                height: '100%',
            },
        },
        buttonConfirm: {
            height: '100%',
            width: '100%',
        },
        close: {
            position: 'absolute',
            right: 4,
            top: 4,
        },
        date: {
            marginTop: 24,
        },
        textarea: {
            border: '1px solid #D0D0D0',
            borderRadius: 5,
            boxSizing: 'border-box',
            marginTop: 12,
            maxHeight: 480,
            maxWidth: '100%',
            minHeight: 120,
            padding: 16,
            resize: 'vertical',
            width: '100%',
        },
        buttonSubmit: {
            display: 'flex',
            marginTop: 24,
        },
        form: {
            display: 'grid',
            gridGap: 16,
            gridTemplateColumns: '1fr',
        },
    })
);
