import moment, { Moment } from 'moment';
import { Iso } from 'monocle-ts';

const DateTimeFormat = 'YYYY-MM-DD';

export const DateIso = new Iso<Date, Moment>(
    (date) => moment(date, DateTimeFormat),
    (instance) => new Date(instance.format(DateTimeFormat))
);

export const ApiDateIso = new Iso<DateTime.Date, Moment>(
    (date) => moment(date, DateTimeFormat),
    (instance) => instance.format(DateTimeFormat) as DateTime.Date
);

export const ApiTimestampIso = new Iso<DateTime.Timestamp, Moment>(
    (date) => moment.unix(date),
    (instance) => instance.unix() as DateTime.Timestamp
);
