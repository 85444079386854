import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useStore } from 'effector-react';
import { Moment } from 'moment';
import { CurrentContractStore } from '../effector/dashboard';
import { fetchPayonlineHistoryList, PayonlineHistoryStore } from '../effector/payonlineHistory';
import { PaymentHistoryContent } from './PaymentHistoryContent';

type Props = { open: boolean };
export const PayonlineHistory: FunctionComponent<Props> = ({ open }) => {
    const { date, items, pagination } = useStore(PayonlineHistoryStore);
    const loading = useStore(fetchPayonlineHistoryList.pending);
    const currentContract = useStore(CurrentContractStore);

    const [fetched, setFetched] = useState(items.length > 0);

    const fetch = useCallback((
            newDate?: Moment,
            customData = {},
        ) => {
        if (!currentContract) return;
        setFetched(true);
        fetchPayonlineHistoryList({
            year: newDate ? newDate.year() : date.year(),
            month: (newDate ? newDate.month() : date.month()) + 1,
            ...currentContract,
            ...pagination,
            ...customData,
        }).catch(() => setFetched(false));
    }, [currentContract, date]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (open && !fetched && !loading) fetch();
    }, [loading, fetched, open]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handle contract id change in header
    useEffect(() => {
        setFetched(false);
    }, [currentContract]);

    return (
        <PaymentHistoryContent
            loading={loading}
            fetched={fetched}
            date={date}
            items={items}
            pagination={pagination}
            fetch={fetch}
            hiddenColumns={['status']}
        />
    );
};
