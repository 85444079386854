import React, { FunctionComponent, useState } from 'react';
import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Modal, Button, Fade, IconButton, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { apiDetachContract } from '../api';
import { setContracts } from '../effector/system';
import { ContractEntity } from '../types/contract';
import { CurrentContractStore } from '../effector/dashboard';
import { ButtonWithLoading } from './ButtonWithLoading';
import { FeipTypograf } from '../utils/typograf';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        body: {
            boxSizing: 'border-box',
            borderRadius: 5,
            position: 'absolute',
            backgroundColor: 'white',
            boxShadow: theme.shadows[5],
            left: '50%',
            marginTop: 48,
            maxWidth: '80%',
            outline: 'none',
            padding: 40,
            transform: 'translateX(-50%)',
            width: 416,
            [theme.breakpoints.down('sm')]: {
                padding: 24,
                maxWidth: '90%',
            },
        },
        title: {
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1.35,
            textAlign: 'left',
            [theme.breakpoints.down('md')]: {
                paddingRight: 24,
            },
            '& .no-white-spaces': {
                whiteSpace: 'nowrap',
            },
        },
        buttons: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 32,
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },

            '& > *': {
                flexShrink: 0,
                width: '50%',

                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },

                '&:not(:last-child)': {
                    marginRight: 16,
                    [theme.breakpoints.down('sm')]: {
                        marginRight: 0,
                        marginBottom: 12,
                    },
                },
            },
        },
        button: {
            height: 48,
            fontSize: 16,
        },
        close: {
            position: 'absolute',
            right: 4,
            top: 4,
        },
    })
);

type Props = {
    open: boolean;
    setOpen: (state: boolean) => void;
    contractToDelete: ContractEntity;
};

const DetachContractModal: FunctionComponent<Props> = ({
    open,
    setOpen,
    contractToDelete,
}) => {
    const classes = useStyles();
    const currentContract = useStore(CurrentContractStore);
    const { enqueueSnackbar } = useSnackbar();
    const closeModal = (): void => {
        setOpen(false);
    };
    const [loading, setLoading] = useState(false);

    const handleDelete = (): void => {
        if (currentContract !== null) {
            const { id, billing } = contractToDelete;
            setLoading(true);
            apiDetachContract(currentContract, { contract: id, billing })
                .then((res) => {
                    enqueueSnackbar('Договор успешно отсоединен', {
                        variant: 'success',
                    });
                    setContracts(res.contracts);
                })
                .catch((e) => {
                    enqueueSnackbar(e.message, {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setLoading(false);
                    setOpen(false);
                });
        }
    };

    return (
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="Модальное окно выхода"
        >
            <Fade in={open}>
                <div className={classes.body}>
                    <Typography variant="h6" className={classes.title}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: FeipTypograf.execute(
                                    `Вы действительно хотите отвязать договор <span class="no-white-spaces">«${
                                        contractToDelete.title ??
                                        contractToDelete.id
                                    }»</span>?`
                                ),
                            }}
                        />
                    </Typography>
                    <div className={classes.buttons}>
                        <Button
                            onClick={closeModal}
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                        >
                            Отменить
                        </Button>
                        <ButtonWithLoading
                            onClick={handleDelete}
                            color="primary"
                            loading={loading}
                            style={{ width: '100%' }}
                            noMargin
                            className={classes.button}
                        >
                            Подтвердить
                        </ButtonWithLoading>
                    </div>

                    <IconButton
                        aria-label="Закрыть"
                        className={classes.close}
                        onClick={closeModal}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </Fade>
        </Modal>
    );
};

export { DetachContractModal };
