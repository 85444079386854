import { createDomain } from 'effector';

import { pipe } from 'fp-ts/es6/function';
import { Lens } from 'monocle-ts';

import { apiOutcomingDownloadHTML, apiOutcomingList } from '../api';
import { Protocol } from '../api/protocol';

import { clearSession } from './system';

import { OutcomingListItem } from '../types/callListItems';

import { sanitizePagination } from '../utils/api';

export type OutcomingListState = {
    list: OutcomingListItem[];
    pagination: Pagination;
};

export const OutcomingListDomain = createDomain();

export const clearOutcomingList = OutcomingListDomain.createEvent();
export const fetchOutcomingList = OutcomingListDomain.createEffect<
    Protocol.OutcomingRequest,
    Protocol.OutcomingResponse
>();

export const downloadOutcomingHTML = OutcomingListDomain.createEffect<
    Protocol.OutcomingDownloadHTMLRequest,
    Protocol.OutcomingDownloadHTMLResponse
>();

fetchOutcomingList.use(apiOutcomingList);
downloadOutcomingHTML.use(apiOutcomingDownloadHTML);

const initialState: OutcomingListState = {
    list: [],
    pagination: {
        pageNumber: 1,
        pageCount: 1,
        pageSize: 10,
        pageTotalRecordsCount: 0,
    },
};

const listLens = Lens.fromProp<OutcomingListState>()('list');
const paginationLens = Lens.fromProp<OutcomingListState>()('pagination');

export const OutcomingListStore = OutcomingListDomain.createStore(initialState)
    .on(fetchOutcomingList.done, (state, { result: { items, ...pagination } }) =>
        pipe(state, paginationLens.set(sanitizePagination(pagination)), listLens.set(items))
    )
    .reset(clearOutcomingList, clearSession);
