import React, { FC, useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { pipe } from 'fp-ts/es6/function';
import moment from 'moment';
import { useSnackbar } from 'notistack';

import { createStyles, makeStyles, TextField } from '@material-ui/core';

import { useStore } from 'effector-react';
import { LOCATION_CAPTION, LOCATION_ROUTE } from '../utils/constants';
import { pluralize } from '../utils/strings';

import { ApiError } from '../api/client/error';

import { useDebouncedCallback } from '../hooks/debouncedCallback';

import arrowBackSvgSrc from '../assets/icons/arrow-back.svg';

import { CameraData, CamerasStore, changeCameraName, fetchCameras, getTranslation } from '../effector/cameras';
import { CurrentContractStore, CurrentDashboardStore } from '../effector/dashboard';
import { buildUrl } from '../utils/routes';

import { withTitle } from '../containers/WithTitle';

import { CustomIconButton } from '../components/CustomIconButton';
import { List } from '../components/dashboard/List';
import { VideoPlayer } from '../components/VideoPlayer';
import { ButtonWithLoading } from '../components/ButtonWithLoading';

type ArchiveItem = {
    ID: number;
    date: string;
};

type ArchiveData = {
    periodDays: 7 | 14 | 30;
    items: ArchiveItem[];
};

const isLoadingMock = false;

// === Real data ===
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            boxShadow: '0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12)',
            borderRadius: '0 0 4px 4px',
            overflow: 'hidden',
            [theme.breakpoints.down('md')]: {
                paddingLeft: 0,
                paddingRight: 0,
                width: '100%',
            },
        },
        header: {
            display: 'flex',
            gap: '16px',
            alignItems: 'flex-start',
            padding: 32,
        },
        arrowBackButton: {
            borderRadius: 0,
            padding: 0,
            position: 'relative',
            top: '2px',
            '&:hover, &:focus, &:focus-visible, &:active': {
                backgroundColor: 'transparent',
            },
        },
        titleWrapper: {
            display: 'flex',
            gap: '16px',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                gap: '4px',
            },
        },
        title: {
            color: theme.palette.text.primary,
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1,
            margin: 0,
            flexShrink: 0,
            [theme.breakpoints.down('md')]: {
                fontSize: 20,
            },
        },
        ip: {
            color: '#707070',
            fontSize: 16,
            lineHeight: 1.3,
        },
        section: {
            padding: '0 32px 16px',
            '&:not(:last-child)': {
                borderBottom: '1px solid #EAEAEA',
            },
        },
        archiveSection: {
            paddingTop: 32,
            paddingBottom: 32,
        },
        sectionTitle: {
            margin: '0 0 16px',
        },
        sectionCaption: {
            color: '#707070',
        },
        cameraNameInput: {
            width: '100%',
            marginTop: 38,
        },
        saveButton: {
            padding: '12px 32px',
            margin: '24px 0',
        },
        saveButtonWrapper: {
            width: 'fit-content',
        },
    })
);

type CameraParams = { id: string };

const Camera: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const dashboard = useStore(CurrentDashboardStore);
    const currentContract = useStore(CurrentContractStore);
    const { list, translation } = useStore(CamerasStore);
    const isChangingName = useStore(changeCameraName.pending);

    const { id } = useParams<CameraParams>();
    const [currentCamera, setCurrentCamera] = useState<CameraData>();
    const [cameraName, setCameraName] = useState<string>();
    const [originalCameraName, setOriginalCameraName] = useState<string>();

    const backToDashboard = (): void => {
        history.push(LOCATION_ROUTE.DASHBOARD);
    };

    const saveNameDebounced = useDebouncedCallback(
        async () => {
            try {
                if (cameraName && currentContract && currentCamera) {
                    const data = await changeCameraName({
                        camera: currentCamera,
                        contract: currentContract,
                        name: cameraName,
                    });

                    if (data.Error) {
                        enqueueSnackbar(data.Error, {
                            variant: 'error',
                        });
                    } else {
                        enqueueSnackbar('Название камеры изменено', {
                            variant: 'success',
                        });
                        setOriginalCameraName(cameraName);
                    }
                }
            } catch (e) {
                if ((e as ApiError).message) {
                    enqueueSnackbar((e as ApiError).message, {
                        variant: 'error',
                    });
                }
            }
        },
        [cameraName],
        300
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (dashboard !== null && currentContract !== null) fetchCameras({ ...currentContract, ...dashboard });
    }, [dashboard, currentContract]);

    useEffect(() => {
        const camera = list.find((item) => item.ID === Number(id));

        if (camera !== undefined) {
            setCurrentCamera(camera);
            setCameraName(camera.Name);
            setOriginalCameraName(camera.Name);

            if (currentContract !== null) getTranslation({ camera, contract: currentContract });
        }
    }, [list, id, currentContract]);

    const goToArchiveView = (cameraId: string, daysAgo: number): void => {
        history.push(buildUrl(LOCATION_ROUTE.ARCHIVE, { id: cameraId, daysAgo }));
    };

    if (currentCamera === undefined) return null;

    const archiveDaysCount = currentCamera.Quota / (60 * 60 * 24);

    const archive = [];

    for (let i = 1; i <= archiveDaysCount; i += 1) {
        archive.push({
            ID: i,
            date: moment().subtract(i, 'days').format('D MMMM YYYY'),
        });
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <CustomIconButton
                    src={arrowBackSvgSrc}
                    aria-label="go-back"
                    disableRipple
                    imageOptions={{
                        width: 24,
                        height: 20,
                        color: '#A1A1A1',
                    }}
                    classes={{ root: classes.arrowBackButton }}
                    onClick={backToDashboard}
                />
                <div className={classes.titleWrapper}>
                    <h2 className={classes.title}>{currentCamera.Name}</h2>
                    <span className={classes.ip}>{currentCamera.IPOrDomain}</span>
                </div>
            </div>
            <section className={classes.section}>
                {translation !== null && <VideoPlayer isFLV source={translation.URL} />}

                <TextField
                    classes={{ root: classes.cameraNameInput }}
                    name="camera-name"
                    label="Название камеры"
                    variant="outlined"
                    defaultValue={cameraName}
                    onChange={(e) => setCameraName(e.target.value)}
                />

                <ButtonWithLoading
                    noMargin
                    loading={isChangingName}
                    className={classes.saveButton}
                    containerClass={classes.saveButtonWrapper}
                    color="primary"
                    disabled={cameraName === originalCameraName}
                    onClick={saveNameDebounced}
                >
                    Сохранить
                </ButtonWithLoading>
            </section>

            <section className={clsx(classes.section, classes.archiveSection)}>
                <h2 className={classes.sectionTitle}>
                    Архив за {archiveDaysCount} {pluralize(archiveDaysCount, ['день', 'дня', 'дней'])}
                </h2>
                <span className={classes.sectionCaption}>
                    У нас есть тарифы с архивом на 7, 14 и 30 дней. Напишите в поддержку если хотите изменить тариф
                </span>

                <List<ArchiveItem>
                    countOfSkeletons={3}
                    items={archive}
                    renderItemContent={(item) => <span>{item.date}</span>}
                    isLoading={isLoadingMock}
                    onItemClick={(item) => goToArchiveView(id, item.ID)}
                />
            </section>
        </div>
    );
};

const Component = pipe(Camera, withTitle(LOCATION_CAPTION.DASHBOARD));
export { Component as Camera };
