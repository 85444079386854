import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Fade, IconButton, Modal, Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Close as CloseIcon } from '@material-ui/icons';
import MomentUtils from '@date-io/moment';

import { useStore } from 'effector-react';
import { CurrentContractStore, CurrentParamsStore, updateBirthday } from '../../effector/dashboard';

import { foldApiTimestampToDate } from '../../utils/view';
import { ApiTimestampIso } from '../../utils/isomorph';

import { apiSetParamBirthday } from '../../api';
import { Protocol } from '../../api/protocol';

import { useStyles as useChangeParamStyles } from './styles';
import { useStyles } from '../modal/styles';

import { ButtonWithLoading } from '../ButtonWithLoading';
import { EditButton } from '../EditButton';

export const ChangeBirthdayParam: FunctionComponent = () => {
    const modalClasses = useStyles();
    const classes = useChangeParamStyles();

    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(ApiTimestampIso.from(moment()));

    const currentContract = useStore(CurrentContractStore);
    const currentParams = useStore(CurrentParamsStore);

    const { enqueueSnackbar } = useSnackbar();

    const closeModal = (): void => setModalOpen(false);

    const change = (): void => {
        if (!currentContract) return;

        setLoading(true);
        const payload: Protocol.SetParamBirthdayRequest = {
            ...currentContract,
            value: foldApiTimestampToDate(value),
        };

        apiSetParamBirthday(payload)
            .then((response) => {
                enqueueSnackbar(response.result, { variant: 'success' });
                updateBirthday(value);
                closeModal();
            })
            .catch(({ message }) => enqueueSnackbar(message, { variant: 'error' }))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (currentParams.birthday) setValue(currentParams.birthday);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <EditButton onClick={() => setModalOpen(true)} withTopGutter={false} />
            <Modal open={isModalOpen} onClose={closeModal}>
                <Fade in={isModalOpen}>
                    <div className={modalClasses.body}>
                        <Typography variant="h6" className={modalClasses.title}>
                            Изменить дату рождения
                        </Typography>

                        <div className={classes.form}>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
                                <DatePicker
                                    label="Год/Месяц"
                                    value={moment.unix(value).format()}
                                    onChange={(date: MaterialUiPickersDate) => {
                                        if (date !== null) setValue(ApiTimestampIso.from(date));
                                    }}
                                    format="DD.MM.yyyy"
                                    cancelLabel="Отмена"
                                    okLabel="Сохранить"
                                    maxDateMessage="Дата не должна превышать текущую дату"
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <div className={classes.button}>
                            <ButtonWithLoading
                                type="submit"
                                loading={loading}
                                color="primary"
                                onClick={change}
                                noMargin
                            >
                                Изменить
                            </ButtonWithLoading>
                        </div>

                        <IconButton aria-label="Закрыть" className={modalClasses.close} onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};
