export type AuthorizationHeader = { Authorization?: string };

enum AuthorizationType {
    Basic = 'Basic',
    Bearer = 'Bearer',
}

type Token = string | null;

export class Authorization {
    constructor(private token: Token, readonly use: boolean, readonly authType: AuthorizationType | null) {}

    getAuthHeaders(): AuthorizationHeader {
        const authHeaders: AuthorizationHeader = {};
        if (this.use && this.token !== null) {
            authHeaders.Authorization = `${this.authType} ${this.token}`;
        }
        return authHeaders;
    }

    setToken(token: Token): void {
        this.token = token;
    }
}

export const BearerAuth = (token: Token): Authorization => new Authorization(token, true, AuthorizationType.Bearer);
