import type { FC } from 'react';
import React from 'react';
import clsx from 'clsx';

import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import type { IconButtonProps } from '@material-ui/core/IconButton';
import type { ClassNameMap } from '@material-ui/styles';

type ImageOptions = {
    width: number | string;
    height: number | string;
    color: string;
};

type StylesClassName = 'imageWrapper';
type StylesProps = {
    src: string;
    imageOptions: ImageOptions;
};

const useStyles = makeStyles(() =>
    createStyles<StylesClassName, StylesProps>({
        imageWrapper: {
            width: ({ imageOptions }) => imageOptions.width,
            height: ({ imageOptions }) => imageOptions.height,
            backgroundColor: ({ imageOptions }) => imageOptions.color,
            mask: ({ src }) => `url(${src}) no-repeat center`,
            '-webkit-mask': ({ src }) => `url(${src}) no-repeat center`,
        },
    })
);

type ClassKey = 'root' | 'imageWrapper';

type CustomIconButtonProps = Omit<IconButtonProps, 'classes'> & {
    src: string;
    imageOptions: ImageOptions;
    classes?: Partial<ClassNameMap<ClassKey>>;
};

export const CustomIconButton: FC<CustomIconButtonProps> = (props) => {
    const { src, imageOptions, className, classes: externalClasses, ...restProps } = props;

    const classes = useStyles({ src, imageOptions });

    return (
        <IconButton className={clsx(className, externalClasses?.root)} {...restProps}>
            <div className={clsx(classes.imageWrapper, externalClasses?.imageWrapper)} />
        </IconButton>
    );
};
