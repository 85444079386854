import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/ru';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import {
    Button,
    FormControl,
    FormLabel,
    MenuItem,
    Slider,
    TablePagination,
    Select,
    Checkbox,
    InputLabel,
} from '@material-ui/core';
import { useStore } from 'effector-react';
import { CurrentContractStore } from '../effector/dashboard';
import { fetchPointList, PointListStore } from '../effector/pointList';
import { downloadIncomingHTML, fetchIncomingList, IncomingListStore } from '../effector/incomingList';
import { IncomingListItem } from '../types/callListItems';
import { cleanPhone, formatPhone } from '../utils/tel';
import { foldApiTimestampToFullDatetime } from '../utils/view';
import { AccordionLoader } from './AccordionLoader';
import { ButtonWithLoading } from './ButtonWithLoading';
import { downloadBlob } from '../utils/api';

type Column<T extends any, K extends keyof T = keyof T> = {
    id: K;
    label: string;
    align?: 'left' | 'right' | 'center';
    minWidth?: number;
    format?: (value: any) => string;
    whiteSpace?: 'nowrap' | 'normal';
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'block',
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
        filters: {
            marginBottom: 24,
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        filter: {
            '&:not(:last-child)': {
                marginRight: 16,
                [theme.breakpoints.down('sm')]: {
                    marginRight: 0,
                    marginBottom: 12,
                },
            },
        },
        downloadButton: {
            display: 'inline-flex',
        },
        formLabel: {
            marginBottom: 17,
        },
        dropdown: {
            width: 190,
        },
        sliderThumb: {
            zIndex: 1,
        },
        sliderActive: {
            zIndex: 2,
        },
        submitButton: {
            marginTop: 10,
        },
    })
);

const columns: Column<IncomingListItem>[] = [
    {
        id: 'dateFrom',
        label: 'Дата',
        format: foldApiTimestampToFullDatetime,
    },
    {
        id: 'fromNumber164',
        label: 'С номера',
        align: 'center',
        whiteSpace: 'nowrap',
        format: (value: string) => formatPhone(value),
    },
    {
        id: 'toNumber164',
        label: 'На номер',
        align: 'center',
        whiteSpace: 'nowrap',
        format: (value: string) => formatPhone(value),
    },
    {
        id: 'sessionTime',
        label: 'Длительность',
        align: 'center',
        format: (value: number) => `${value} сек.`,
    },
];

// FIXME: WTF - 476:21
type Props = { open: boolean };
export const IncomingList: FunctionComponent<Props> = ({ open }) => {
    const { list, pagination } = useStore(IncomingListStore);
    const currentContract = useStore(CurrentContractStore);
    const pointsList = useStore(PointListStore);

    const minDate = moment('2000-01-01');
    const maxDate = moment();

    const [isFetchedPointsList, setFetchedPointsList] = useState(pointsList.length > 0);
    const [loading, setLoading] = useState(true);
    const [fetched, setFetched] = useState(list.length > 0);
    const [date, setDate] = useState(maxDate.clone().startOf('month'));
    const [dayFrom, setDayFrom] = useState(maxDate.clone().startOf('month').date());
    const [dayTo, setDayTo] = useState(maxDate.clone().endOf('month').date());
    const [maxDayTo, setMaxDayTo] = useState(dayTo);
    const [page, setPage] = useState(1);
    const [chosenPoints, setChosenPoints] = useState<string[]>([]);
    const minDayFrom = 1;

    const getPointsPhones = useCallback((points: string[] = chosenPoints): string => points.map(cleanPhone).join(','), [
        chosenPoints,
    ]);

    const fetch = (fetchPage: number = page): void => {
        if (!currentContract) return;
        setPage(fetchPage);
        setFetched(true);
        fetchIncomingList({
            ...currentContract,
            ...pagination,
            phones: getPointsPhones(),
            year: moment(date).year(),
            month: moment(date).month() + 1,
            dayFrom,
            dayTo,
            pageNumber: fetchPage,
        })
            .catch(() => {
                setFetched(false);
                setLoading(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (open && currentContract && loading && !isFetchedPointsList) {
            setFetchedPointsList(true);
            fetchPointList(currentContract)
                .catch(() => {
                    setFetchedPointsList(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [loading, open, isFetchedPointsList, currentContract]);

    const classes = useStyles();
    const sliderMarks = [
        {
            value: minDayFrom,
            label: minDayFrom,
        },
        {
            value: maxDayTo,
            label: maxDayTo,
        },
    ];

    useEffect(() => {
        if (open && currentContract && !fetched && loading && pointsList.length > 0) {
            if (!currentContract) return;
            setFetched(true);
            let points = chosenPoints;
            if (chosenPoints.length === 0) {
                points = pointsList.map((item) => {
                    return item.number;
                });
                setChosenPoints(points);
            }
            fetchIncomingList({
                ...currentContract,
                ...pagination,
                phones: getPointsPhones(points),
                year: moment(date).year(),
                month: moment(date).month() + 1,
                dayFrom,
                dayTo,
            })
                .catch(() => {
                    setFetched(false);
                    setLoading(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [
        loading,
        fetched,
        open,
        pointsList,
        chosenPoints,
        currentContract,
        date,
        dayFrom,
        dayTo,
        getPointsPhones,
        pagination,
    ]);

    // Handle contract id change in header
    useEffect(() => {
        setFetchedPointsList(false);
        setFetched(false);
    }, [currentContract]);

    const handlePointsChange: SelectInputProps['onChange'] = useCallback((event) => {
        event.persist();
        setChosenPoints(event.target.value);
    }, []);

    const loadingHTML = useStore(downloadIncomingHTML.pending);
    const download = (): void => {
        if (!currentContract || loadingHTML) return;

        downloadIncomingHTML({
            ...currentContract,
            phones: getPointsPhones(),
            year: moment(date).year(),
            month: moment(date).month() + 1,
            dayFrom,
            dayTo,
        })
            .then((blob) =>
                downloadBlob(blob, `Входящие звонки с ${dayFrom} по ${dayTo} за ${date.format('MMMM YYYY')}.html`)
            )
            .catch((e) => console.log(e));
    };

    if (loading) return <AccordionLoader />;

    return (
        <div className={classes.root}>
            <div className={classes.filters}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
                    {pointsList.length > 0 && (
                        <FormControl className={classes.filter} variant="outlined">
                            <InputLabel htmlFor="incoming-phones-select">Номера телефонов</InputLabel>
                            <Select
                                className={classes.dropdown}
                                id="incoming-phones-select"
                                label="Номера телефонов"
                                multiple
                                value={chosenPoints}
                                onChange={handlePointsChange}
                                renderValue={(selected) => (selected as string[]).join(', ')}
                                MenuProps={{
                                    disableAutoFocusItem: true,
                                    autoFocus: false,
                                    variant: 'menu',
                                    MenuListProps: {
                                        autoFocus: false,
                                    },
                                }}
                            >
                                {pointsList.map((item, index) => (
                                    <MenuItem value={item.number} key={`dropdown-item-${item.number}-${index}`}>
                                        <Checkbox checked={chosenPoints.indexOf(item.number) > -1} />
                                        {item.number}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <FormControl className={classes.filter}>
                        <DatePicker
                            views={['month', 'year']}
                            label="Год/Месяц"
                            minDate={minDate}
                            maxDate={maxDate}
                            value={date}
                            onChange={(newDate: MaterialUiPickersDate) => {
                                setDate(moment(newDate));
                                setMaxDayTo(moment(newDate).clone().endOf('month').date());
                            }}
                            format="yyyy-MM"
                            cancelLabel="Отмена"
                            okLabel="Сохранить"
                            minDateMessage="Дата не должна быть раньше 2000 года"
                            maxDateMessage="Дата не должна превышать текущую дату"
                            disableToolbar
                            disableFuture
                        />
                    </FormControl>

                    <FormControl className={classes.filter}>
                        <FormLabel
                            id="incoming-days-slider-label"
                            className={`MuiInputLabel-shrink ${classes.formLabel}`}
                        >
                            Выберите дни
                        </FormLabel>

                        <Slider
                            value={[dayFrom, dayTo]}
                            min={1}
                            step={1}
                            classes={{
                                active: classes.sliderActive,
                                thumb: classes.sliderThumb,
                            }}
                            max={maxDayTo}
                            marks={sliderMarks}
                            onChange={(event, values) => {
                                if (Array.isArray(values) && values.length === 2) {
                                    setDayFrom(values[0]);
                                    setDayTo(values[1]);
                                }
                            }}
                            valueLabelDisplay="auto"
                            aria-labelledby="incoming-days-slider-label"
                        />
                    </FormControl>

                    <FormControl className={classes.filter}>
                        <Button
                            variant="contained"
                            className={classes.submitButton}
                            color="primary"
                            onClick={() => {
                                if (!currentContract) return;
                                setLoading(true);
                                fetchIncomingList({
                                    ...currentContract,
                                    ...pagination,
                                    phones: getPointsPhones(),
                                    year: moment(date).year(),
                                    month: moment(date).month() + 1,
                                    dayFrom,
                                    dayTo,
                                })
                                    .catch(() => {
                                        setFetched(false);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        >
                            Применить
                        </Button>
                    </FormControl>
                </MuiPickersUtilsProvider>
                <ButtonWithLoading
                    color="primary"
                    loading={loadingHTML}
                    containerClass={classes.downloadButton}
                    onClick={download}
                >
                    Скачать
                </ButtonWithLoading>
            </div>

            {list.length > 0 ? (
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={`row-${row.fromNumber164}-${row.dateFrom}`}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        whiteSpace: column.whiteSpace ?? 'normal',
                                                    }}
                                                >
                                                    {column.format ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <AccordionLoader text="Не найдено данных для отображения" />
            )}

            {pagination.pageCount > 0 && list.length > 0 && (
                <TablePagination
                    component="div"
                    rowsPerPage={pagination.pageSize}
                    count={pagination.pageTotalRecordsCount}
                    page={pagination.pageNumber - 1}
                    onChangePage={(event, value) => {
                        if (!currentContract) return;
                        fetch(value === 0 ? 1 : value + 1);
                    }}
                    onChangeRowsPerPage={(event) => {
                        if (!currentContract) return;
                        setLoading(true);
                        fetchIncomingList({
                            ...currentContract,
                            ...pagination,
                            phones: getPointsPhones(),
                            year: moment(date).year(),
                            month: moment(date).month() + 1,
                            dayFrom,
                            dayTo,
                            pageSize: Number(event.target.value),
                        })
                            .catch(() => setFetched(false))
                            .finally(() => {
                                setLoading(false);
                            });
                    }}
                />
            )}
        </div>
    );
};
