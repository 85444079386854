import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useStore } from 'effector-react';

import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';

import { FormControl, TablePagination, createStyles, makeStyles } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { CurrentContractStore } from '../effector/dashboard';
import { fetchLimitHistory, CurrentLimitsList, LimitsStore } from '../effector/limits';

import { formatApiTimestamp, formatPrice } from '../utils/view';
import { isString } from '../utils/guards';

import { ContractLimitItem } from '../types/limit';

import { Table, TableColumns } from '../containers/Table';
import { AccordionLoader } from './AccordionLoader';

// ----- Styles -----
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'block',
            width: '100%',
        },
        container: {
            maxHeight: 440,
        },
        filters: {
            margin: theme.spacing(2),
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        filter: {
            '&:not(:last-child)': {
                marginRight: 16,
                [theme.breakpoints.down('sm')]: {
                    marginRight: 0,
                    marginBottom: 12,
                },
            },
        },
    })
);

const foldApiTimestamp = formatApiTimestamp('HH:mm DD.MM.YYYY');

// ----- Table config -----
const columns: TableColumns<ContractLimitItem> = [
    {
        id: 'dtFrom',
        label: 'Активен с',
        format: foldApiTimestamp,
        isNoWrapHeader: true,
    },
    {
        id: 'dtTo',
        label: 'Заканчивается',
        isNoWrapHeader: true,
        format: (value: DateTime.Timestamp | null) => (value ? foldApiTimestamp(value) : '—'),
    },
    {
        id: 'status',
        label: 'Статус',
        format: (value) => (isString(value) ? value.replace(/^./, (char) => char.toUpperCase()) : value),
    },
    {
        id: 'sum',
        label: 'Сумма, ₽',
        align: 'right',
        format: (value) => formatPrice(value, 2, ' '),
    },
];

// ----- Component -----
type ContractLimitsProps = { open: boolean };
export const ContractLimits: FunctionComponent<ContractLimitsProps> = ({ open }) => {
    // Store stuff
    const loading = useStore(fetchLimitHistory.pending);
    const currentList = useStore(CurrentLimitsList);
    const currentContract = useStore(CurrentContractStore);
    const { pagination, date } = useStore(LimitsStore);

    const [fetched, setFetched] = useState(currentList.length > 0);
    const classes = useStyles();

    const fetch = useCallback(
        (newDate?: Moment): void => {
            if (!currentContract) return;
            fetchLimitHistory({
                year: newDate ? newDate.year() : date.year(),
                month: (newDate ? newDate.month() : date.month()) + 1,
                ...currentContract,
                ...pagination,
            }).catch(() => setFetched(false));
        },
        [currentContract, pagination, date]
    );

    // Handle contract id change in header
    useEffect(() => {
        setFetched(false);
    }, [currentContract]);

    useEffect(() => {
        if (open && currentContract && !fetched && !loading) {
            setFetched(true);
            fetch();
        }
    }, [loading, fetched, open, currentContract, fetch]);

    if (loading) return <AccordionLoader />;

    return (
        <div className={classes.root}>
            {/* ----- Filters ----- */}
            <div className={classes.filters}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
                    <FormControl className={classes.filter}>
                        <DatePicker
                            value={date}
                            label="Дата"
                            views={['month', 'year']}
                            maxDate={moment().endOf('day')}
                            onChange={(newDate: MaterialUiPickersDate) => {
                                if (!currentContract || !newDate) return;
                                fetch(newDate);
                            }}
                            cancelLabel="Отмена"
                            okLabel="Сохранить"
                            minDateMessage="Дата не должна быть раньше 2000 года"
                            maxDateMessage="Дата не должна превышать текущую дату"
                            disableFuture
                        />
                    </FormControl>
                </MuiPickersUtilsProvider>
            </div>

            {currentList.length === 0 ? (
                <AccordionLoader text="Не найдено данных за выбранный период" />
            ) : (
                <>
                    {/* Table */}
                    <Table columns={columns} data={currentList} keyProp={['dtFrom', 'dtTo']} />

                    {/* Pagination */}
                    <TablePagination
                        component="div"
                        rowsPerPage={pagination.pageSize}
                        count={pagination.pageTotalRecordsCount}
                        page={pagination.pageNumber - 1}
                        labelRowsPerPage="Записей на странице"
                        onChangePage={(event, value) => {
                            if (!currentContract) return;
                            fetchLimitHistory({
                                year: date.year(),
                                month: date.month() + 1,
                                ...pagination,
                                ...currentContract,
                                pageNumber: value + 1,
                            }).catch(() => setFetched(false));
                        }}
                        onChangeRowsPerPage={(event) => {
                            if (!currentContract) return;
                            fetchLimitHistory({
                                year: date.year(),
                                month: date.month() + 1,
                                ...pagination,
                                ...currentContract,
                                pageSize: Number(event.target.value),
                            }).catch(() => setFetched(false));
                        }}
                    />
                </>
            )}
        </div>
    );
};
