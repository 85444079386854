import Typograf from 'typograf';

class FeipTypografClass {
    public instance: typograf.Typograf;

    constructor(locale: Array<string> = ['ru', 'en-US']) {
        this.instance = new Typograf({ locale });
    }
}

export const FeipTypograf = (new FeipTypografClass()).instance;
