import React, { FunctionComponent, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Tooltip,
    AccordionDetails,
    Accordion,
    Button,
    AccordionSummary,
} from '@material-ui/core';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    InfoOutlined as InfoOutlinedIcon,
    ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import { TariffDescriptionRecord, TariffForChangeItem } from '../types/tariff';
import { FeipTypograf } from '../utils/typograf';
import { sliceItems } from '../utils/arrays';
import { ButtonWithLoading } from './ButtonWithLoading';

// ----- Styles -----
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontSize: 20,
            fontWeight: 500,
            margin: 0,
        },
        outerWrapper: {
            display: 'flex',
            gap: '24px',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        listWrapper: {
            marginTop: 20,
        },
        list: {
            display: 'inline-flex',
            flexDirection: 'column',
            margin: 0,
            padding: 0,
            listStyle: 'none',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        listItemWrapper: {
            '&:not(:last-child)': {
                marginBottom: 8,
                [theme.breakpoints.down('sm')]: {
                    marginBottom: 0,
                },
            },
            '&:not(:first-child)': {
                [theme.breakpoints.down('sm')]: {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    marginTop: 16,
                    paddingTop: 8,
                },
            },
        },
        listItemWrapperVertical: {
            '&:not(:last-child)': {
                marginBottom: 0,
            },
            '&:not(:first-child)': {
                borderTop: `1px solid ${theme.palette.divider}`,
                marginTop: 24,
                paddingTop: 16,
                [theme.breakpoints.down('sm')]: {
                    marginTop: 16,
                    paddingTop: 8,
                },
            },
        },
        listItem: {
            alignItems: 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        listItemVertical: {
            flexDirection: 'column',
        },
        listItemWithIcon: {
            '&:hover, &:focus': {
                '& $listItemName': {
                    color: theme.palette.primary.main,
                },
            },
        },
        listItemValue: {
            fontSize: 16,
            marginLeft: 24,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginTop: 4,
            },
        },
        listItemValueVertical: {
            marginLeft: 0,
            marginTop: 8,
            [theme.breakpoints.down('sm')]: {
                marginTop: 4,
            },
        },
        listItemName: {
            alignItems: 'center',
            color: theme.palette.text.secondary,
            display: 'inline-flex',
            fontSize: 16,
            lineHeight: 1.35,
            minWidth: 145,
            transition: 'color 0.2s ease-out',
            [theme.breakpoints.down('sm')]: {
                minWidth: 'auto',
            },
        },
        listItemNameVertical: {
            minWidth: 'auto',
        },
        listItemNameIcon: {
            marginLeft: 2,
            transform: 'scale(0.8)',
        },
        buttons: {
            [theme.breakpoints.down('sm')]: {
                marginTop: 24,
            },
        },
        buttonPrimary: {
            height: 40,
            width: '100%',
        },
        buttonSecondary: {
            height: 40,
            marginTop: 12,
            backgroundColor: '#f5f5f5',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        accordionRoot: {
            boxShadow: 'none',
            '&::before': {
                display: 'none',
            },
        },
        accordionExpanded: {
            marginTop: 24,
        },
        accordionSummaryRoot: {
            display: 'none',
        },
        accordionDetailsRoot: {
            borderTop: `1px solid ${theme.palette.divider}`,
            padding: 0,
            paddingTop: 16,
        },
    })
);

type TariffDescriptionListProps = {
    items: TariffDescriptionRecord[];
    vertical?: boolean;
};
export const TariffDescriptionList: FunctionComponent<TariffDescriptionListProps> = (props) => {
    const { items, vertical = false } = props;

    const classes = useStyles();

    const renderDescriptionItem = (
        item: TariffDescriptionRecord,
        isVertical = false,
        withIcon = false
    ): JSX.Element => (
        <div
            className={clsx(
                classes.listItem,
                isVertical && classes.listItemVertical,
                withIcon && classes.listItemWithIcon
            )}
        >
            <span className={clsx(classes.listItemName, isVertical && classes.listItemNameVertical)}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: FeipTypograf.execute(item.name),
                    }}
                />
                {withIcon && <InfoOutlinedIcon className={classes.listItemNameIcon} fontSize="small" />}
            </span>
            <span
                className={clsx(classes.listItemValue, isVertical && classes.listItemValueVertical)}
                dangerouslySetInnerHTML={{
                    __html: FeipTypograf.execute(item.value),
                }}
            />
        </div>
    );

    return (
        <ul className={classes.list}>
            {items.map((item, index) => (
                <li
                    className={clsx(classes.listItemWrapper, vertical && classes.listItemWrapperVertical)}
                    key={`${item.name}-${index}`}
                >
                    {item.hint ? (
                        <Tooltip title={item.hint}>{renderDescriptionItem(item, vertical, true)}</Tooltip>
                    ) : (
                        renderDescriptionItem(item, vertical)
                    )}
                </li>
            ))}
        </ul>
    );
};

type Props = { data: TariffForChangeItem; onClick: () => void; isDisabled: boolean; isLoading: boolean };
export const TariffCard: FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const { data, onClick, isDisabled, isLoading } = props;

    const [isAccordionExpanded, setAccordionExpanded] = useState(false);

    const descriptionsAmount = 3;
    const visibleDescriptions = sliceItems<TariffDescriptionRecord>(data.description, 0, descriptionsAmount);
    const hiddenDescriptions = sliceItems<TariffDescriptionRecord>(data.description, descriptionsAmount);

    return (
        <Card variant="outlined" className={classes.root}>
            <CardContent>
                <div className={classes.outerWrapper}>
                    <div>
                        <Typography
                            variant="h5"
                            component="h2"
                            classes={{ root: classes.title }}
                            dangerouslySetInnerHTML={{
                                __html: FeipTypograf.execute(data.title),
                            }}
                        />

                        <div className={classes.listWrapper}>
                            <TariffDescriptionList items={visibleDescriptions} />
                        </div>
                    </div>

                    <div className={classes.buttons}>
                        <ButtonWithLoading
                            color="primary"
                            loading={isLoading}
                            disabled={isDisabled}
                            className={classes.buttonPrimary}
                            onClick={onClick}
                            noMargin
                        >
                            Подключить
                        </ButtonWithLoading>

                        {hiddenDescriptions.length > 0 && (
                            <Button
                                color="default"
                                variant="contained"
                                endIcon={isAccordionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                onClick={() => setAccordionExpanded(!isAccordionExpanded)}
                                className={classes.buttonSecondary}
                            >
                                Подробнее
                            </Button>
                        )}
                    </div>
                </div>

                {hiddenDescriptions.length > 0 && (
                    <Accordion
                        expanded={isAccordionExpanded}
                        classes={{
                            root: classes.accordionRoot,
                            expanded: classes.accordionExpanded,
                        }}
                    >
                        {/* without summary accordion is not togglable */}
                        <AccordionSummary classes={{ root: classes.accordionSummaryRoot }} />
                        <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                            <TariffDescriptionList items={hiddenDescriptions} vertical />
                        </AccordionDetails>
                    </Accordion>
                )}
            </CardContent>
        </Card>
    );
};
