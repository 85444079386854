/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from 'react';

type Callback = (...args: any[]) => void;
type DebouncedCallback<T extends Callback> = (...args: Parameters<T>) => void;

function debounce<T extends Callback>(callback: T, delay: number): DebouncedCallback<T> {
    let timeout: number | undefined;

    return (...args: Parameters<T>) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = undefined;
        }

        // eslint-disable-next-line no-restricted-globals
        timeout = self.setTimeout(() => {
            callback(...args);
            timeout = undefined;
        }, delay);
    };
}

export function useDebouncedCallback<T extends Callback>(
    callback: T,
    deps: any[],
    delay: number
): DebouncedCallback<T> {
    const callbackMemo = useCallback(callback, deps);

    return useMemo(() => debounce(callbackMemo, delay), [callbackMemo, delay]);
}
