import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useSnackbar } from 'notistack';

import {
    Card,
    CardContent,
    Modal,
    CardHeader,
    FormControl,
    InputAdornment,
    IconButton,
    TextField,
} from '@material-ui/core';
import {
    Close as CloseIcon,
    Visibility,
    VisibilityOff,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';

import { useStore } from 'effector-react';
import { setContracts } from '../effector/system';
import { CurrentContractStore } from '../effector/dashboard';

import { ButtonWithLoading } from './ButtonWithLoading';

import { apiAttachContract } from '../api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            width: '100%',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
        },
        card: {
            boxSizing: 'border-box',
            marginLeft: 'auto',
            marginRight: 'auto',
            outline: 'none',
            position: 'relative',
            width: 416,
            maxWidth: '80%',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '90%',
            },
        },
        header: {
            padding: '40px 40px 0',
            [theme.breakpoints.down('sm')]: {
                padding: '24px 24px 0',
            },
        },
        headerTitle: {
            color: theme.palette.primary.contrastText,
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1.15,
            [theme.breakpoints.down('md')]: {
                paddingRight: 24,
            },
        },
        content: {
            padding: '32px 40px 40px',
            [theme.breakpoints.down('sm')]: {
                padding: '20px 24px 24px',
            },
        },
        input: {
            borderRadius: 5,
        },
        textField: {
            width: '100%',
            '& .MuiFormLabel-root': {
                color: theme.palette.text.secondary,
            },
            '& .MuiInputBase-root': {
                backgroundColor: '#F5F5F5',
            },
            '& label.Mui-focused': {
                color: theme.palette.text.secondary,
            },
        },
        loginButton: {
            display: 'block',
            height: 48,
            fontSize: 16,
            marginTop: 32,
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: 24,
            },
        },
        close: {
            position: 'absolute',
            right: 4,
            top: 4,
        },
    })
);

type FormInputs = {
    login: string;
    password: string;
};

type Props = {
    isModalVisible: boolean;
    setModalVisible: (state: boolean) => void;
};

export const AttachContractModal: FunctionComponent<Props> = ({
    isModalVisible,
    setModalVisible,
}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit } = useForm<FormInputs>();

    const currentContract = useStore(CurrentContractStore);

    const [loading, setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const onSubmit = (data: FormInputs): void => {
        if (currentContract) {
            setLoading(true);
            apiAttachContract({
                ...currentContract,
                ...data,
            })
                .then((res) => {
                    enqueueSnackbar('Договор успешно присоединен', {
                        variant: 'success',
                    });
                    setContracts(res.contracts);
                })
                .catch((e) => {
                    enqueueSnackbar(e.message, {
                        variant: 'error',
                    });
                })
                .finally(() => {
                    setLoading(false);
                    setModalVisible(false);
                });
        }
    };

    return (
        <Modal
            open={isModalVisible}
            onClose={() => {
                setModalVisible(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableEnforceFocus
            disableAutoFocus
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Card className={classes.card}>
                <CardHeader
                    title="Присоединить договор"
                    classes={{
                        root: classes.header,
                        title: classes.headerTitle,
                    }}
                />
                <CardContent className={classes.content}>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormControl
                            size="small"
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <TextField
                                classes={{ root: classes.textField }}
                                autoComplete="off"
                                inputRef={register}
                                type="text"
                                name="login"
                                id="attach-login"
                                variant="filled"
                                InputProps={{
                                    classes: {
                                        root: classes.input,
                                    },
                                    disableUnderline: true,
                                }}
                                label="Логин"
                            />
                        </FormControl>
                        <FormControl
                            size="small"
                            variant="outlined"
                            className={classes.formControl}
                            style={{ marginTop: 16 }}
                        >
                            <TextField
                                classes={{ root: classes.textField }}
                                autoComplete="off"
                                type={passwordVisible ? 'text' : 'password'}
                                inputRef={register}
                                name="password"
                                id="attach-password"
                                label="Пароль"
                                variant="filled"
                                InputProps={{
                                    classes: {
                                        root: classes.input,
                                    },
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Показать или скрыть пароль"
                                                onClick={() =>
                                                    setPasswordVisible(
                                                        !passwordVisible
                                                    )
                                                }
                                            >
                                                {passwordVisible ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                        <div>
                            <ButtonWithLoading
                                loading={loading}
                                color="primary"
                                className={classes.loginButton}
                                type="submit"
                                noMargin
                            >
                                Присоединить
                            </ButtonWithLoading>
                        </div>
                    </form>
                </CardContent>

                <IconButton
                    aria-label="Закрыть"
                    className={classes.close}
                    onClick={() => {
                        setModalVisible(false);
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Card>
        </Modal>
    );
};
