type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const BREAKPOINTS: Record<ScreenSize, number> = {
    xs: 375,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
};

const mediaQuery = (query: string): boolean => window.matchMedia(query).matches;

export const isScreenSizeDown = (size: ScreenSize): boolean => mediaQuery(`(max-width: ${BREAKPOINTS[size] + 1}px)`);
