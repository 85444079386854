import React, { FunctionComponent } from 'react';
import { SnackbarProvider } from 'notistack';

import { flow } from 'fp-ts/es6/function';

import { ruRU } from '@material-ui/core/locale';
import { createMuiTheme, responsiveFontSizes, ThemeProvider, SnackbarOrigin } from '@material-ui/core';

import { useStore } from 'effector-react';
import { SystemStore } from '../effector/system';
import { FirebaseProvider } from '../providers/FirebaseProvider';

import { App } from '../App';

const themeBuilder = flow(createMuiTheme, responsiveFontSizes);
const theme = themeBuilder(
    {
        palette: {
            primary: {
                dark: '#DF9502',
                main: '#FAB000',
                light: '#fffe50',
                contrastText: '#101010',
            },
            secondary: {
                dark: '#000000',
                main: '#1b1918',
                light: '#423f3e',
                contrastText: '#fff',
            },
            text: {
                primary: '#202020',
                secondary: '#808080',
            },
        },
        breakpoints: {
            values: {
                xs: 375,
                sm: 425,
                md: 640,
                lg: 1000,
                xl: 1200,
            },
        },
        typography: {
            fontFamily: ['IBM Plex Sans', 'sans-serif'].join(','),
            button: {
                textTransform: 'none',
                fontWeight: 500,
            },
        },
        overrides: {
            MuiButton: {
                root: {
                    height: 40,
                },
                contained: {
                    boxShadow: 'none',
                    height: 40,
                    '&:hover, &:focus, &:active': {
                        boxShadow: 'none',
                    },
                },
                outlined: {
                    height: 40,
                    '&:hover, &:focus, &:active': {
                        boxShadow: 'none',
                    },
                },
                containedPrimary: {
                    borderRadius: 5,
                },
                containedSecondary: {
                    borderRadius: 5,
                },
                outlinedSecondary: {
                    border: '1px solid #D0D0D0',
                    borderRadius: 5,
                    color: '#101010',
                },
            },
        },
    },
    ruRU
);

export const Root: FunctionComponent = () => {
    const snackbarAnchorOptions: SnackbarOrigin = {
        vertical: 'top',
        horizontal: 'right',
    };

    const { loggedIn } = useStore(SystemStore)

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider anchorOrigin={snackbarAnchorOptions}>
                <FirebaseProvider loggedIn={loggedIn}>
                    <App />
                </FirebaseProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
};
