import { createDomain } from 'effector';
import { apiContractPointList } from '../api';
import { Protocol } from '../api/protocol';
import { clearSession } from './system';
import { PointListItem } from '../types/pointList';

type PointListState = PointListItem[];

export const PointListDomain = createDomain();

export const clearPointList = PointListDomain.createEvent();
export const fetchPointList = PointListDomain.createEffect<
    Protocol.ContractPointRequest,
    Protocol.ContractPointResponse
>();

fetchPointList.use(apiContractPointList);

const initialState: PointListState = [];

export const PointListStore = PointListDomain.createStore(initialState)
    .on(fetchPointList.doneData, (state, payload) => payload)
    .reset(clearPointList, clearSession);
