import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            display: 'grid',
            gridGap: 16,
            gridTemplateColumns: '1fr',
            marginTop: 12,
            marginBottom: 32,
        },
        formLargeGutter: {
            marginTop: 24,
        },
        button: {
            display: 'inline-flex',
        },
        contactItem: {
            alignItems: 'flex-start',
            display: 'flex',
            '&:not(:last-child)': {
                borderBottom: `1px solid #cfcfcf`,
                paddingBottom: 8,
            },
        },
        contactWrapper: {
            flexGrow: 1,
        },
        contactValue: {
            color: theme.palette.text.primary,
            margin: 0,
        },
        contactComment: {
            color: theme.palette.text.secondary,
            fontSize: 14,
            margin: 0,
            marginTop: 8,
        },
        contactButtons: {
            display: 'inline-grid',
            gridTemplateColumns: 'repeat(2, auto)',
            gridGap: 8,
        },
        contactTextField: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            boxSizing: 'border-box',
            height: 32,
        },
    })
);
