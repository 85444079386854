import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import MomentUtils from '@date-io/moment';
import moment from 'moment';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useStore } from 'effector-react';
import { fetchBalance, BalanceStore } from '../effector/balance';
import { CurrentContractStore } from '../effector/dashboard';

import { foldViewState, formatPrice, foldApiTimestampToDate } from '../utils/view';
import { BalancePayloadIso } from '../utils/payload';
import { FeipTypograf } from '../utils/typograf';

import { BalanceListItem, BalanceType } from '../types/balance';

import { Table, TableColumns } from '../containers/Table';

import { AccordionLoader } from './AccordionLoader';

const balanceTypeMap: Record<BalanceType, string> = {
    [BalanceType.Info]: 'Справочно',
    [BalanceType.Income]: 'Приход',
    [BalanceType.Expense]: 'Расход',
};

const columns: TableColumns<BalanceListItem> = [
    {
        id: 'dt',
        label: 'Дата',
        format: (value: DateTime.Timestamp) => foldApiTimestampToDate(value),
        minWidth: 84,
    },
    {
        id: 'sum',
        align: 'right',
        label: 'Сумма, ₽',
        format: (value) => formatPrice(value, 2, ' '),
        isNoWrap: true,
        tooltip: {
            valueColumnID: 'type',
            format: (value: BalanceType) => balanceTypeMap[value],
        },
    },
    {
        id: 'balance',
        align: 'right',
        label: 'Остаток, ₽',
        format: (value) => formatPrice(value, 2, ' '),
        minWidth: 100,
        isNoWrap: true,
    },
    {
        id: 'title',
        label: 'Операция',
        format: (value) => FeipTypograf.execute(value),
    },
    {
        id: 'coment',
        label: 'Дополнительно',
        format: (value: string | null) => FeipTypograf.execute(value || '—'),
    },
];

const useStyles = makeStyles(() =>
    createStyles({
        datePicker: {
            '&:not(:last-child)': {
                marginRight: 8,
            },
        },
    })
);

type Props = { open: boolean };
export const Balance: FunctionComponent<Props> = ({ open }) => {
    const classes = useStyles();

    const state = useStore(BalanceStore);
    const loading = useStore(fetchBalance.pending);
    const currentContract = useStore(CurrentContractStore);

    const [dateTo, setDateTo] = useState(state.date.to);
    const [dateFrom, setDateFrom] = useState(state.date.from);
    const [fetched, setFetched] = useState(state.list.length > 0);

    const fetchCallback = useCallback(() => {
        setFetched(true);
        if (currentContract) {
            const dates = BalancePayloadIso.to({ from: dateFrom, to: dateTo });
            fetchBalance({ ...currentContract, ...dates }).catch(() => setFetched(false));
        }
    }, [currentContract, dateFrom, dateTo]);

    useEffect(() => {
        if (open && !fetched && !loading) fetchCallback();
    }, [loading, fetched, open]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (open && fetched && !loading) fetchCallback();
    }, [dateFrom, dateTo]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handle contract id change in header
    useEffect(() => {
        setFetched(false);
    }, [currentContract]);

    const viewState = foldViewState(state.list, loading, null);

    return (
        <Grid container direction="column">
            <Grid container alignItems="center" style={{ margin: '1em' }} spacing={1}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
                    <DatePicker
                        className={classes.datePicker}
                        value={dateFrom}
                        label="Начало периода"
                        views={['month', 'year']}
                        maxDate={moment().endOf('day')}
                        onChange={(newDate: MaterialUiPickersDate) => {
                            if (!newDate) return;
                            setDateFrom(newDate);
                        }}
                        cancelLabel="Отмена"
                        okLabel="Сохранить"
                        minDateMessage="Дата не должна быть раньше 2000 года"
                        maxDateMessage="Дата не должна превышать текущую дату"
                        disableFuture
                    />
                    <DatePicker
                        className={classes.datePicker}
                        value={dateTo}
                        label="Конец периода"
                        views={['month', 'year']}
                        maxDate={moment().endOf('day')}
                        onChange={(newDate: MaterialUiPickersDate) => {
                            if (!newDate) return;
                            setDateTo(newDate);
                        }}
                        cancelLabel="Отмена"
                        okLabel="Сохранить"
                        minDateMessage="Дата не должна быть раньше 2000 года"
                        maxDateMessage="Дата не должна превышать текущую дату"
                        disableFuture
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            {viewState(
                () => (
                    <AccordionLoader text="Не найдено данных за выбранный период" />
                ),
                () => (
                    <AccordionLoader />
                ),
                () => null,
                (list) => (
                    <Table data={list} columns={columns} keyProp={['dt', 'title', 'balance']} />
                )
            )}
        </Grid>
    );
};
