import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

// import * as serviceWorker from './serviceWorker';
import { Root } from './containers/Root';
import { apiGetMode } from './api';

import './index.css';

declare global {
    interface Window {
        emergencyMode: boolean;
        carrotQuestSecret?: string;
    }
}

const initSentry = (): void => {
    const DSN = process.env.REACT_APP_SENTRY_DSN;
    if (DSN) {
        import('@sentry/browser')
            .then((Sentry) => {
                Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
                console.log('Sentry successfully initialized.');
            })
            .catch((err) => console.warn('Failed to initialize Sentry.', err));
    }
};

const initGTM = (): void => {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID ?? '',
    };

    if (tagManagerArgs.gtmId) {
        TagManager.initialize(tagManagerArgs);
    }
};
apiGetMode()
    .then((result) => {
        window.emergencyMode = result.mode === 1;

        if (!window.emergencyMode) {
            initSentry();
            initGTM();
            window.carrotQuestSecret = process.env.REACT_APP_CARROT_QUEST_SECRET;
        }
    })
    .finally(() => {
        ReactDOM.render(<Root />, document.getElementById('root'));
    });
