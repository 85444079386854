import type { FC } from 'react';
import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { useStore } from 'effector-react';
import { CurrentParamsStore } from '../../effector/dashboard';
import { useDesignFeatures } from '../../hooks/designFeatures';

import { FeipTypograf } from '../../utils/typograf';

import { Section } from './Section';
import { SkeletonText } from './SkeletonText';

const useStyles = makeStyles((theme) =>
    createStyles({
        list: {
            listStyle: 'none',
            margin: '24px 0 0',
            padding: 0,
        },
        listItem: {
            display: 'flex',
            '&:not(:last-child)': {
                marginBottom: 12,
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        listLabel: {
            color: theme.palette.text.secondary,
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1.35,
            margin: 0,
            marginRight: 16,
            minWidth: 192,
        },
        listValue: {
            color: theme.palette.text.primary,
            fontSize: 16,
            lineHeight: 1.35,
            margin: 0,
        },
    })
);

// === List item component ===

type ListItemProps = {
    id?: string;
    title: string;
    isLoading: boolean;
    classes?: ClassNameMap<'listItem' | 'listLabel'>;
};

const ListItem: FC<ListItemProps> = (props) => {
    const { id, title, isLoading, classes, children } = props;

    return (
        <li {...{ id }} className={classes?.listItem}>
            <h4 className={classes?.listLabel}>{title}</h4>

            {isLoading ? <SkeletonText /> : children}
        </li>
    );
};

// === Section component ===

type AddressesSectionProps = {
    isLoading: boolean;
};

export const AddressesSection: FC<AddressesSectionProps> = (props) => {
    const { isLoading } = props;

    const classes = useStyles();

    const store = useStore(CurrentParamsStore);

    const listItemProps: Pick<ListItemProps, 'isLoading' | 'classes'> = {
        isLoading,
        classes: {
            listItem: classes.listItem,
            listLabel: classes.listLabel,
        },
    };

    const { displayDesignFeature } = useDesignFeatures();

    return (
        <Section title="Адреса">
            <ul className={classes.list}>
                {store.fizAddress &&
                    displayDesignFeature('main1_11', 'Физический адрес', ({ name, caption }) => (
                        <ListItem id={name} title={caption} {...listItemProps}>
                            <p className={classes.listValue}>{FeipTypograf.execute(store.fizAddress)}</p>
                        </ListItem>
                    ))}

                {store.urAddress &&
                    displayDesignFeature('main1_12', 'Юридический адрес', ({ name, caption }) => (
                        <ListItem id={name} title={caption} {...listItemProps}>
                            <p className={classes.listValue}>{FeipTypograf.execute(store.urAddress)}</p>
                        </ListItem>
                    ))}

                {store.postAddress &&
                    displayDesignFeature('main1_13', 'Почтовый адрес', ({ name, caption }) => (
                        <ListItem id={name} title={caption} {...listItemProps}>
                            <p className={classes.listValue}>{FeipTypograf.execute(store.postAddress)}</p>
                        </ListItem>
                    ))}
            </ul>
        </Section>
    );
};
