import { createDomain, createEffect, createEvent } from 'effector';
import { apiVideoControlProxy } from '../api';
import { clearSession } from './system';
import { ContractDescription, ContractEntity } from '../types/contract';
import { getIp } from '../utils/ip';

const encodeParams = (params: Record<string, string | number | boolean>): string =>
    Object.entries(params)
        .map((pair) => pair.map((item) => item.toString()).join('='))
        .join('&');

export type CameraData = {
    ID: number;
    Name: string;
    IPOrDomain: string;
    Login: string;
    Quota: number;
    // TODO: continue
};

export type TranslationPayload = {
    camera: CameraData;
    contract: ContractEntity;
};

export type ArchiveTranslationPayload = TranslationPayload & { duration: number; ts: string; tz: number };

export type TranslationData = {
    URL: string;
    Error?: string;
};

export type ChangeCameraNamePayload = TranslationPayload & { name: string };

type CamerasState = {
    list: CameraData[];
    translation: null | TranslationData;
    archiveTranslation: null | TranslationData;
};

type Status = { Error?: string };

export const CamerasDomain = createDomain();

export const clearCamerasCache = createEvent();

export const changeCameraName = createEffect<ChangeCameraNamePayload, Status>().use(async (data) => {
    const params = {
        ID: data.camera.ID,
        Name: data.name,
    };

    const payload = {
        ...data.contract,
        forpostURL: '/system-api/EditCamera',
        forpostURLEncodedData: encodeParams(params),
    };

    return (await apiVideoControlProxy(payload)) as Status;
});

export const getArchiveTranslation = createEffect<ArchiveTranslationPayload, TranslationData>().use(async (data) => {
    const ip = await getIp();

    const params = {
        UserLogin: data.camera.Login,
        UserIP: ip,
        CameraID: data.camera.ID,
        Duration: data.duration,
        Container: 'mp4',
        TS: data.ts,
        TZ: data.tz,
    };

    const payload = {
        ...data.contract,
        forpostURL: '/system-api/GetDownloadURL',
        forpostURLEncodedData: encodeParams(params),
    };

    const response = (await apiVideoControlProxy(payload)) as TranslationData;

    return response;
});

export const getTranslation = createEffect<TranslationPayload, TranslationData>().use(async (data) => {
    const ip = await getIp();

    const params = {
        UserLogin: data.camera.Login,
        UserIP: ip,
        CameraID: data.camera.ID,
    };

    const payload = {
        ...data.contract,
        forpostURL: '/system-api/GetTranslationURL',
        forpostURLEncodedData: encodeParams(params),
    };

    const response = (await apiVideoControlProxy(payload)) as TranslationData;

    return response;
});

export const fetchCameras = createEffect<ContractDescription & ContractEntity, CameraData[]>().use(async (data) => {
    const accountIds = data.forpostAccounts.map((item) => item.AccountId);
    // In fact, account ids can be not unique
    const uniqueAccountIds = accountIds.sort().filter((item, pos, ary) => {
        return !pos || item !== ary[pos - 1];
    });

    let cameras: CameraData[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const id of uniqueAccountIds) {
        const encodedParams = new URLSearchParams();
        encodedParams.append('AccountID', id.toString());

        const payload = {
            ...data,
            forpostURL: '/system-api/GetCameras',
            forpostURLEncodedData: encodedParams.toString(),
        };

        // eslint-disable-next-line no-await-in-loop
        const response = (await apiVideoControlProxy(payload)) as CameraData[];
        cameras = [...response, ...cameras];
    }

    return cameras;
});

const initialState: CamerasState = {
    list: [],
    translation: null,
    archiveTranslation: null,
};

export const CamerasStore = CamerasDomain.createStore(initialState)
    .on(fetchCameras.done, (state, payload) => ({
        ...state,
        list: payload.result,
        translation: null,
    }))
    .on(getTranslation.done, (state, payload) => ({
        ...state,
        translation: payload.result,
    }))
    .on(getArchiveTranslation.done, (state, payload) => ({
        ...state,
        archiveTranslation: payload.result,
    }))
    .reset(clearCamerasCache, clearSession);
