import { createDomain } from 'effector';
import moment, { Moment } from 'moment';

import { pipe } from 'fp-ts/es6/function';
import { Lens } from 'monocle-ts';

import { Protocol } from '../api/protocol';
import { apiSberbankPaymentHistory } from '../api';

import { clearSession } from './system';

import { PaymentHistoryResponse } from '../types/paymentHistory';

import { sanitizePagination } from '../utils/api';

export type SberbankHistoryState = {
    date: Moment;
    pagination: Pagination;
} & PaymentHistoryResponse;

const SberbankHistoryDomain = createDomain();

export const clearSberbankHistoryList = SberbankHistoryDomain.createEvent();
export const fetchSberbankHistoryList = SberbankHistoryDomain.createEffect<
    Protocol.SberbankHistoryRequest,
    Protocol.SberbankHistoryResponse
>();

fetchSberbankHistoryList.use(apiSberbankPaymentHistory);

const initialState: SberbankHistoryState = {
    date: moment(),
    items: [],
    minSumma: 0,
    maxSumma: 0,
    pagination: {
        pageNumber: 1,
        pageCount: 1,
        pageSize: 10,
        pageTotalRecordsCount: 0,
    },
};

const dateLens = Lens.fromProp<SberbankHistoryState>()('date');
const listLens = Lens.fromProp<SberbankHistoryState>()('items');
const paginationLens = Lens.fromProp<SberbankHistoryState>()('pagination');

export const SberbankHistoryStore = SberbankHistoryDomain.createStore(initialState)
    .on(fetchSberbankHistoryList.done, (state, { params, result: { items, ...pagination } }) =>
        pipe(
            state,
            listLens.set(items),
            paginationLens.set(sanitizePagination(pagination)),
            dateLens.set(moment(`${params.year}.${params.month}`, 'YYYY.MM'))
        )
    )
    .reset(clearSberbankHistoryList, clearSession);
