import React, { FunctionComponent, useEffect, useState } from 'react';
import { Fade, IconButton, Modal, Typography, TextField } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';

import { apiSetParamFullName } from '../../api';
import {
    CurrentContractStore,
    CurrentParamsStore,
    updateFullName,
} from '../../effector/dashboard';
import { useStyles } from '../modal/styles';
import { useStyles as useChangeParamStyles } from './styles';
import { ButtonWithLoading } from '../ButtonWithLoading';
import { Protocol } from '../../api/protocol';
import { EditButton } from '../EditButton';

export const ChangeFullNameParam: FunctionComponent = () => {
    const modalClasses = useStyles();
    const classes = useChangeParamStyles();

    const [loading, setLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [value, setValue] = useState('');

    const currentContract = useStore(CurrentContractStore);
    const currentParams = useStore(CurrentParamsStore);

    const { enqueueSnackbar } = useSnackbar();

    const closeModal = (): void => setModalOpen(false);

    const change = (): void => {
        if (!currentContract) return;

        if (value.length === 0) {
            enqueueSnackbar('Введите имя', { variant: 'warning' });
            return;
        }

        setLoading(true);
        const payload: Protocol.SetParamFullNameRequest = {
            ...currentContract,
            value,
        };

        apiSetParamFullName(payload)
            .then((response) => {
                enqueueSnackbar(response.result, { variant: 'success' });
                updateFullName(payload.value);
                closeModal();
            })
            .catch(({ message }) => enqueueSnackbar(message, { variant: 'error' }))
            .finally(() => setLoading(false));
    };

    useEffect(() => setValue(currentParams.fullName), []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <EditButton
                onClick={() => setModalOpen(true)}
                withTopGutter={false}
            />

            <Modal
                open={isModalOpen}
                onClose={closeModal}
            >
                <Fade in={isModalOpen}>
                    <div className={modalClasses.body}>
                        <Typography variant="h6" className={modalClasses.title}>
                            Изменить имя
                        </Typography>

                        <div className={classes.form}>
                            <TextField
                                placeholder="Имя"
                                onChange={(event) => setValue(event.target.value)}
                                defaultValue={value}
                            />
                        </div>

                        <div className={classes.button}>
                            <ButtonWithLoading
                                type="submit"
                                loading={loading}
                                color="primary"
                                onClick={change}
                                noMargin
                            >
                                Изменить
                            </ButtonWithLoading>
                        </div>

                        <IconButton aria-label="Закрыть" className={modalClasses.close} onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Fade>
            </Modal>
        </>
    )
};
