import { createDomain } from 'effector';
import moment, { Moment } from 'moment';

import { pipe } from 'fp-ts/es6/function';
import { Lens } from 'monocle-ts';

import { Protocol } from '../api/protocol';
import { apiPayonlinePaymentHistory } from '../api';

import { clearSession } from './system';

import { PaymentHistoryResponse } from '../types/paymentHistory';

import { sanitizePagination } from '../utils/api';

export type PayonlineHistoryState = {
    date: Moment;
    pagination: Pagination;
} & PaymentHistoryResponse;

const PayonlineHistoryDomain = createDomain();

export const clearPayonlineHistoryList = PayonlineHistoryDomain.createEvent();
export const fetchPayonlineHistoryList = PayonlineHistoryDomain.createEffect<
    Protocol.PayonlineHistoryRequest,
    Protocol.PayonlineHistoryResponse
>();

fetchPayonlineHistoryList.use(apiPayonlinePaymentHistory);

const initialState: PayonlineHistoryState = {
    date: moment(),
    items: [],
    minSumma: 0,
    maxSumma: 0,
    pagination: {
        pageNumber: 1,
        pageCount: 1,
        pageSize: 10,
        pageTotalRecordsCount: 0,
    },
};

const dateLens = Lens.fromProp<PayonlineHistoryState>()('date');
const listLens = Lens.fromProp<PayonlineHistoryState>()('items');
const paginationLens = Lens.fromProp<PayonlineHistoryState>()('pagination');

export const PayonlineHistoryStore = PayonlineHistoryDomain.createStore(initialState)
    .on(fetchPayonlineHistoryList.done, (state, { params, result: { items, ...pagination } }) =>
        pipe(
            state,
            listLens.set(items),
            paginationLens.set(sanitizePagination(pagination)),
            dateLens.set(moment(`${params.year}.${params.month}`, 'YYYY.MM'))
        )
    )
    .reset(clearPayonlineHistoryList, clearSession);
