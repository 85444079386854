import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import clsx from 'clsx';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { List, ListItem, Paper, Chip, Button, Tooltip } from '@material-ui/core';
import { ExpandMore, Warning, Help } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';

import { useStore } from 'effector-react';
import { CanChangeLimitStore, LimitSettingsStore } from '../effector/limits';
import { CurrentContractStore, CurrentDashboardStore, CurrentTariffStore, fetchDashboard } from '../effector/dashboard';
import { useDesignFeatures } from '../hooks/designFeatures';

import { formatPrice, foldApiTimestampToDate, formatApiDate } from '../utils/view';
import { LOCATION_HASH, LOCATION_ROUTE } from '../utils/constants';
import { FeipTypograf } from '../utils/typograf';
import { BusEvents, EventBus } from '../utils/bus';

import { ChangeLimitModalWithButton } from './ChangeLimitModalWithButton';
import { BalanceReplenishForm } from './BalanceReplenishForm';
import { TariffChangeCancelButton } from './TariffChangeCancelButton';
import { EditButton } from './EditButton';
import { TariffInfoModal } from './TariffInfoModal';

declare module '../utils/bus' {
    interface BusEvents {
        showBalanceBlock: void;
        showChangeTariffBlock: void;
    }
}

const foldApiDateToMonth = formatApiDate('D MMMM');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            color: theme.palette.text.primary,
            [theme.breakpoints.down('md')]: {
                width: 'auto',
            },
        },
        list: {
            padding: 0,
            [theme.breakpoints.down('md')]: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
        },
        item: {
            display: 'block',
            padding: 24,
            '&:not(:last-child)': {
                borderBottom: '1px solid #EAEAEA;',
            },
            [theme.breakpoints.down('md')]: {
                padding: 16,
                width: '33.33%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '50%',
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        itemToggleable: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        itemHeading: {
            color: '#808080',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1,
            margin: '0 0 12px',
        },
        itemButton: {
            margin: 0,
        },
        itemLink: {
            'html:root &': {
                alignItems: 'center',
                color: theme.palette.text.secondary,
                display: 'inline-flex',
                fontSize: 14,
                fontWeight: theme.typography.fontWeightMedium,
                textDecoration: 'none',

                '&:hover, &:focus': {
                    color: theme.palette.primary.main,
                },
            },
        },
        itemValue: {
            color: '#202020',
            fontSize: 16,
            lineHeight: 1.35,
            margin: 0,
        },
        itemValue_large: {
            fontSize: 24,
            fontWeight: 600,
            '& > button': {
                '&, &:focus, &:hover, &:active': {
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    margin: 0,
                    display: 'block',
                    boxShadow: 'none',
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    lineHeight: 'inherit',
                    fontFamily: 'inherit',
                    outline: 'none',
                    color: '#202020',
                    transition: 'opacity 0.125s ease-in-out',
                    cursor: 'pointer',
                },
                '&:hover': {
                    opacity: 0.8,
                },
            },
        },
        itemValueWithIcon: {
            alignItems: 'flex-start',
            cursor: 'pointer',
            display: 'flex',
        },
        itemDescription: {
            alignItems: 'center',
            color: '#404040',
            display: 'flex',
            fontSize: 14,
            lineHeight: 1,
            margin: '12px 0 0',
        },
        itemDescriptionLastPrice: {
            marginTop: 8,
        },
        addChip: {
            background: '#21BF73',
            color: 'white',
            fontWeight: 600,
            cursor: 'pointer',
            '&:hover, &:focus': {
                background: '#1CA663',
            },
        },
        label: {
            [theme.breakpoints.up('sm')]: {
                display: 'inline-block',
            },
            [theme.breakpoints.down('xs')]: {
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 0,
                '& svg': {
                    marginLeft: 4,
                    transition: 'transform 0.2s ease-out',
                },
            },
        },
        arrowIcon: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        arrowIconShown: {
            [theme.breakpoints.down('xs')]: {
                transform: 'rotateZ(180deg)',
            },
        },
        itemShown: {
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
        content: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        paymentButton: {
            marginTop: 24,
            width: '100%',
        },
        changeTariffLinkWrapper: {
            marginTop: 12,
        },
        changeTariffIcon: {
            fontSize: 16,
            marginLeft: 5,
        },
    })
);

type History = ReturnType<typeof useHistory>;
const makeRedirectBuilder = (h: History) => (targetRoute: string, event: keyof BusEvents) => (): void => {
    const { pathname, hash } = h.location;
    const currentRoute = pathname + hash;

    if (currentRoute !== targetRoute) h.push(targetRoute);
    EventBus.emit(event);
};

export const AsideInfo: FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();
    const redirectBuilder = makeRedirectBuilder(history);

    const tariffs = useStore(CurrentTariffStore);
    const fetching = useStore(fetchDashboard.pending);
    const dashboard = useStore(CurrentDashboardStore);
    const canChangeLimit = useStore(CanChangeLimitStore);
    const { limitMessage } = useStore(LimitSettingsStore);
    const { enableLimit = false, enablePayOnline = false, enableSberbank = false } =
        useStore(CurrentContractStore) || {};

    const [currentTariffInfoOpen, setCurrentTariffInfoOpen] = useState(false);
    const [plannedTariffInfoOpen, setPlannedTariffInfoOpen] = useState(false);
    const [isClosedInfo, setClosedInfo] = useState(true);
    const [isVisiblePaymentForm, setVisiblePaymentForm] = useState(false);

    const loading = fetching || dashboard === null;
    const canReplenishBalance = enableSberbank || enablePayOnline;
    const isDashboard = history.location.pathname === LOCATION_ROUTE.DASHBOARD;

    const { renderDesignFeature, displayDesignFeature, isDesignFeaturesVisible } = useDesignFeatures();

    useEffect(() => {
        return history.listen(() => {
            setClosedInfo(true);
        });
    }, [history]);

    const redirectToBalance = redirectBuilder(`${LOCATION_ROUTE.ADVANCED}${LOCATION_HASH.BALANCE}`, 'showBalanceBlock');

    const redirectToTariffChange = redirectBuilder(
        `${LOCATION_ROUTE.SETTINGS}${LOCATION_HASH.CHANGE_TARIFF}`,
        'showChangeTariffBlock'
    );

    const balanceDetailsContent = useMemo(() => {
        if (!dashboard) return <></>;

        return loading ? (
            <>
                <Skeleton variant="text" width={100} height={38} />
                <Skeleton variant="text" width={180} height={24} />
                <Skeleton variant="text" width={180} height={24} />
            </>
        ) : (
            <>
                {renderDesignFeature('main0_1', ({ name }) => (
                    <p id={`${name}-balance`} className={clsx(classes.itemValue, classes.itemValue_large)}>
                        <button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                history.push('/advanced#balance');
                            }}
                        >
                            {formatPrice(dashboard.balance ?? 0)}&nbsp;₽
                        </button>
                    </p>
                ))}

                {enableLimit &&
                    displayDesignFeature('main0_2_1', 'Лимит отключения', ({ name, caption }) => (
                        <div id={`${name}-limit`}>
                            <p className={classes.itemDescription}>
                                <span>
                                    {caption}: {formatPrice(dashboard.closeSumma ?? 0)}
                                    &nbsp;₽
                                </span>
                                {!canChangeLimit && (
                                    <Tooltip title={limitMessage}>
                                        <Warning
                                            style={{
                                                color: '#FAB000',
                                                marginLeft: '0.25em',
                                            }}
                                        />
                                    </Tooltip>
                                )}
                            </p>
                            {canChangeLimit && <ChangeLimitModalWithButton />}
                        </div>
                    ))}

                {canReplenishBalance &&
                    displayDesignFeature('main0_3', 'Пополнить баланс', ({ name, caption }) => (
                        <div id={name}>
                            <Button
                                variant="contained"
                                className={classes.paymentButton}
                                disabled={loading}
                                color={isVisiblePaymentForm ? 'default' : 'primary'}
                                onClick={() => setVisiblePaymentForm(!isVisiblePaymentForm)}
                            >
                                {caption}
                            </Button>
                            {isVisiblePaymentForm && <BalanceReplenishForm />}
                        </div>
                    ))}
            </>
        );
    }, [
        loading,
        isVisiblePaymentForm,
        classes,
        limitMessage,
        canChangeLimit,
        dashboard,
        history,
        enableLimit,
        canReplenishBalance,
        renderDesignFeature,
        displayDesignFeature,
    ]);

    const lastPaymentContent = useMemo(() => {
        if (!dashboard) return <></>;

        return loading ? (
            <>
                <Skeleton variant="text" width={80} height={34} />
                <Skeleton variant="text" width={130} height={24} />
            </>
        ) : (
            <>
                <div className={`${classes.itemValue} ${classes.content}`}>
                    <Chip
                        size="small"
                        color="primary"
                        classes={{ root: classes.addChip }}
                        label={`+ ${formatPrice(dashboard.lastPaySumm ?? 0)} ₽`}
                        onClick={redirectToBalance}
                    />
                </div>
                <p className={clsx(classes.itemDescription, classes.itemDescriptionLastPrice)}>
                    {`Внесён: ${foldApiTimestampToDate(dashboard.lastPayDate ?? 0)}`}
                </p>
            </>
        );
    }, [loading, classes, dashboard, redirectToBalance]);

    const currentTariffInfo = useMemo(() => {
        if (tariffs.current) {
            if (tariffs.current.description.length === 0) {
                return <span className={classes.itemValue}>{FeipTypograf.execute(tariffs.current.title)}</span>;
            }
            return (
                <>
                    <Tooltip title="Посмотреть детали текущего тарифа">
                        <span
                            role="button"
                            className={clsx(classes.itemValue, classes.itemValueWithIcon)}
                            onClick={() => setCurrentTariffInfoOpen(true)}
                        >
                            {FeipTypograf.execute(tariffs.current.title)}
                            <Help color="primary" fontSize="small" />
                        </span>
                    </Tooltip>

                    <TariffInfoModal
                        data={tariffs.current}
                        open={currentTariffInfoOpen}
                        closeModal={() => setCurrentTariffInfoOpen(false)}
                    />
                </>
            );
        }

        return <span className={classes.itemValue}>Отсутствует</span>;
    }, [classes, currentTariffInfoOpen, tariffs]);

    const plannedTariffInfo = useMemo(() => {
        if (tariffs.planned) {
            if (tariffs.planned.description.length === 0) {
                return <span className={classes.itemValue}>{FeipTypograf.execute(tariffs.planned.title)}</span>;
            }
            return (
                <>
                    <h3 className={classes.itemHeading} style={{ marginTop: 12 }}>
                        <Tooltip title="Посмотреть детали тарифа, на который запланирован переход">
                            <span
                                className={classes.itemValueWithIcon}
                                onClick={() => setPlannedTariffInfoOpen(true)}
                                role="button"
                            >
                                <span>
                                    C {foldApiDateToMonth(tariffs.planned.dateFrom)} у вас запланирован переход на новый
                                    тариф <br />
                                    {FeipTypograf.execute(tariffs.planned.title)}
                                </span>
                                <Help color="primary" fontSize="small" />
                            </span>
                        </Tooltip>
                    </h3>
                    <TariffInfoModal
                        data={tariffs.planned}
                        open={plannedTariffInfoOpen}
                        closeModal={() => setPlannedTariffInfoOpen(false)}
                    />
                    <TariffChangeCancelButton />
                </>
            );
        }

        return <></>;
    }, [classes, plannedTariffInfoOpen, tariffs]);

    const tariffDetailsContent = useMemo(() => {
        return loading ? (
            <>
                <Skeleton variant="text" width={200} height={22} />
                <Skeleton variant="text" width={200} height={22} />
            </>
        ) : (
            <div className={classes.itemValue}>
                {renderDesignFeature('main0_5', ({ name }) => (
                    <div id={`${name}-pricing-plan`}>{currentTariffInfo}</div>
                ))}
                {displayDesignFeature('main0_6', 'Изменить тариф', ({ name, caption }) => (
                    <div id={name}>
                        {plannedTariffInfo}

                        {tariffs.current && tariffs.current.tariffsForChange.length > 0 && (
                            <div className={classes.changeTariffLinkWrapper}>
                                <EditButton
                                    label={caption}
                                    classesOverride={{ button: classes.itemButton }}
                                    onClick={redirectToTariffChange}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    }, [
        loading,
        classes,
        redirectToTariffChange,
        currentTariffInfo,
        plannedTariffInfo,
        tariffs,
        renderDesignFeature,
        displayDesignFeature,
    ]);

    if (dashboard === null) {
        if (fetching) return <p>Панель инструментов загружается, это займёт немного времени</p>;
        return <p>Что-то пошло не так, попробуйте зайти позже или обратитесь в службу поддержки</p>;
    }

    return (
        <Paper className={classes.root}>
            <List className={classes.list}>
                {isDesignFeaturesVisible(['main0_1', 'main0_2', 'main0_3']) && (
                    <ListItem className={classes.item}>
                        <div
                            role="button"
                            onClick={() => {
                                setClosedInfo(!isClosedInfo);
                            }}
                        >
                            {displayDesignFeature('main0_1', 'Баланс', ({ name, caption }) => (
                                <h3 id={`${name}-title`} className={`${classes.itemHeading} ${classes.label}`}>
                                    <span>{caption}</span>
                                    {!isDashboard && (
                                        <ExpandMore
                                            className={clsx(classes.arrowIcon, !isClosedInfo && classes.arrowIconShown)}
                                        />
                                    )}
                                </h3>
                            ))}

                            {balanceDetailsContent}
                        </div>
                    </ListItem>
                )}

                {dashboard &&
                    dashboard.lastPaySumm &&
                    dashboard.lastPayDate &&
                    displayDesignFeature('main0_4', 'Последний платеж', ({ name, caption }) => (
                        <ListItem
                            id={name}
                            className={clsx(
                                classes.item,
                                !isDashboard && classes.itemToggleable,
                                !isClosedInfo && classes.itemShown
                            )}
                        >
                            <h3 className={classes.itemHeading}>{caption}</h3>

                            {lastPaymentContent}
                        </ListItem>
                    ))}

                {isDesignFeaturesVisible(['main0_5', 'main0_6']) && (
                    <ListItem
                        className={clsx(
                            classes.item,
                            !isDashboard && classes.itemToggleable,
                            !isClosedInfo && classes.itemShown
                        )}
                    >
                        {displayDesignFeature('main0_5', 'Тариф', ({ name, caption }) => (
                            <h3 id={`${name}-title`} className={classes.itemHeading}>
                                {caption}
                            </h3>
                        ))}

                        {tariffDetailsContent}
                    </ListItem>
                )}
            </List>
        </Paper>
    );
};
