import { createDomain } from 'effector';
import moment, { Moment } from 'moment';
import { insertAt, lookup } from 'fp-ts/es6/Record';
import { pipe } from 'fp-ts/es6/function';
import { getOrElse } from 'fp-ts/es6/Option';
import { Lens } from 'monocle-ts';
import { Protocol } from '../api/protocol';
import { apiContractTrafficByService, apiTrafficDownloadHTML } from '../api';
import { clearSession } from './system';
import { TrafficListItem, TrafficServiceId } from '../types/traffic';
import { sanitizePagination } from '../utils/api';

export type TrafficState = {
    date: Moment;
    list: Record<string, TrafficListItem[]>;
    serviceId: TrafficServiceId;
    pagination: Pagination;
};

const TrafficDomain = createDomain();

export const saveTrafficDate = TrafficDomain.event<Moment>();
export const clearTrafficList = TrafficDomain.event();
export const fetchTrafficList = TrafficDomain.effect<
    Protocol.ContractTrafficByServiceRequest,
    Protocol.ContractTrafficByServiceResponse
>();

export const downloadTrafficHTML = TrafficDomain.effect<
    Protocol.TrafficDownloadHTMLRequest,
    Protocol.TrafficDownloadHTMLResponse
>();

fetchTrafficList.use(apiContractTrafficByService);
downloadTrafficHTML.use(apiTrafficDownloadHTML);

const initialState: TrafficState = {
    date: moment().startOf('month'),
    list: {},
    serviceId: 'all',
    pagination: {
        pageNumber: 1,
        pageCount: 1,
        pageSize: 10,
        pageTotalRecordsCount: 0,
    },
};

const dateLens = Lens.fromProp<TrafficState>()('date');
const listLens = Lens.fromProp<TrafficState>()('list');
const serviceIdLens = Lens.fromProp<TrafficState>()('serviceId');
const paginationLens = Lens.fromProp<TrafficState>()('pagination');

export const TrafficStore = TrafficDomain.createStore(initialState)
    .on(saveTrafficDate, (state, payload) => dateLens.set(payload)(state))
    .on(fetchTrafficList.done, (state, { params, result: { items, ...rawPagination } }) => {
        const pagination = sanitizePagination(rawPagination);
        return pipe(
            state,
            serviceIdLens.set(params.serviceId),
            listLens.modify(insertAt(pagination.pageNumber.toString(), items)),
            paginationLens.set(pagination)
        );
    })
    .reset(clearTrafficList, clearSession);

export const CurrentTrafficList = TrafficStore.map((state) =>
    pipe(
        state.list,
        lookup(state.pagination.pageNumber.toString()),
        getOrElse<TrafficListItem[]>(() => [])
    )
);
