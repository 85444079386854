import React from 'react';

import { range } from 'fp-ts/es6/Array';

import { createStyles, makeStyles } from '@material-ui/core';
import { ChevronRightRounded } from '@material-ui/icons';

import { SkeletonText } from './SkeletonText';

const useStyles = makeStyles((theme) =>
    createStyles({
        list: {
            listStyle: 'none',
            padding: 0,
            margin: '32px 0 0',
        },
        listItem: {
            width: '100%',
            '&:not(:last-child)': {
                borderBottom: '1px solid #D0D0D080',
            },
            '&:last-child': {
                '& $listButton': {
                    paddingBottom: 0,
                },
            },
        },
        listButton: {
            display: 'flex',
            color: 'black',
            width: '100%',
            backgroundColor: 'transparent',
            border: 'none',
            borderRadius: 0,
            fontFamily: "'IBM Plex Sans', sans-serif",
            fontSize: 16,
            padding: '16px 0',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                '& $chevronIcon': {
                    color: theme.palette.primary.main,
                },
            },
        },
        chevronIcon: {
            color: '#A1A1A1',
            transition: 'color 0.2s ease-out',
            marginLeft: 'auto',
        },
    })
);

type ListItemBase = { ID: string | number };

type ListProps<Item extends ListItemBase> = {
    countOfSkeletons: number;
    items: Item[];
    renderSkeletonContent?: () => JSX.Element;
    renderItemContent: (item: Item) => JSX.Element;
    onItemClick?: (item: Item) => void;
    isLoading?: boolean;
};

export const List = <Item extends ListItemBase>(props: ListProps<Item>): JSX.Element => {
    const { countOfSkeletons, items, renderSkeletonContent, renderItemContent, onItemClick, isLoading = false } = props;

    const classes = useStyles();

    const handleItemClick = (item: Item) => () => {
        onItemClick?.(item);
    };

    return (
        <ul className={classes.list}>
            {isLoading
                ? range(1, countOfSkeletons).map((id) => (
                      <li key={id} className={classes.listItem}>
                          {renderSkeletonContent?.()}

                          <SkeletonText />

                          <ChevronRightRounded className={classes.chevronIcon} />
                      </li>
                  ))
                : items.map((item) => (
                      <li key={item.ID} className={classes.listItem}>
                          <button type="button" className={classes.listButton} onClick={handleItemClick(item)}>
                              {renderItemContent(item)}

                              <ChevronRightRounded className={classes.chevronIcon} />
                          </button>
                      </li>
                  ))}
        </ul>
    );
};
