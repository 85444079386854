import React, { FunctionComponent, useState } from 'react';

import { Cancel as CancelIcon } from '@material-ui/icons';

import { TariffChangeCancelConfirm } from './TariffChangeCancelConfirm';
import { EditButton } from './EditButton';

export const TariffChangeCancelButton: FunctionComponent = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <EditButton label='Отменить переход' onClick={() => setOpen(true)} icon={CancelIcon}/>
            <TariffChangeCancelConfirm open={open} onClose={() => setOpen(false)}/>
        </>
    )
};
