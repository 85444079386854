import { Iso } from 'monocle-ts';
import moment, { Moment } from 'moment';

import { BalanceApiPayload, BalanceAppPayload } from '../types/balance';

const buildMomentFromYearMonthPair = ([year, month]: [string, string]): Moment =>
    moment([
        Number(year),
        Number(month) - 1,
    ])

export const BalancePayloadIso = new Iso<BalanceAppPayload, BalanceApiPayload>(
    params => ({
        yearFrom: params.from.format('YYYY'),
        monthFrom: params.from.format('MM'),
        yearTo: params.to.format('YYYY'),
        monthTo: params.to.format('MM'),
    }),
    params => ({
        from: buildMomentFromYearMonthPair([params.yearFrom, params.monthFrom]),
        to: buildMomentFromYearMonthPair([params.yearTo, params.monthTo]),
    })
)

