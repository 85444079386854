import { createEffect, restore } from 'effector';
import { apiPayonlineSettings, apiSberbankSettings } from '../api';
import { Protocol } from '../api/protocol';

const defaultState: Protocol.PaymentSettingsResponse = {
    minSumma: 0,
    maxSumma: 0,
    text: '',
};

export const getSberbankSettings = createEffect<Protocol.SberbankSettingsRequest, Protocol.SberbankSettingsResponse>();

getSberbankSettings.use(apiSberbankSettings);

export const SberbankSettingsStore = restore<Protocol.SberbankSettingsResponse>(
    getSberbankSettings.doneData,
    defaultState
);

export const getPayonlineSettings = createEffect<
    Protocol.PayonlineSettingsRequest,
    Protocol.PayonlineSettingsResponse
>();

getPayonlineSettings.use(apiPayonlineSettings);

export const PayonlineSettingsStore = restore<Protocol.PayonlineSettingsResponse>(
    getPayonlineSettings.doneData,
    defaultState
);
