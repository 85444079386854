import React, { FunctionComponent, useState } from 'react';
import clsx from 'clsx';

import { IconButton, Fade, Modal, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';

import { useStore } from 'effector-react';
import { LimitSettingsStore } from '../effector/limits';
import { useStyles as useModalStyles } from './modal/styles';

import { ChangeLimitForm } from './ChangeLimitForm';
import { EditButton } from './EditButton';
import { useDesignFeatures } from '../hooks/designFeatures';

const useStyles = makeStyles(() =>
    createStyles({
        modalBody: {
            paddingBottom: 32,
            width: 420,
        },
        modalTitle: {
            lineHeight: 1.15,
            marginBottom: 12,
        },
        limitMessage: {
            fontSize: 14,
            lineHeight: 1.35,
            marginBottom: 16,
        },
        close: {
            position: 'absolute',
            right: 4,
            top: 4,
        },
    })
);

export const ChangeLimitModalWithButton: FunctionComponent = () => {
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const [open, setOpen] = useState(false);
    const limitSettings = useStore(LimitSettingsStore);
    const { displayDesignFeature } = useDesignFeatures();

    return (
        <>
            <EditButton
                label={displayDesignFeature('main0_2_2', 'Изменить лимит', ({ caption }) => caption)}
                onClick={() => setOpen(true)}
            />
            <Modal
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                aria-labelledby="Модальное окно выхода"
            >
                <Fade in={open}>
                    <div className={clsx(modalClasses.body, classes.modalBody)}>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {limitSettings === null ? (
                            <p>Загружаем..</p>
                        ) : (
                            <>
                                <Typography variant="h6" className={clsx(modalClasses.title, classes.modalTitle)}>
                                    {limitSettings.enableLimitChanging
                                        ? displayDesignFeature(
                                              'main0_2_3',
                                              'Изменение лимита отключения',
                                              ({ caption }) => caption
                                          )
                                        : 'Внимание'}
                                </Typography>

                                {limitSettings.enableLimitChanging ? (
                                    <div>
                                        <div className={classes.limitMessage}>{limitSettings.limitMessage}</div>
                                        <ChangeLimitForm setClose={() => setOpen(false)} />
                                        <IconButton
                                            aria-label="Закрыть"
                                            className={modalClasses.close}
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                ) : (
                                    <p>Изменение лимита невозможно для этого договора</p>
                                )}

                                <IconButton
                                    aria-label="Закрыть"
                                    className={classes.close}
                                    onClick={() => setOpen(false)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </>
                        )}
                    </div>
                </Fade>
            </Modal>
        </>
    );
};
