import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import MuiTable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles } from '@material-ui/core/styles';
import { BalanceType } from '../types/balance';

export type Column<T extends object, K extends keyof T = keyof T> = {
    id: K;
    label: string;
    align?: 'left' | 'right' | 'center';
    minWidth?: number;
    format?: (value: any) => ReactNode;
    callback?: (data: T, column: Column<T>) => void;
    isNoWrap?: boolean;
    tooltip?: {
        valueColumnID: K;
        format?: (value: BalanceType) => string;
        map?: (value: T[K]) => BalanceType;
    };
    isNoWrapHeader?: boolean;
};

export type TableColumns<T extends object> = Column<T>[];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    noWrap: {
        whiteSpace: 'nowrap',
    },
});

type TableProps<T extends object, K extends keyof T = keyof T> = {
    columns: TableColumns<T>;
    keyProp: K | K[];
    data: T[];
};

const resolveKeyProp = <T extends object, K extends keyof T = keyof T>(obj: T, arg: K | K[]): string =>
    Array.isArray(arg) ? arg.map((k) => obj[k]).join('-') : `${obj[arg]}`;

const renderCellContent = <T extends Record<string, any>>(row: T, column: Column<T>): JSX.Element => {
    const value = row[column.id];

    if (column.callback !== undefined) column.callback(row, column);

    // Exception for balance income/outcome
    if (column.tooltip !== undefined) {
        const incomeType = column.tooltip.map
            ? column.tooltip.map(row[column.tooltip.valueColumnID])
            : row[column.tooltip.valueColumnID];
        const tooltipCaption = column.tooltip.format
            ? column.tooltip.format(incomeType)
            : row[column.tooltip.valueColumnID];

        // Type assertion hack
        const formattedValue = (column.format ? column.format(value) : value) as string;

        const content =
            // eslint-disable-next-line no-nested-ternary
            (incomeType as unknown) === BalanceType.Info ? (
                <Chip size="small" label={formattedValue.replace(/^-/, '- ')} style={{ fontWeight: 500 }} />
            ) : (incomeType as unknown) === BalanceType.Income ? (
                <Chip
                    size="small"
                    style={{
                        color: '#26B160',
                        backgroundColor: '#DCF8E8',
                        fontWeight: 500,
                    }}
                    label={`+ ${formattedValue}`}
                />
            ) : (
                <Chip
                    size="small"
                    style={{
                        color: '#CE484A',
                        backgroundColor: '#FFE4E5',
                        fontWeight: 500,
                    }}
                    label={`- ${formattedValue}`}
                />
            );

        return (
            <Tooltip title={tooltipCaption}>
                <div>{content}</div>
            </Tooltip>
        );
    }

    return <>{column.format ? column.format(value) : value}</>;
};

export const Table: <T extends object>(props: TableProps<T>) => JSX.Element = ({ columns, data, keyProp }) => {
    const classes = useStyles();
    return (
        <TableContainer className={classes.container}>
            <MuiTable stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id as string}
                                align={column.align}
                                className={clsx(column.isNoWrapHeader && classes.noWrap)}
                                style={{ minWidth: column.minWidth }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow
                            key={`row-${resolveKeyProp(row, keyProp)}-${index}`}
                            role="checkbox"
                            hover
                            tabIndex={-1}
                        >
                            {columns.map((column) => {
                                return (
                                    <TableCell
                                        key={column.id as string}
                                        align={column.align}
                                        className={clsx(column.isNoWrap && classes.noWrap)}
                                    >
                                        {renderCellContent(row, column)}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};
