import React, { FunctionComponent } from 'react';
import {
    Modal,
    Button,
    Fade,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useStyles } from './modal/styles';

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    handleLogout: () => void,
};

const LogoutModal: FunctionComponent<Props> = ({ open, setOpen, handleLogout }) => {
    const classes = useStyles();
    const closeModal = (): void => { setOpen(false) };

    return (
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="Модальное окно выхода"
        >
            <Fade in={open}>
                <div className={classes.body}>
                    <Typography variant="h6" className={classes.title}>
                        Вы действительно хотите выйти?
                    </Typography>
                    <div className={classes.buttons}>
                        <Button
                            onClick={closeModal}
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                        >
                            Отменить
                        </Button>
                        <Button
                            onClick={handleLogout}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                        >
                            Подтвердить
                        </Button>
                    </div>

                    <IconButton aria-label="Закрыть" className={classes.close} onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </Fade>
        </Modal>
    );
};

export { LogoutModal };
