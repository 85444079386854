import { BASE_ROUTES } from './constants';

type VariableParams = Record<string, string | number>;

export const rollbackRouteToBase = (route: string): string => {
    for (let i = 0; i < BASE_ROUTES.length; i += 1) {
        if (route.match(`^${BASE_ROUTES[i]}/`) !== null) {
            return BASE_ROUTES[i];
        }
    }
    return route;
};

export const buildUrl = (path: string, params: VariableParams): string =>
    Object.keys(params).reduce((url, key) => url.replace(`:${key}`, params[key].toString()), path);
