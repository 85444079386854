import React, { FunctionComponent, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
    createStyles,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Theme,
    Typography,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import { pipe } from 'fp-ts/es6/pipeable';

import { useStore } from 'effector-react';
import { updateSession } from '../effector/system';
import { Protocol } from '../api/protocol';

import { withTitle } from '../containers/WithTitle';
import { FeaturedLayout } from '../containers/FeaturedLayout';
import { ButtonWithLoading } from '../components/ButtonWithLoading';
import { LOCATION_CAPTION } from '../utils/constants';
import { sendLoginEvent } from '../utils/analytics';

const Styles = (theme: Theme) =>
    createStyles({
        root: {
            minHeight: 560,
        },
        margin: {
            margin: theme.spacing(1),
        },
        loginButton: {
            display: 'block',
            fontSize: 16,
            height: 48,
            marginTop: 32,
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: 24,
            },
        },
        heading: {
            color: theme.palette.secondary.main,
            fontWeight: 500,
            fontSize: 24,
            lineHeight: 1,
            marginBottom: 32,
        },
        input: {
            borderRadius: 5,
        },
        textField: {
            width: '100%',
            '& .MuiFormLabel-root': {
                color: theme.palette.text.secondary,
            },
            '& .MuiInputBase-root': {
                backgroundColor: '#F5F5F5',
            },
            '& label.Mui-focused': {
                color: theme.palette.text.secondary,
            },
        },
    });

type LoginFormData = Protocol.LoginRequest;

type Props = WithStyles<typeof Styles> & RouteComponentProps;
const Login: FunctionComponent<Props> = ({ classes }) => {
    const [showPassword, setShowPassword] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const loading = useStore(updateSession.pending);

    const { register, handleSubmit } = useForm<LoginFormData>({
        defaultValues: {
            login: '',
            password: '',
        },
    });

    const onSubmit = (payload: LoginFormData): void => {
        if (loading) return;
        updateSession(payload)
            .then(() => {
                sendLoginEvent();
                enqueueSnackbar('Вы успешно вошли в личный кабинет', {
                    variant: 'success',
                });
            })
            .catch(({ message }) => {
                enqueueSnackbar(message, {
                    variant: 'error',
                });
            });
    };

    return (
        <FeaturedLayout withLogo>
            <Typography variant="h5" align="center" className={classes.heading}>
                Личный кабинет
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid spacing={1} container direction="column">
                    <Grid item>
                        <Grid container spacing={1} alignItems="flex-end">
                            <TextField
                                classes={{ root: classes.textField }}
                                id="input-with-icon-grid"
                                label="Логин"
                                style={{ width: '100%' }}
                                name="login"
                                variant="filled"
                                InputProps={{
                                    classes: {
                                        root: classes.input,
                                    },
                                    disableUnderline: true,
                                }}
                                inputRef={register}
                            />
                        </Grid>
                    </Grid>
                    <Grid item style={{ marginTop: 16 }}>
                        <Grid container spacing={1} alignItems="flex-end">
                            <TextField
                                classes={{ root: classes.textField }}
                                label="Пароль"
                                inputRef={register}
                                id="password-field"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                variant="filled"
                                InputProps={{
                                    classes: {
                                        root: classes.input,
                                    },
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Показать или скрыть пароль"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={(e) => e.preventDefault()}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <ButtonWithLoading
                            type="submit"
                            size="large"
                            color="primary"
                            noMargin
                            className={classes.loginButton}
                            loading={loading}
                        >
                            Войти
                        </ButtonWithLoading>
                    </Grid>
                </Grid>
            </form>
        </FeaturedLayout>
    );
};

const component = pipe(Login, withStyles(Styles), withTitle(LOCATION_CAPTION.LOGIN), withRouter);
export { component as Login };
