import { createDomain } from 'effector';
import { apiContractTariffList } from '../api';
import { Protocol } from '../api/protocol';
import { clearSession } from './system';
import { TariffListItem } from '../types/tariff';

type TariffListState = TariffListItem[];

export const TariffListDomain = createDomain();

export const clearTariffList = TariffListDomain.createEvent();
export const fetchTariffList = TariffListDomain.createEffect<
    Protocol.ContractTariffsRequest,
    Protocol.ContractTariffsResponse
>();

fetchTariffList.use(apiContractTariffList);

const initialState: TariffListState = [];

export const TariffListStore = TariffListDomain.createStore(initialState)
    .on(fetchTariffList.doneData, (state, payload) => payload)
    .reset(clearTariffList, clearSession);
