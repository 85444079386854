import React, { FunctionComponent } from 'react';

import { Modal, Fade, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';

import { ContractTariff } from '../types/tariff';

import { TariffDescriptionList } from './TariffCard';

import { useStyles as useModalStyles } from './modal/styles';

type Props = {
    open: boolean;
    data: ContractTariff;
    closeModal: Noop;
};

const useStyles = makeStyles(() =>
    createStyles({
        list: {
            marginTop: 24,
        },
    })
);

const TariffInfoModal: FunctionComponent<Props> = (props) => {
    const { open, closeModal, data } = props;

    const classes = useStyles();
    const modalClasses = useModalStyles();

    return (
        <Modal open={open} onClose={closeModal}>
            <Fade in={open}>
                <div className={modalClasses.body}>
                    <Typography variant="h6" className={modalClasses.title}>
                        {data.title}
                    </Typography>

                    <div className={classes.list}>
                        <TariffDescriptionList items={data.description} vertical />
                    </div>

                    <IconButton aria-label="Закрыть" className={modalClasses.close} onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </Fade>
        </Modal>
    );
};

export { TariffInfoModal };
