import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';

type Props = { text?: string };
export const AccordionLoader: FunctionComponent<Props> = (props) => {
    return (
        <Grid
            container
            alignItems="center"
            justify="center"
            style={{ minHeight: 100, color: '#a2a2a2' }}
        >
            {props.children ? props.children : (props.text ?? 'Загрузка...')}
        </Grid>
    );
};
