import React, { FC, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { createStyles, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { pipe } from 'fp-ts/es6/function';

import { useHistory, useParams } from 'react-router-dom';

import { LOCATION_CAPTION, LOCATION_ROUTE } from '../utils/constants';

import { buildUrl } from '../utils/routes';

import { withTitle } from '../containers/WithTitle';

import { CustomIconButton } from '../components/CustomIconButton';
import { VideoPlayer } from '../components/VideoPlayer';

import arrowBackSvgSrc from '../assets/icons/arrow-back.svg';
import { CurrentContractStore, CurrentDashboardStore } from '../effector/dashboard';
import { CamerasStore, TranslationData, fetchCameras, getArchiveTranslation } from '../effector/cameras';
import { AccordionLoader } from '../components/AccordionLoader';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            boxShadow: '0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12)',
            borderRadius: '0 0 4px 4px',
            overflow: 'hidden',
            [theme.breakpoints.down('md')]: {
                paddingLeft: 0,
                paddingRight: 0,
                width: '100%',
            },
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            padding: 32,
        },
        arrowBackButton: {
            borderRadius: 0,
            padding: 0,
            '&:hover, &:focus, &:focus-visible, &:active': {
                backgroundColor: 'transparent',
            },
            marginRight: 16,
        },
        title: {
            color: theme.palette.text.primary,
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1,
            margin: '0 16px 0 0',
            [theme.breakpoints.down('md')]: {
                fontSize: 20,
            },
        },
        section: {
            padding: '0 32px 16px',
            '&:not(:last-child)': {
                borderBottom: '1px solid #EAEAEA',
            },
        },
    })
);

type ArchiveParams = {
    id: string;
    daysAgo: string;
};

const minutesInDay = 1440;

const Archive: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { id, daysAgo } = useParams<ArchiveParams>();

    const dashboard = useStore(CurrentDashboardStore);
    const currentContract = useStore(CurrentContractStore);
    const { list } = useStore(CamerasStore);
    const [convertedLink, setConvertedLink] = useState('');
    const [error, setError] = useState('');

    const backToCamera = (): void => {
        history.push(buildUrl(LOCATION_ROUTE.CAMERA, { id }));
    };

    const waitForTranslation = async (archiveTranslation: TranslationData): Promise<void> => {
        await new Promise((resolve) => setTimeout(resolve, 5000));

        if (archiveTranslation.Error === undefined) {
            fetch(archiveTranslation.URL, {
                method: 'GET',
            })
                .then((result) => {
                    if (result.status === 423) {
                        waitForTranslation(archiveTranslation);
                    } else if (result.status === 200) {
                        setConvertedLink(archiveTranslation.URL);
                    } else {
                        setError('Видео не найдено');
                    }
                })
                .catch(() => setError('Не удалось загрузить видео'));
        } else {
            setError(archiveTranslation.Error);
        }
    };

    useEffect(() => {
        if (dashboard !== null && currentContract !== null && list.length === 0)
            fetchCameras({ ...currentContract, ...dashboard });
    }, [dashboard, currentContract, list]);

    useEffect(() => {
        setConvertedLink('');
        setError('');

        const camera = list.find((item) => item.ID === Number(id));

        if (camera !== undefined && currentContract !== null) {
            getArchiveTranslation({
                camera,
                contract: currentContract,
                ts: moment().startOf('day').subtract(daysAgo, 'days').format('X'),
                tz: moment().utcOffset() * 60,
                duration: Number(daysAgo) > 0 ? minutesInDay : moment().diff(moment().startOf('day'), 'minutes'),
            })
                .then((result) => {
                    waitForTranslation(result);
                })
                .catch(() => setError('Не удалось загрузить видео'));
        }
    }, [list]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <CustomIconButton
                    src={arrowBackSvgSrc}
                    aria-label="go-back"
                    disableRipple
                    imageOptions={{
                        width: 24,
                        height: 20,
                        color: '#A1A1A1',
                    }}
                    classes={{ root: classes.arrowBackButton }}
                    onClick={backToCamera}
                />

                <h2 className={classes.title}>Запись от {moment().subtract(daysAgo, 'days').format('D MMMM YYYY')}</h2>
            </div>
            <section className={classes.section}>
                {convertedLink.length > 0 ? (
                    <VideoPlayer source={convertedLink} />
                ) : (
                    <AccordionLoader text={error.length > 0 ? error : undefined} />
                )}
            </section>
        </div>
    );
};

const Component = pipe(Archive, withTitle(LOCATION_CAPTION.DASHBOARD));
export { Component as Archive };
