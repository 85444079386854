import { createDomain } from 'effector';

import { apiContractServiceList } from '../api';
import { Protocol } from '../api/protocol';

import { clearSession } from './system';

import { ServiceListItem } from '../types/service';
import { fetchDashboard } from './dashboard';

export const ServiceListDomain = createDomain();

export const clearServiceList = ServiceListDomain.createEvent();
export const fetchServiceList = ServiceListDomain.createEffect<
    Protocol.ContractServicesRequest,
    Protocol.ContractServicesResponse
>();

fetchServiceList.use(apiContractServiceList);

type ServiceListState = {
    list: ServiceListItem[];
    fetched: boolean;
};

const initialState: ServiceListState = {
    list: [],
    fetched: false,
};

export const ServiceListStore = ServiceListDomain.createStore(initialState)
    .on(fetchServiceList.doneData, (state, payload) => ({
        list: payload,
        fetched: true,
    }))
    .reset(clearServiceList, clearSession, fetchDashboard.done);
