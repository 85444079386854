import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            [theme.breakpoints.down('md')]: {
                paddingLeft: 0,
                paddingRight: 0,
                width: '100%',
            },
        },
    })
);

export const Content: FunctionComponent = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div style={{ width: '100%' }}>{props.children}</div>
        </div>
    );
};
