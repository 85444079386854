declare global {
    interface Window {
        dataLayer: DataLayerEventObject[];
    }
}

type EmptyPayload = Record<string, never>;

type DataLayerEvents = {
    LOGIN_SUCCESS: EmptyPayload;
};

type EventType = keyof DataLayerEvents;

type DataLayerEventObject = {
    event?: EventType;
    eventCallback?: () => void;
};

function sendGoogleTagManagerEvent<T extends EventType>(event: T, options: DataLayerEvents[T]): Promise<void> {
    if (window.dataLayer !== undefined && !window.emergencyMode) {
        return new Promise((resolve, reject) => {
            window.dataLayer.push({ event, eventCallback: resolve, ...options });
            setTimeout(reject, 3000);
        });
    }

    return Promise.resolve();
}

export const sendLoginEvent = (): void => {
    sendGoogleTagManagerEvent('LOGIN_SUCCESS', {}).finally(undefined);
};
