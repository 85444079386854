import { Lens } from 'monocle-ts';

import { createDomain, createEvent } from 'effector';
import { clearSession } from './system';

export type CarrotQuestState = 'idle' | 'loading' | 'success' | 'failed';
type ChatState = {
    isInitialized: boolean;
    state: CarrotQuestState;
};

const isInitializedLens = Lens.fromProp<ChatState>()('isInitialized');
const stateLens = Lens.fromProp<ChatState>()('state');

export const ChatDomain = createDomain();

export const setChatIsInitialized = createEvent<boolean>();
export const setChatState = createEvent<CarrotQuestState>();
export const clearChatState = createEvent();

const initialState: ChatState = {
    isInitialized: false,
    state: 'idle',
};

export const ChatStore = ChatDomain.createStore(initialState)
    .on(setChatIsInitialized, (s, value) => isInitializedLens.set(value)(s))
    .on(setChatState, (s, value) => stateLens.set(value)(s))
    .reset(clearChatState, clearSession);
