import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { pipe } from 'fp-ts/es6/function';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { Inbox as InboxIcon } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';

import { useStore } from 'effector-react';
import { CurrentContractStore } from '../effector/dashboard';
import { CurrentNewsStore, fetchNews } from '../effector/news';

import { foldApiTimestampToLocalizedDate } from '../utils/view';
import { LOCATION_CAPTION } from '../utils/constants';
import { FeipTypograf } from '../utils/typograf';

import { withTitle } from '../containers/WithTitle';
import { Content } from '../containers/Content';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        newsCard: {
            boxShadow: 'none',
            padding: '0 32px 32px',
            '&:not(:last-child)': {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
        },
        cardHeaderRoot: {
            padding: '32px 0 16px',
        },
        cardBody: {
            color: theme.palette.secondary.dark,
            fontSize: 16,
            lineHeight: 1.5,
            maxWidth: 540,
            padding: 0,
            '&:last-child': {
                paddingBottom: 0,
            },
        },
        cardHeaderSubheader: {
            color: theme.palette.primary.contrastText,
            fontSize: 24,
            lineHeight: 1.25,
            fontWeight: 500,
            marginTop: 16,
        },
        cardHeaderTitle: {
            color: theme.palette.text.secondary,
            fontSize: 14,
        },
        placeholder: {
            alignItems: 'center',
            backgroundColor: 'white',
            borderRadius: '0 0 5px 5px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
            minHeight: 150,
        },
        placeholderText: {
            color: theme.palette.text.secondary,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: 24,
            lineHeight: 1.25,
            margin: 0,
            marginTop: 16,
            textAlign: 'center',
        },
    })
);

const SkeletonCard = (): JSX.Element => {
    const styles = useStyles();

    return (
        <Card className={styles.newsCard} square>
            <CardHeader
                classes={{
                    root: styles.cardHeaderRoot,
                    title: styles.cardHeaderTitle,
                    subheader: styles.cardHeaderSubheader,
                }}
                title={<Skeleton width="65%" />}
                subheader={<Skeleton height={18} width="30%" />}
            />
            <CardContent
                classes={{
                    root: styles.cardBody,
                }}
            >
                <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </>
            </CardContent>
        </Card>
    );
};

const News: FunctionComponent = () => {
    const styles = useStyles();

    const { push } = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const store = useStore(CurrentNewsStore);
    const loading = useStore(fetchNews.pending);
    const currentContract = useStore(CurrentContractStore);
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        if (currentContract !== null && !fetched) {
            fetchNews(currentContract)
                .then(() => {
                    setFetched(true);
                })
                .catch((e) => {
                    push('/dashboard');
                    enqueueSnackbar(e.message);
                });
        }
    }, [fetched, push, enqueueSnackbar, currentContract]);

    // Handle contract id change in header
    useEffect(() => {
        setFetched(false);
    }, [currentContract]);

    if (!loading && store.news.length === 0)
        return (
            <div className={styles.placeholder}>
                <InboxIcon fontSize="large" htmlColor="#A0A0A0" />

                <p className={styles.placeholderText}>Новостей нет</p>
            </div>
        );

    return (
        <Content>
            {loading ? (
                <>
                    <SkeletonCard />
                    <SkeletonCard />
                </>
            ) : (
                store.news.map((article, id) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Card className={styles.newsCard} key={id} square>
                        <CardHeader
                            classes={{
                                root: styles.cardHeaderRoot,
                                title: styles.cardHeaderTitle,
                                subheader: styles.cardHeaderSubheader,
                            }}
                            subheader={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: FeipTypograf.execute(article.title),
                                    }}
                                />
                            }
                            title={foldApiTimestampToLocalizedDate(article.dateFrom)}
                        />
                        <CardContent
                            classes={{
                                root: styles.cardBody,
                            }}
                            dangerouslySetInnerHTML={{
                                __html: FeipTypograf.execute(article.text),
                            }}
                        />
                    </Card>
                ))
            )}
        </Content>
    );
};

const Component = pipe(News, withTitle(LOCATION_CAPTION.NEWS));
export { Component as News };
