import React, { FunctionComponent } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { pipe } from 'fp-ts/es6/function';
import { useDesignFeatures } from '../hooks/designFeatures';

import { LOCATION_CAPTION } from '../utils/constants';

import { CarrotQuestProvider } from '../providers/CarrotQuestProvider';

import { withTitle } from '../containers/WithTitle';
import { Feedback } from '../components/Feedback';
import { Content } from '../containers/Content';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: 'white',
            boxShadow: '0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12)',
            borderRadius: '0 0 4px 4px',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: 16,
            padding: 32,
            [theme.breakpoints.down('md')]: {
                padding: 20,
            },
        },
    })
);

const Help: FunctionComponent = () => {
    const { isDesignFeatureVisible, displayDesignFeature } = useDesignFeatures();
    const classes = useStyles();

    const needInitCarrot = isDesignFeatureVisible('main5_1', false);

    return (
        <Content>
            <CarrotQuestProvider needInitCarrot={false}>
                <div className={classes.root}>
                    {displayDesignFeature('main5_1', 'Поддержка в чате', ({ name, caption }) => (
                        <Feedback id={name} title={caption} type="chat" />
                    ))}
                    {displayDesignFeature('main5_2', 'Написать нам', ({ name, caption }) => (
                        <Feedback id={name} title={caption} type="feedback" />
                    ))}
                    {displayDesignFeature('main5_3', 'Заказать звонок', ({ name, caption }) => (
                        <Feedback id={name} title={caption} type="callRequest" />
                    ))}
                </div>
            </CarrotQuestProvider>
        </Content>
    );
};

const Component = pipe(Help, withTitle(LOCATION_CAPTION.HELP));
export { Component as Help };
