import React, { FunctionComponent } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import appStore from '../assets/mobile-apps/app-store.svg';
import googlePlay from '../assets/mobile-apps/google-play.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        heading: {
            margin: '0 0 16px',
            fontSize: 16,
            fontWeight: 400,
            textAlign: 'center',
            color: '#606060',
            [theme.breakpoints.down('xs')]: {
                marginBottom: 12,
                fontSize: 14,
            },
        },
        list: {
            display: 'flex',
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
        listItem: {
            '&:not(:last-child)': {
                marginRight: 16,
                [theme.breakpoints.down('xs')]: {
                    marginRight: 12,
                },
            },
        },
        link: {
            '-webkit-tap-highlight-color': 'transparent',
            '&:hover': {
                '& $linkIcon': {
                    opacity: 0.7,
                },
            },
        },
        linkIcon: {
            display: 'block',
            width: 'auto',
            height: 40,
            backfaceVisibility: 'hidden',
            transform: 'translateZ(0)',
            transition: 'opacity 0.25s ease',
        },
    })
);

export const MobileApps: FunctionComponent = () => {
    const classes = useStyles();

    const links = [
        {
            address: 'https://apps.apple.com/ru/app/iks/id1537764891',
            icon: appStore,
        },
        {
            address: 'https://play.google.com/store/apps/details?id=co.feip.iks',
            icon: googlePlay,
        },
    ];

    return (
        <div className={classes.root}>
            <h3 className={classes.heading}>Приложение для мобильных устройств</h3>

            <ul className={classes.list}>
                {links.map((link, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li className={classes.listItem} key={index}>
                        <a
                            className={classes.link}
                            href={link.address}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img className={classes.linkIcon} src={link.icon} alt="" />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};
