import React, { FunctionComponent } from 'react';
import { Theme, Button, ButtonProps, createStyles, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        wrapperFullWidth: {
            width: '100%',
        },
        wrapperHalfWidth: {
            width: '50%',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        noMargin: {
            margin: 0,
        },
    })
);

type Props = { loading: boolean; noMargin?: boolean; halfWidth?: boolean; containerClass?: string } & Omit<
    ButtonProps,
    'variant'
>;
export const ButtonWithLoading: FunctionComponent<Props> = ({
    loading,
    disabled,
    children,
    noMargin = false,
    containerClass,
    ...buttonProps
}) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(
                classes.wrapper,
                buttonProps.fullWidth && classes.wrapperFullWidth,
                buttonProps.halfWidth && classes.wrapperHalfWidth,
                noMargin && classes.noMargin,
                containerClass
            )}
        >
            <Button variant="contained" disabled={loading || disabled} {...buttonProps}>
                {children}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />}
        </div>
    );
};
