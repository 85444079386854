import React, { FunctionComponent } from 'react';
import {
    Card,
    CardContent,
    createStyles,
    Grid,
    Theme,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import clsx from 'clsx';

import { pipe } from 'fp-ts/es6/pipeable';

import LogoImage from '../assets/logo.svg';
import BackgroundImage from '../assets/bg.svg';

const Styles = (theme: Theme) =>
    createStyles({
        root: {
            minHeight: '100vh',
            backgroundColor: theme.palette.primary.main,
            backgroundImage: `url(${BackgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom right',
            backgroundSize: 'cover',
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        withoutBg: {
            background: 'none',
        },
        logo: {
            width: 115,
            margin: '0 auto',
            marginBottom: 24,
            display: 'block',
        },
        card: {
            width: 400,
            maxWidth: '100%',
        },
        cardContent: {
            padding: 40,
            '&:last-child': {
                paddingBottom: 40,
            },
            [theme.breakpoints.down('md')]: {
                padding: 32,
                '&:last-child': {
                    paddingBottom: 32,
                },
            },
            [theme.breakpoints.down('sm')]: {
                padding: 24,
                '&:last-child': {
                    paddingBottom: 24,
                },
            },
        },
    });

type FeaturedLayoutProps = WithStyles<typeof Styles> & {
    withLogo?: boolean;
    withoutBg?: boolean;
};
const FeaturedLayout: FunctionComponent<FeaturedLayoutProps> = ({
    classes,
    children,
    withLogo = false,
    withoutBg = false,
}) => {
    return (
        <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            className={clsx(classes.root, withoutBg && classes.withoutBg)}
        >
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    {withLogo && (
                        <img
                            className={classes.logo}
                            src={LogoImage}
                            alt="IKS Logo"
                        />
                    )}
                    {children}
                </CardContent>
            </Card>
        </Grid>
    );
};

const component = pipe(FeaturedLayout, withStyles(Styles));
export { component as FeaturedLayout };
