import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { Button, createStyles, InputAdornment, TextField, Theme } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useStore } from 'effector-react';
import {
    DEFAULT_PAYMENT_PRICE,
    MAX_DAY_OF_MONTH,
    MAX_PAYMENT_PRICE,
    MIN_DAY_OF_MONTH,
    MIN_PAYMENT_PRICE,
} from '../utils/constants';
import { CurrentContractStore, CurrentParamsStore } from '../effector/dashboard';
import { ButtonWithLoading } from './ButtonWithLoading';
import { apiAutoReplenishmentInfo, apiDoAutoReplenishment } from '../api';
import { redirect } from '../utils/query';
import { formatErrorText, formatPrice } from '../utils/view';
import { isEmail, isInt } from '../utils/guards';
import { DeleteAutoReplenishment } from './DeleteAutoReplenishment';
import { Protocol } from '../api/protocol';
import { AccordionLoader } from './AccordionLoader';
import { firstContact } from '../utils/strings';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 8,
        },
        textInput: {
            marginBottom: 16,
        },
        button: {
            marginTop: 12,
            width: '100%',
        },
        enabledAutopayMessage: {
            fontSize: 16,
            lineHeight: 1.5,
        },
    }),
);

const validationSchema = {
    email: {
        required: true,
        validate: (value: string) => isEmail(value) || 'E-mail имеет неверный формат',
    },
    summa: {
        validate: (value: string) => (
            (Number(value) >= MIN_PAYMENT_PRICE && Number(value) <= MAX_PAYMENT_PRICE)
            || `Сумма должна быть в промежутке от ${MIN_DAY_OF_MONTH} до ${MAX_DAY_OF_MONTH}`
        ),
        required: true,
    },
    day: {
        validate: (value: string) => (
            (Number(value) >= MIN_DAY_OF_MONTH && Number(value) <= MAX_DAY_OF_MONTH)
            || `Число месяца должно быть в промежутке от ${MIN_DAY_OF_MONTH} до ${MAX_DAY_OF_MONTH}`
        ),
        required: true,
    },
};

type FormInputs = Protocol.AutoReplenishmentRequest;

type Props = {
    open: boolean;
}
export const AutoReplenishment: FunctionComponent<Props> = (props) => {
    const { open } = props;
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [loadingInfo, setloadingInfo] = useState(false);
    const [autoReplenishmentInfo, setAutoReplenishmentInfo] = useState<Protocol.AutoReplenishmentInfoResponse | null>(null);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const currentContract = useStore(CurrentContractStore);
    const currentParams = useStore(CurrentParamsStore);
    const { enqueueSnackbar } = useSnackbar();

    const defaultValues = {
        summa: DEFAULT_PAYMENT_PRICE,
        day: Number(moment().format('DD')),
        email: currentParams ? (firstContact(currentParams.contactEmails, 'email') || '') : '',
    };
    const { register, handleSubmit, errors, setValue, clearError } = useForm<FormInputs>({
        reValidateMode: 'onSubmit',
        defaultValues,
    });

    const onSubmit = (data: FormInputs): void => {
        if (Number(data.summa) < MIN_PAYMENT_PRICE || Number(data.summa) > MAX_PAYMENT_PRICE) {
            enqueueSnackbar(`Неверная сумма для оплаты: ${MIN_PAYMENT_PRICE}₽`, {
                variant: 'error',
            });
            return;
        }

        if (currentContract) {
            setLoading(true);

            const formattedPayload = {
                ...currentContract,
                email: data.email,
                day: Number(data.day),
                summa: Number(data.summa),
            };

            apiDoAutoReplenishment(formattedPayload).then(response => {
                enqueueSnackbar('Перенаправляем на платёжную страницу PayOnline', {
                    variant: 'success',
                });
                redirect(response.result);
            }).catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
                setLoading(false);
            });
        }
    };

    const allowOnlyNumbers = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: keyof FormInputs): void => {
        const { value } = event.target;
        if (isInt(value)) {
            setValue(field, Number(value));
        }
    };

    const allowMaxInputValue = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: keyof FormInputs, maxValue: number): void => {
        const { value } = event.target;
        if (Number(value) > maxValue) {
            setValue(field, maxValue);
        }
    };

    const allowMaxInputLength = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: keyof FormInputs, maxLength: number): void => {
        const { value } = event.target;
        if (value.length > String(maxLength).length) {
            setValue(field, Number(value.slice(0, String(maxLength).length)));
        }
    };

    const fetchInfo = (): void => {
        if (currentContract) {
            setloadingInfo(true);

            apiAutoReplenishmentInfo(currentContract).then((response) => {
                setAutoReplenishmentInfo(response);
            }).finally(() => setloadingInfo(false));
        }
    };

    useEffect(() => {
        if (!loadingInfo && !autoReplenishmentInfo) {
            fetchInfo();
        }
    }, [loadingInfo, open]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => fetchInfo(), [currentContract]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loadingInfo || autoReplenishmentInfo === null) return <AccordionLoader/>;
    if (!autoReplenishmentInfo.enableNewAutoPay && autoReplenishmentInfo.autoPaySumma === 0)
        return <AccordionLoader text="Подключение автоплатежа недоступно" />;
    return (
        <div>
            {autoReplenishmentInfo.autoPaySumma > 0 ? (
                <p className={classes.enabledAutopayMessage}>
                    У вас подключён автоплатёж на сумму {formatPrice(autoReplenishmentInfo.autoPaySumma)} ₽.<br/>
                    Оплата взимается {autoReplenishmentInfo.autoPayDay} числа каждого месяца
                </p>
            ) : (
                <form
                    className={classes.root}
                    onSubmit={handleSubmit(onSubmit)}
                    onChange={() => clearError()}
                >
                    <TextField
                        id="balance-replenish-input-email"
                        type="text"
                        name="email"
                        label="E-mail"
                        disabled={loading}
                        aria-invalid={errors.email ? 'true' : 'false'}
                        helperText={formatErrorText(errors.email)}
                        error={Boolean(errors.email && errors.email.message)}
                        inputRef={register(validationSchema.email)}
                        className={classes.textInput}
                        defaultValue={defaultValues.email}
                        InputProps={{
                            inputProps: {
                                inputMode: 'email',
                            },
                        }}
                    />

                    <TextField
                        id="balance-replenish-input-day"
                        type="number"
                        name="day"
                        label="Число месяца"
                        disabled={loading}
                        aria-invalid={errors.day ? 'true' : 'false'}
                        helperText={formatErrorText(errors.day)}
                        error={Boolean(errors.day && errors.day.message)}
                        inputRef={register(validationSchema.day)}
                        className={classes.textInput}
                        onChange={(e) => {
                            allowOnlyNumbers(e, 'day');
                            allowMaxInputLength(e, 'day', MAX_DAY_OF_MONTH);
                            allowMaxInputValue(e, 'day', MAX_DAY_OF_MONTH);
                        }}
                        InputProps={{
                            inputProps: {
                                max: MAX_DAY_OF_MONTH,
                                min: MIN_DAY_OF_MONTH,
                            },
                        }}
                    />

                    <TextField
                        id="balance-replenish-input-amount"
                        type="number"
                        name="summa"
                        label="Сумма"
                        disabled={loading}
                        aria-invalid={errors.summa ? 'true' : 'false'}
                        helperText={formatErrorText(errors.summa)}
                        inputRef={register(validationSchema.summa)}
                        className={classes.textInput}
                        onChange={(e) => {
                            allowOnlyNumbers(e, 'summa');
                            allowMaxInputLength(e, 'summa', MAX_PAYMENT_PRICE);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">₽</InputAdornment>
                            ),
                            inputProps: {
                                max: MAX_PAYMENT_PRICE,
                                min: MIN_PAYMENT_PRICE,
                            },
                        }}
                    />

                    <ButtonWithLoading
                        type="submit"
                        loading={loading}
                        color="primary"
                        noMargin
                        className={classes.button}
                    >
                        Оплатить
                    </ButtonWithLoading>
                </form>
            )}

            {autoReplenishmentInfo.autoPaySumma > 0 && (
                <>
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={openConfirmDelete}
                        onClick={() => setOpenConfirmDelete(true)}
                        startIcon={<DeleteIcon/>}
                    >
                        Отключить
                    </Button>

                    <DeleteAutoReplenishment
                        open={openConfirmDelete}
                        onClose={() => setOpenConfirmDelete(false)}
                        updateReplenishmentInfo={(data: Protocol.AutoReplenishmentInfoResponse) => {
                            setAutoReplenishmentInfo(data);
                        }}
                    />
                </>
            )}
        </div>
    );
};
