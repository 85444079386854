import { reduceWithIndex } from 'fp-ts/es6/Record';
import { pipe } from 'fp-ts/es6/pipeable';
import { copy } from 'fp-ts/es6/Array';

import { LOCATION_ROUTE } from './constants';

export const buildApiUrl = (
    url: string,
    params: Record<string, string | number | null | undefined | boolean>
): string =>
    pipe(
        params,
        reduceWithIndex(url, (k, b, a) => b.replace(`{${k}}`, a?.toString() ?? ''))
    );

// export const buildPaymentRedirectUrl = (kind: PaymentSystem): string =>
// window.location.origin + LOCATION_ROUTE.PAYMENT_STATUS.replace(':paymentSystem', kind);

export const buildPaymentRedirectUrl = (): string => window.location.origin + LOCATION_ROUTE.DASHBOARD;

export const parseNumber = (value: string): number => {
    const possibleToBeNumber = parseFloat(value);
    if (possibleToBeNumber.toString() !== value) return NaN;
    return possibleToBeNumber;
};

export const pluralize = (count: number, words: string[]): string => {
    const cases = [2, 0, 1, 1, 1, 2];
    return words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]];
};

export const firstContact = <
    T extends Record<string, any>,
    Q extends string,
    K extends keyof T = keyof T,
    R extends K = { [P in K]: T[P] extends Q ? P : never }[K]
>(
    values: T[],
    prop: R
): Q | null => {
    const firstValue = copy(values).shift();
    if (firstValue !== undefined) {
        return firstValue[prop];
    }

    return null;
};
