import React, { FC, useEffect } from 'react';

import { pipe } from 'fp-ts/es6/function';

import { createStyles, makeStyles } from '@material-ui/core';

import { useStore } from 'effector-react';
import {
    CurrentContractStore,
    CurrentDashboardStore,
    fetchContractParams,
    fetchDashboard,
} from '../effector/dashboard';
import { fetchCameras } from '../effector/cameras';
import { useDesignFeatures } from '../hooks/designFeatures';

import { LOCATION_CAPTION } from '../utils/constants';

import { withTitle } from '../containers/WithTitle';

import { AddressesSection } from '../components/dashboard/AddressesSection';
import { ContractDetailsSection } from '../components/dashboard/ContractDetailsSection';
import { InternetServicesSection } from '../components/dashboard/InternetServicesSection';
// import { VideoSurveillanceSection } from '../components/dashboard/VideoSurveillanceSection';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            boxShadow: '0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12)',
            borderRadius: '0 0 4px 4px',
            overflow: 'hidden',
            [theme.breakpoints.down('md')]: {
                paddingLeft: 0,
                paddingRight: 0,
                width: '100%',
            },
        },
    })
);

const Dashboard: FC = () => {
    const classes = useStyles();

    const dashboard = useStore(CurrentDashboardStore);
    const currentContract = useStore(CurrentContractStore);
    const isFetchingParams = useStore(fetchContractParams.pending);
    const isFetchingDashboard = useStore(fetchDashboard.pending);
    const isFetchingCameras = useStore(fetchCameras.pending);

    const isLoading = isFetchingParams || isFetchingDashboard || dashboard === null || isFetchingCameras;

    // if (loading || fetching) return <div>Загрузка</div>;
    // if (dashboard === null) return <p>some empty text...</p>;

    const isInternetServicesSectionVisible =
        dashboard?.inetServicesCurrentStatus && dashboard?.inetServicesCurrentStatus.length > 0;

    const { displayDesignFeature, isDesignFeaturesVisible } = useDesignFeatures();

    useEffect(() => {
        if (dashboard !== null && currentContract !== null) fetchCameras({ ...currentContract, ...dashboard });
    }, [dashboard, currentContract]);

    return (
        <div className={classes.root}>
            {isDesignFeaturesVisible([
                'main1_1',
                'main1_2',
                'main1_3',
                'main1_4',
                'main1_5',
                'main1_6',
                'main1_7',
                'main1_1',
                'main1_9',
            ]) && <ContractDetailsSection isLoading={isLoading} />}

            {isInternetServicesSectionVisible &&
                displayDesignFeature('main1_10', 'Состояние интернет-сервисов', ({ name, caption }) => (
                    <InternetServicesSection isLoading={isLoading} id={name} title={caption} />
                ))}

            {/* <VideoSurveillanceSection isLoading={isLoading} /> */}

            {isDesignFeaturesVisible(['main1_11', 'main1_12', 'main1_13']) && (
                <AddressesSection isLoading={isLoading} />
            )}
        </div>
    );
};

const Component = pipe(Dashboard, withTitle(LOCATION_CAPTION.DASHBOARD));
export { Component as Dashboard };
