import { Authorization } from './auth';
import { ApiError } from './error';
import { isString } from '../../utils/guards';

export enum HttpMethod {
    Get = 'GET',
    Post = 'POST',
}

type ResponseType = 'json' | 'blob' | 'html';
type FetchBody = FormData | string | null;

enum KnownErrors {
    ExpiredToken = 'Expired token',
    InvalidSignature = 'Signature verification failed',
}

export class ApiClientClass {
    private invalidTokenHandler: () => void = () => {};
    private defaultHeaders = { Accept: 'application/json' };

    constructor(private readonly auth: Authorization) {}

    private request<T>(
        method: HttpMethod,
        url: string,
        body: FetchBody,
        headers = {},
        type: ResponseType = 'json'
    ): Promise<T> {
        const init: RequestInit = {
            headers: {
                ...this.defaultHeaders,
                ...headers,
                ...this.auth.getAuthHeaders(),
            } as HeadersInit,
            credentials: 'same-origin',
            mode: 'cors',
            method,
            body,
        };

        return fetch(url, init).then((response) =>
            type === 'json'
                ? this.processJSONResponse<T>(response)
                : type === 'blob'
                ? this.processBLOBResponse<T>(response)
                : this.processHTMLResponse<T>(response)
        );
    }

    private handleError(response: Response, json: any): void {
        if (!response.ok || json.error !== undefined) {
            let ServerError = null;
            if (response.status === 500) {
                // TODO: create fake error for this status
                ServerError = new ApiError('Сервер недоступен');
            } else if (response.status === 400 || response.status === 401 || response.status === 422) {
                const errorMessage = json.message || json.error;
                if (errorMessage === KnownErrors.ExpiredToken || json.error === '') {
                    this.invalidTokenHandler();
                    ServerError = new ApiError('Время сессии истекло');
                } else if (errorMessage === KnownErrors.InvalidSignature) {
                    this.invalidTokenHandler();
                    ServerError = new ApiError(errorMessage);
                } else {
                    ServerError = new ApiError(errorMessage);
                }
            } else if (json.message !== undefined) {
                ServerError = new ApiError(json.message);
            } else {
                ServerError = new ApiError(json.error);
            }

            throw ServerError;
        }

        // NOTE: в любой момент может сломаться!
        if (isString(json.error)) {
            if (/Bad token found/.test(json.error)) {
                this.invalidTokenHandler();
                throw new ApiError('Время сессии истекло');
            }

            throw new ApiError(json.error);
        }

        if ('token' in json) this.auth.setToken(json.token);
    }

    private processJSONResponse<T>(response: Response): Promise<T> {
        const contentType = response.headers.get('content-type');
        if (!contentType || contentType.indexOf('application/json') < 0) {
            // TODO: create fake error for this status
            throw new ApiError('Сервер недоступен');
        }
        return response.json().then((json) => {
            this.handleError(response, json);

            return json as T;
        });
    }

    private processBLOBResponse<T>(response: Response): Promise<T> {
        return response.blob().then(async (blob) => {
            let serverError = null;
            let parsedResult = null;
            if (blob.type.indexOf('application/json') >= 0) {
                await blob.text().then((text) => {
                    try {
                        parsedResult = JSON.parse(text);
                    } catch {
                        serverError = new ApiError('Ошибка обработки запроса');
                    }
                });

                if (serverError !== null) throw serverError;
                else if (parsedResult !== null) this.handleError(response, parsedResult);
            }

            return blob;
        }) as Promise<T>;
    }

    private async processHTMLResponse<T>(response: Response): Promise<T> {
        return response.blob().then((blob) => {
            if (blob.type.indexOf('text/html') < 0) throw new ApiError('Ошибка обработки запроса');
            return blob;
        }) as Promise<T>;
    }

    setInvalidTokenHandler(handler: () => void): void {
        this.invalidTokenHandler = handler;
    }

    get<Response, Request = null>(url: string, payload: Request, type: ResponseType = 'json'): Promise<Response> {
        return this.request<Response>(HttpMethod.Get, url, null, {}, type);
    }

    post<Response, Request = null>(url: string, payload: Request): Promise<Response> {
        return this.request<Response>(HttpMethod.Post, url, JSON.stringify(payload), {
            'Content-Type': 'application/json',
        });
    }
}
