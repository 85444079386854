import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { findFirst, isEmpty, isNonEmpty } from 'fp-ts/es6/Array';
import { toNullable } from 'fp-ts/es6/Option';
import { head } from 'fp-ts/es6/NonEmptyArray';
import { pipe } from 'fp-ts/es6/function';

import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { useStore } from 'effector-react';
import { CurrentContractStore } from '../../effector/dashboard';
import { fetchServiceList, ServiceListStore } from '../../effector/serviceList';

import { apiChangePPPOEPassword } from '../../api';
import { isInt } from '../../utils/guards';

import { AccordionLoader } from '../AccordionLoader';

type FormInputs = {
    oldpassword: string;
    newpassword: string;
    idservice: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginBottom: 8,
            '&:not(:last-child)': {
                marginRight: 16,
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%',
                marginRight: 0,
            },
        },
        buttonWrapper: {
            margin: 0,
            marginTop: 8,
        },
        button: {
            height: 40,
            minWidth: 112,
        },
        form: {
            paddingTop: 16,
            paddingBottom: 16,
            width: '100%',
        },
        input: {
            borderRadius: 5,
        },
        select: {
            width: '100%',
            marginBottom: theme.spacing(2),
            backgroundColor: 'white',
        },
        selectInput: {
            backgroundColor: 'white',
            'html:root & > .MuiSelect-root': {
                backgroundColor: 'white',
            },
        },
        textField: {
            width: '100%',
            '& .MuiFormLabel-root': {
                color: theme.palette.text.secondary,
            },
            '& .MuiInputBase-root': {
                backgroundColor: '#F5F5F5',
            },
            '& label.Mui-focused': {
                color: theme.palette.text.secondary,
            },
        },
    })
);

type PPPOEPasswordProps = { defaultService: number | null; open: boolean };
export const PPPOEPassword: FunctionComponent<PPPOEPasswordProps> = ({ defaultService, open }) => {
    const classes = useStyles();
    const { push } = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, control, setValue } = useForm<FormInputs>({
        defaultValues: {
            idservice: '',
            oldpassword: '',
            newpassword: '',
        },
    });

    const loading = useStore(fetchServiceList.pending);
    const currentContract = useStore(CurrentContractStore);
    const { list: serviceList, fetched: serviceListFetched } = useStore(ServiceListStore);
    const filteredServiceList = useMemo(() => serviceList.filter((item) => item.isPPPoE), [serviceList]);

    const [visible, setVisible] = useState({
        oldPassword: false,
        newPassword: false,
    });

    const onSubmit = (data: FormInputs): void => {
        if (currentContract !== null) {
            apiChangePPPOEPassword({ ...currentContract, ...data })
                .then(() =>
                    enqueueSnackbar('Пароль успешно изменен', {
                        variant: 'success',
                    })
                )
                .catch((e) =>
                    enqueueSnackbar(e.message, {
                        variant: 'error',
                    })
                );
        }
    };

    useEffect(() => {
        if (!serviceListFetched && currentContract && open && !loading) {
            fetchServiceList(currentContract);
        }
    }, [serviceListFetched, currentContract, open, loading]);

    useEffect(() => {
        if (isNonEmpty(filteredServiceList)) {
            if (isInt(defaultService)) {
                const firstListService = pipe(
                    filteredServiceList,
                    findFirst((service) => service.id === defaultService),
                    toNullable
                );

                if (firstListService) {
                    setValue('idservice', defaultService.toString());
                } else {
                    push('/settings');
                    enqueueSnackbar(`Сервис с идентификатором ${defaultService} не найден`, { variant: 'warning' });
                }
            } else {
                const firstService = head(filteredServiceList);
                setValue('idservice', firstService.id.toString());
            }
        }
    }, [filteredServiceList, defaultService, enqueueSnackbar, push, setValue]);

    if (loading) return <AccordionLoader />;
    if (!loading && filteredServiceList.length === 0)
        return <AccordionLoader text="На аккаунте нет сервисов для смены PPPoE пароля" />;
    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <FormControl variant="outlined" className={clsx(classes.formControl, classes.select)}>
                <InputLabel id="service-select">Название сервиса</InputLabel>
                <Controller
                    name="idservice"
                    control={control}
                    as={
                        <Select
                            inputRef={register}
                            label="Название сервиса"
                            id="service-select-input"
                            labelId="service-select"
                            disabled={isEmpty(filteredServiceList)}
                            className={classes.selectInput}
                        >
                            {filteredServiceList.map((service) => (
                                <MenuItem key={service.id} value={service.id}>
                                    {service.title}
                                </MenuItem>
                            ))}
                        </Select>
                    }
                />
            </FormControl>
            <FormControl size="small" variant="outlined" className={classes.formControl}>
                <TextField
                    classes={{ root: classes.textField }}
                    name="oldpassword"
                    id="pppoe-oldpassword"
                    inputRef={register}
                    label="Старый пароль"
                    type={visible.oldPassword ? 'text' : 'password'}
                    variant="filled"
                    InputProps={{
                        classes: {
                            root: classes.input,
                        },
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Показать или скрыть пароль"
                                    size="medium"
                                    onClick={() =>
                                        setVisible({
                                            ...visible,
                                            oldPassword: !visible.oldPassword,
                                        })
                                    }
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {visible.oldPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>

            <FormControl size="small" variant="outlined" className={classes.formControl}>
                <TextField
                    classes={{ root: classes.textField }}
                    type={visible.newPassword ? 'text' : 'password'}
                    name="newpassword"
                    id="pppoe-newpassword"
                    label="Новый пароль"
                    inputRef={register}
                    variant="filled"
                    InputProps={{
                        classes: {
                            root: classes.input,
                        },
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Показать или скрыть пароль"
                                    size="medium"
                                    onClick={() =>
                                        setVisible({
                                            ...visible,
                                            newPassword: !visible.newPassword,
                                        })
                                    }
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {visible.newPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
            <div className={classes.buttonWrapper}>
                <Button type="submit" variant="contained" color="primary" className={classes.button}>
                    Изменить
                </Button>
            </div>
        </form>
    );
};
