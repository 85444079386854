import React, { FunctionComponent, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { CurrentContractStore } from '../effector/dashboard';
import { fetchPointList, PointListStore } from '../effector/pointList';
import { foldApiTimestampToDate } from '../utils/view';
import { AccordionLoader } from './AccordionLoader';
import { Table, TableColumns } from '../containers/Table';
import { PointListItem } from '../types/pointList';

const columns: TableColumns<PointListItem> = [
    { id: 'number', label: 'Номер' },
    {
        id: 'dateFrom',
        label: 'Подключен',
        align: 'center',
        format: foldApiTimestampToDate,
    },
    {
        id: 'dateTo',
        label: 'Отключен',
        align: 'center',
        format: (value: DateTime.Timestamp | null) => (value ? foldApiTimestampToDate(value) : '—'),
    },
];

type Props = { open: boolean };
export const PointList: FunctionComponent<Props> = ({ open }) => {
    const list = useStore(PointListStore);
    const loading = useStore(fetchPointList.pending);
    const currentContract = useStore(CurrentContractStore);

    const [fetched, setFetched] = useState(list.length > 0);

    useEffect(() => {
        if (open && currentContract && !fetched && !loading) {
            setFetched(true);
            fetchPointList(currentContract).catch(() => setFetched(false));
        }
    }, [loading, fetched, open, currentContract]);

    // Handle contract id change in header
    useEffect(() => {
        setFetched(false);
    }, [currentContract]);

    if (loading) return <AccordionLoader />;
    if (!loading && list.length === 0) return <AccordionLoader text="Не найдено данных для отображения" />;

    return <Table columns={columns} data={list} keyProp="number" />;
};
